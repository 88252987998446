.modal {
    display : none;
    max-height: none;
}

.lean-overlay {
    height: auto;
}

.reveal-modal {
    visibility: visible;
    position: fixed;
}

.white-popup {
    position   : relative;
    background : #FFF;
    padding    : 30px 20px 20px 20px;
    width      : 90%;
    margin     : 20px auto;
}

.modal,
.base-modal {
    padding: 0;
    background-color: #fff;
    overflow: auto;
    top: 5%;
    max-height: 90%;

    .modal-title {
        width         : 75%;
        margin        : 0 auto;
        overflow      : hidden;
        font-size     : 16px;
        font-weight   : normal;
        line-height   : 22px;
        color         : #8899a6;
        text-align    : center;
        text-overflow : ellipsis;
        white-space   : nowrap
    }
    .modal-header {
        background     : #fff;
        border-bottom  : 1px solid #e1e8ed;
        border-radius  : 6px 6px 0 0;
        cursor         : move;
        padding: 20px 20px 10px 20px;
    }

    .modal-content {
        padding: 25px 24px 50px 24px;
    }

    .modal-footer {
        position   : relative;
        border-top : 1px solid rgba(0, 0, 0, 0.1);
        bottom     : 0;
        height     : 60px;
        left       : 0;
        right      : 0;
        padding: 0 20px;

        .btn,
        .btn-large,
        .btn-flat {
            float  : right;
            margin : 6px 0;
            height: 45px;
            line-height: 45px;
            text-transform: uppercase;
            padding: 0 2rem;
            color: #555;
        }
    }

    .close-reveal-modal {
        color       : #AAAAAA;
        cursor      : pointer;
        font-size   : rem-calc(50);
        font-weight : bold;
        line-height : 1;
        position    : absolute;
        top         : 0;
        right       : 1.375rem;
        padding: 0 8px;

    }

    .item-image {
        padding: 40px 0;
    }
}

body.modal-open {
    overflow : hidden;
}

body.modal-open .modal-mask {
    opacity    : 1;
    visibility : visible;
}

.modal-mask {
    background-color : rgba(0, 0, 0, 0.5);
    height           : 100%;
    left             : 0;
    opacity          : 0.1;
    top              : 0;
    visibility       : hidden;
    width            : 100%;
    z-index          : 3;
    position         : fixed;
    @include transform(translateZ(0));
}

#hlxxModalContent {
    background-color : #fff;
    //width            : 55%;
    //margin           : 10% auto;
}

#hlxxModalWrap {
    position: fixed;
    display:none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
    overflow-y: scroll;
}

#hlxxModalWrap.open {
    display:block;
}


#hlxxModal {
    border  : 1px solid #727272;
    padding : 0px;
    background-color: #FFF;
    top: 5% !important;
    max-height: none;
    position:absolute;
    //overflow-y: scroll;


    .close-reveal-modal {
        font-size   : 30px;
        line-height : 1;
        top: 0;
        padding: 0 5px;
    }

    .modalHeader,
    .modalBody,
    .modalButtons {
        padding: 0 20px;
    }

    .modalHeader {
        .close-reveal-modal {
            right: 0;
        }
    }

    &.ghost {
        border : none;
        background-color: transparent;
    }

    input {
        border: 2px solid #ccc;
        box-shadow: none;
    }

    .modalHeader {
        border-bottom: 1px solid #eee;
        padding-top: 5px;
        padding-button: 5px;
    }

    h4 {
        margin    : 8px 0px;
        font-size : rem-calc(16);
        text-transform: uppercase;
        color            : rgba(0,0,0,.5);
    }

    .row.modalHeader small {
        color : #fff;
    }

    .row.modalBody {
        margin      : 20px 0px;
        line-height : 1.5;
    }

    .row.modalButtons {
        border-top: 1px solid #eee;
        margin-bottom    : 0px;
        padding-top      : 5px;
        padding-bottom      : 5px;
        text-transform: uppercase;
    }

    .modalButtons .btn {
        font-size        : rem-calc(16);
        padding          : 10px 20px;
        background-color : #eee;
        box-shadow: none;
        color: #333;
        margin-right: 15px;
    }

    .collection {
        .collection-item {
            padding: 0px 10px;
        }
    }

    small.success,
    small.error {
        margin-top  : 0;
    }

}

div#hlxxModal select[name="grad_year"],
div#hlxxModal select[name="gender"],
div#hlxxModal select[name="school"],
div#hlxxModal select[name="city"] {
    margin-top : 9px;
}

div#hlxxModal select[name="grad_year"],
div#hlxxModal select[name="gender"] {
    width : 130px;
}

#hlxxModal.vimeo .row.modalButtons {
    border     : none;
    background : black;
}

#hlxxModalContent {
    ul.search-list > li {
        width     : 99%;
        min-width : inherit;
    }

    .user-block {
        border-bottom: 1px solid #eee;
        padding: 5px 0;

        &:last-child {
            border:none;
        }
    }

    .loadMoreUsers {
        line-height: 1;
    }


    div.user-block.condensed div.text-content div {
        font-size : 12px;
    }
    div.user-block div.text-content div.weak {
        font-size : 10px;
        color     : #777;
    }
    div.user-block {
        width : 100%;
    }

    li.border-block {
        width  : 100%;
        margin : 0 5px 2px 0;
    }

    div.user-block.condensed div.image-content {
        width : 50px;
        height     : 50px;
        text-align : center;

        .icon {
            margin-right: 10px;
        }
    }

    div.user-block.condensed div.image-content img {
        max-width  : 50px;
        max-height : 50px;
    }

    div.user-block div.text-content {
        width : 85%;
    }

    #everythingResults {
        width: 95%;
        margin: auto;
    }

    .user-search-result {
        div.result-box {
            div.result-count {
                clear       : both;
                float       : left;
                margin      : 0 10px 0 12px;
                color       : #555;
                line-height : 28px;
            }
        }
        .btn.show-more {
            text-transform : uppercase;
            font-size      : 14px;
        }
    }
}

@media (max-width : $small-breakpoint) {
    #hlxxModalContent {
        div.user-block.condensed div.image-content {
            width      : 15%;
        }
    }

    .base-modal,
    .modal {
        width: 100%;
        max-height: 90%;
        top: 5% !important;

        .modal-content {
            padding-top: 10px;
        }

        .collection {
            .collection-item.avatar {
                .title {
                    font-size: rem-calc(14);
                }

            }
        }

        .social-comments {
            .collection {
                .collection-item.avatar {
                    .title {
                        font-size : rem-calc(12);
                    }

                }
            }
        }

        .item-header,
        .item-footer {
            padding: 0 15px;
        }

    }

    .reveal-modal {
        top: 15vh;
        min-height:0;
    }

}

