/* 
    Document   : main
    Created on : Oct 24, 2012, 8:11:11 PM
    Author     : andy
    Description:
        Purpose of the stylesheet follows.
*/

root
{
    display : block;
}

body
{
    padding     : 0px;
    margin      : 0px;
}

ul
{
    list-style : none;
    padding    : 0;
    margin     : 0;
}

.float,
.left
{
    float : left;
}

.right
{
    float : right;
}

.center
{
    text-align : center;
}

.buttonOld,
button.buttonOld
{
    font-size        : 14px;
    border           : 0px none;
    border-radius    : 3px;
    padding          : 5px 15px;
    margin           : 5px;
    background-color : #444;
    color            : #fff;
    text-align       : center;
    cursor           : pointer;
}

.colLeft
{
    float : left;
    width : 50%;
}

.colRight
{
    float : right;
    width : 50%;
}

/*input,
select
{
    border: 1px solid #999;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin: 3px 5px 0px 0px;
    padding: 0 5px;
    height:30px;
}

input,
select,
textarea
{
    font-size:14px;
}*/

form div.format
{
    padding   : 4px 0;
    color     : #666;
    font-size : 12px;
}

input:focus
{
    border-color : #444;
}

input:active
{
    border-color : #000;
}

h1, h2, h3, h4, h5
{
    color : #444;
}

.underlined
{
    margin-bottom  : 12px;
    padding-bottom : 12px;
    border-bottom  : 1px solid #999;
}

div.dialog-message
{
    display : none;
}

table.entity
{
    clear            : both;
    width            : 100%;
    background-color : #dbdedb;
    border-collapse  : collapse;
}

table.entity th,
table.entity td
{
    border  : 1px solid #999;
    padding : 5px;
}

table.entity th.field,
table.entity td.field
{
    width : 150px;
}

#header
{
    z-index           : 95;
    height            : 138px;
    text-align        : left;
    vertical-align    : top;
    background        : url(http://ystreet.org/helix/images/v1/helixheader-right.gif);
    background-repeat : repeat-x;
}

#header #top,
#header #nav,
#container
{
    width  : 950px;
    margin : auto;
}

#header #nav ul
{
    color           : #fff;
    list-style-type : none;
    margin          : 1px;
}

#header #nav li
{
    height       : 39px;
    width        : 120px;
    float        : left;
    border-right : 1px solid #444;
    text-align   : center;
}

#header #nav li:hover a,
#header #nav li:hover a:link,
#header #nav li:hover a:visited
{
    color : #fff;
}

#header #nav li a,
#header #nav li a:link,
#header #nav li a:visited
{
    display         : block;
    margin-top      : 13px;
    font-family     : Helvetica, Arial, sans-serif;
    font-size       : 14px;
    color           : #00BBE4;
    font-weight     : bold;
    height          : 24px;
    text-transform  : uppercase;
    text-decoration : none;
}

#header #nav li a.selected
{
    color : #fff;
}

#header #top
{
    background : url(http://ystreet.org/helix/images/v1/helixheader_new.jpg);
    height     : 98px;

}

.pageMessage, .pageError
{
    padding               : 10px;
    -moz-border-radius    : 8px;
    -webkit-border-radius : 8px;
    border-radius         : 8px;
    margin                : 5px;
    width                 : 90%;
    font-size             : 14px;
}

.miscError
{
    width                 : 100%;
    -moz-border-radius    : 3px;
    -webkit-border-radius : 3px;
    border-radius         : 3px;
    padding               : 4px 0px;
    margin                : 1px 0px;
    font-size             : 12px;
}

.pageMessage span, .pageError span
{
    font-size   : 13px;
    font-weight : bold;
}

.pageMessage
{
    color            : #0C0;
    border           : 1px solid #0C0;
    background-color : #F3F6FF;
}

.pageError, .invalid, .miscError
{
    color            : #F00;
    border           : 1px solid #F00;
    background-color : #FFFFDF;
}

div.formError
{
    font-size    : .9em;
    color        : red;
    display      : inline;
    padding-left : 10px;
}

div.formInput
{
    padding-top : 26px;
    float       : left;
    width       : 400px;
    height      : 80px;

}

div.formInput label
{
    display : block;
    color   : #444;
}

div.formInput input,
div.formInput select,
div.formInput .miscError
{
    width : 260px;
}

div.loginButtons
{
    margin-top : 30px;
}

div.login a.forgot
{
    display     : inline-block;
    margin-left : 20px;
    font-size   : .85em;
    color       : yellow;
}

ul.loginButtons li
{
    padding : 25px 0 0 0;
}

#developerStats
{
    display : none;
}

code
{
    background-color : #f1f1f1;
}

.devMemStatContainer
{
    max-height : 70px;
    cursor     : pointer;
    overflow   : hidden;
}

#devToolsMemTable, #devToolsQueryTable, #devToolsTraceTable
{
    display : none;
}

/* Admin Role Tools */
#roleSelectorBox
{
    overflow : hidden;
}

#adminRoleContainer
{
    border-right : 1px solid black;
}

#adminRoleContainer,
#adminPrivilegeList {
    button {
        text-transform: none;
    }
}

.addNew
{
    width : 50px;
}

#saveChanges
{
    width  : 120px;
    float  : left;
    margin : 55px;
}

.singleRoleDiv, .singlePrivilegeDiv
{
    cursor : pointer;
}

.singleRoleDiv, .singlePrivilegeDiv, .singlePrivDiv
{
    font-size             : 95%;
    width                 : 80%;
    -moz-border-radius    : 3px;
    -webkit-border-radius : 3px;
    margin                : 1px;
}

.selected
{
    background-color : #000000;
    color            : #FFFFFF;
}

.selected.light
{
    background-color : #aaa;
    color            : #000;
}

a.selected
{
    color : #FFF;
}

.ui-dialog,
.ui-widget,
body .ui-widget
{
    font-size : 14px;
}

#unverifiedMessage
{
    margin-top : 80px;
}

#unverifiedMessage h1
{
    margin-bottom : 40px;

}

div.date-range input {
    font-size: 21px;
    height: auto;
}


input[name=table-filter-checkbox] {
    margin-right: 5px;
}

small.small-80 {
    font-size: 80%;
}

