
.label.filter-label {
    color: $snow;
}

#socialPreviewModal {
    width        : 90%;
    margin: 0 5%;

    background-color:white;

    .content-left {
        width: 60%;
        display:inline-block;
    }
    .content-right {
        display:inline-block;
    }

    .item-image {
        text-align:center;
        background-color: black;
    }

    .item-header,
    .item-footer {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    .social-comments {
    }

    .mfp-close {
        width     : 100%;
        font-size : 40px;
        color     : white;
    }

    .main-img {
        max-height: 400px;
    }

    ul.collection.comments {
        padding: 10px 0 40px 0;
    }

    .collection {
        margin-bottom: 0;

        .collection-item {
        }
    }
}

.collection .collection-item {
    line-height: 1.4;

    .title {
        text-transform: capitalize;
    }

    &.avatar {
        padding-left: 55px;

        img,
        .circle {
            left: 0;
        }

        p {
            line-height: 1.3;
        }
    }

    ul.action-list {
        margin-top: 5px;
        li {
            font-size : 10px;
            font-weight  : 500;
            margin-left  : 0;
            margin-right : 10px;
            line-height : 1;

            i {
                font-size    : 11px;
                color        : #ccc;
                float        : left;
                margin-right : 3px;
                line-height: 1;
            }


            i.selected, span.selected {
                background-color : transparent;
                color            : black;
            }

            span {
                float       : left;
                line-height : 1;
                color       : #aaa;
            }

        }
    }

    ul.action-list.large {
        li {
            font-size    : 12px;

            i {
                font-size    : 14px;
                line-height  : 1;
            }
            span {
                line-height: 14px;
            }
        }
    }
}

.grid-item {
    .collection-item {
        ul.action-list {
            margin-bottom: 5px;
        }
    }
}


div.social-comments textarea {
    font-size     : 11px;
    height        : 28px;
    margin-left   : 1%;
    width         : 98%;
    margin-bottom : 0;
}

.mfp-close {
    width     : 100%;
    font-size : 31px;
    color: white;
    height: 31px;
    line-height: 31px;
}

.mfp-content {
    padding    : 0;
    text-align : left;
    margin     : 0 auto;
    position   : relative;
}

.white-popup-block {
    background : #FFF;
}

.mfp-figure {
    padding-top : 20px;
    background-color: transparent;
}

img.mfp-img {
    padding-top : 0;
    max-height  : 600px !important;
}

.mfp-bottom-bar {
    position : relative;
    padding  : 20px;
}

#custom-content {
    max-width : 1000px;
}

.main {
    width        : 90%;
    margin-left  : auto;
    margin-right : auto;
}

.grid-item {
    margin-bottom : 20px;
    display       : inline-block;
    position : absolute;
    left     : -1000px;
    top      : 0px;

    .card div.collection-item {
        padding     : 10px 10px 20px 10px;

        p {
            padding: 2px 0 0 0;
        }

        .item-avatar {
            margin-top: 0;
            max-height : 55px;
            max-width  : 35px;
        }
    }
}

.grid {
}

.grid-item--width2 {
    width : 400px;
}

.grid-item,
.grid-sizer {
    width : calc(25% - 12px);
}

.grid-item .card {
    min-height : 60px;
}

.gutter-sizer {
    width : 12px;
}

@media (max-width : 1024px) {
    .grid-item,
    .grid-sizer {
        width : calc(33% - 12px);
    }
}

@media (max-width : 868px) {
    .grid-item,
    .grid-sizer {
        width : calc(50% - 12px);
    }
}

@media (max-width : 580px) {
    .grid-item,
    .grid-sizer {
        width : 100%
    }

}

.social-grid-container {
    margin-top: 25px;
    margin-bottom: 200px;

    .scroll-trigger {
        margin: 50px 0;
    }
}

.social-grid .card {
    height : auto;

    .card-image {
        max-height : 300px;
        overflow   : hidden;
    }

    .card-image a {
        text-align : center;
        max-height : 300px;
    }

    .card-content {
        height : auto;
    }

    &.image-headline {
        .card-image {
            padding: 10px 0 0 0;
            background-color : transparent;
        }

        .card-content {
        }
    }
}

.button.social-post-message {
    background-color: #ddd;
    color: #555;

    &:hover {
        background-color: lighten(#ddd, 5%);
        color: lighten(#555, 10%);
    }
}

.dropzone-button {
    position:relative;
    text-align:center;
    input {
        visibility: visible !important;
        opacity: 0;
        position:absolute !important;
        top: 0 !important;
        left:0 !important;
        width: 100% !important;
        height: 100% !important;
        border: 1px solid black !important;
    }
}

