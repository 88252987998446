.card {
    @include box-shadow(0, 2px, 5px, rgba(0, 0, 0, 0.16));
    margin        : 0;
    border-radius : 2px;

    &.card-flat {
        box-shadow: none;
    }

    .card-image a {
        display : inline-block;
        width   : 100%;
        height  : 100%;
    }

    .card-title {
        text-transform : uppercase;
        font-size      : rem-calc(20);
        padding: 0;
        margin-bottom: 0;
        line-height    : 1;
        display        : inline-block;
        color: $body-font-color;
    }

    &.panel {
        padding: 10px 20px;
    }

    .card-content {
        padding: 10px 20px;
    }

    div.collection-item {
        margin-top  : 0px;
        margin-left : 37px;
        padding     : 5px 10px;

        .item-avatar {
            margin-left : -41px;
            float       : left;
            margin-top  : 5px;
            width       : 35px;
        }

        .item-header {
            margin-top  : 2px;
            margin-bottom: 0;
            line-height : 1.2;
            a {
                font-size : rem-calc(11);
            }
        }

        p {
            min-height  : 10px;
            line-height : 1.3;
            font-size   : rem-calc(11);
            word-wrap   : break-word;
        }

        ul.action-list {
            margin-top : 5px;

            li {
                font-size : 10px;
                .material-icons {
                    font-size : 12px;

                }
            }

            li.time {
                color     : #aaa;
                font-size : 9px;
                float     : right;
            }
            //
        }
    }

    .collection-item {
        ul.action-list {
            margin-top : 5px;

            li {
                font-size    : 10px;
                font-weight  : 500;
                margin-left  : 0;
                margin-right : 5px;
                line-height  : 1;

                i {
                    font-size    : 11px;
                    color        : #ccc;
                    float        : left;
                    margin-right : 3px;
                    line-height  : 1;
                }

                a {
                    display : inline;
                    color   : #aaa;
                }

                span {
                    float       : left;
                    line-height : 1;
                    color       : #aaa;
                }

                span.selected,
                i:hover,
                i.selected {
                    background-color : transparent;
                    color            : black;
                }
            }

            li.list-circle:before {
                content      : '\00B7';
                float        : left;
                margin-right : 5px;
                color        : #aaa;

            }
        }
    }

    &.small {
        height : 260px;

        .card-image {
            height : 190px;
        }

        .card-content {
            height  : 70px;
            padding : 0;
        }

        .card-title {
            font-size : 17px;
        }

        .settings {
            .settings-header {
                height : 25px;
            }

            .settings-body {
                height : 150px;

                .goal {
                    img {
                        height : 100px;

                    }
                    small {
                        font-size : 80%;
                        display   : block;
                        padding   : 5px 0;
                    }
                }
            }

            .settings-footer {
                height : 40px;
            }

            &.goal-image {
                .button {
                    background-color : rgba(237, 232, 8, .5);
                }

                .button:hover {
                    background-color : rgba(237, 232, 8, 1);
                }

            }
        }
    }

    &.image-headline {
        .card-image {
            height   : 85%;
            overflow : hidden;
        }

        .card-content {
            height  : 15%;
            padding : 0;
        }

        .large-graph {
            margin : 20px 0 0 10px;
            width  : calc(100% - 30px);
        }
    }

    .card-reveal {
        overflow-y : hidden;
    }

    &.panel {
        background-color : #fff;
        border           : none;
        margin           : 0;

    }

    .card-settings {
        position   : relative;
        color      : #ddd;
        top        : -27px;
        visibility : hidden;
        left       : 0;
        text-align : right;
        width      : 95%;
        margin     : auto;

        &.show-on-hover {
            display : none;
        }
        i:hover {
            color : #333;
        }

    }

    &.expand {
        .card-settings {
            visibility : visible;
        }
    }

    .card-reveal {

        .card-title {
        }

        i {
            float : right;
        }

    }

    .card-message {
        font-size   : rem-calc(14);
        font-weight : 300;
        text-align  : center;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .card-image img {
        width      : auto;
        max-width  : 100%;
        max-height : 100%;
    }

    .card-footer {
        position:absolute;
        bottom: 0;
        padding: 0 20px;
        width: 100%;
    }
}
