/* The starting CSS styles for the enter animation */
.fade-enter {
    transition : 0.5s linear all;
    opacity    : 0;
}

/* The finishing CSS styles for the enter animation */
.fade-enter.fade-enter-active {
    opacity : 1;
}

.animate-enter {
    -webkit-transition : 4s linear all; /* Chrome */
    transition         : 4s linear all;
    opacity            : 0;
}

.animate-enter.animate-enter-active {
    opacity : 1;
}

.progress-circle {
    display : none;
}

#storeListModal {
    margin : 50px;

    .mfp-close {
        color     : white;
        font-size : 50px;
    }
}

.collection.feed {
    list-style : none;

    textarea {
        padding       : 3px 5px;
        margin-left   : 0;
        margin-top    : 5px;
        margin-bottom : 0;
        font-size     : rem-calc(12);
        line-height   : 1.5;
        border-color  : #ddd;
        box-shadow    : none;
        resize        : vertical;
        height        : auto;
    }

    & > li.collection-item:first-child {
        margin-top  : 0;
        padding-top : 0;
    }

    & > li.collection-item {
        padding : 10px 0;
    }

    button.show-more {
        font-size : rem-calc(11);
        padding   : 0 0 0 10px;
        color     : #666;
    }
}

.collection.comments {
    margin-top : 5px;

    & > li.collection-item {
        font-size   : rem-calc(12);
        line-height : 1.2;
        min-height  : 0;

        padding     : 5px 5px 5px 35px;

        .circle {
            width  : 25px;
            height : 25px;
            left   : 0;
        }

        .title {
            text-transform : capitalize;
            font-size      : rem-calc(12);
            font-weight    : normal;
            margin-right   : 5px;
            margin-bottom  : 5px;
            display        : inline;
        }

        .date {
            font-size  : rem-calc(9);
            color      : #333;
            margin-top : 5px;
        }
    }
}

section.dashboard {
    margin-bottom: 40px;

    h3 {
        font-size  : 16px;
        margin-top : 5px;
    }

    h3 a {
        color : black;
    }

    h3 a:hover {
        color : #555;
    }

    .card {
        cursor: default;

        .card-title {
            padding-bottom: 5px;
        }

    }

    .card-pad {
        margin-top: 10px;
    }

    .settings {
        .card-title {
            padding-top: 0;
        }
    }
}

body.dashboard-feature {
    background-color       : #fafafa;
    -webkit-font-smoothing : antialiased;


    p {
        font-size : .80rem;
    }

    .has-tip,
    .has-tip:hover {
        border-bottom : none;
    }

}

.material-icons {
    font-size : 13px;
}

.onload {
    visibility : hidden;
}

.onload.loaded {
    visibility : visible;
}

.header-inner a {
    -webkit-tap-highlight-color : rgba(0, 0, 0, 0);
}

.btn-simple {
    border     : 1px solid #ddd;
    background : #fff;
    color      : #000;
}

.progress-circle {
    width        : 135px;
    margin-left  : auto;
    margin-right : auto;
    height       : 140px;
    margin-top   : 30px;

    &.semi-circle {
        height     : 80px;
        margin-top : 60px;
        width      : 160px;
    }
}

.card,
.chart {
    font-family : $lato-font-family;
    font-weight : 300;


    .progress-goal-img img {
        max-height : 66px;
    }
}

.card h3,
.chart h3,
.card h3 a,
.chart h3 a {
    font-size   : 14px;
    line-height : 14px;
    color       : #666;
}

.card .header,
.chart .header {
    height : 20px;
}

.progressbar-text {
    font-size      : 30px;
    color          : #444 !important;
    vertical-align : middle;
    text-align     : center;
}

p.progress-hover,
p.progress-text,
p.text {
    font-size  : 14px;
    text-align : center;
}

.text,
.progress-hover,
.progress-text {
    margin-top : 8px;
}

.progress-label > p.text {
    text-align : center;
    font-size  : 14px;
    margin-top : 0;
}

.gauge p.progress-hover,
.gauge p.progress-text {
}

p.text.no-space {
    margin-top    : 0;
    margin-bottom : 0;
}

p.text .progress-percent,
p.text .progress-count {
    font-size   : 30px;
    line-height : 1;
    color       : #000;
    font-weight : bold;
}


.point-tracker-single {
    &.card {
        height: 200px;
    }

    &.reveal {
        height: 270px;
    }

    &.card.small .card-content {
        margin-top: 55px;
        height: 135px;
    }

    p.text .progress-count, p.text .progress-percent {
        font-size: 80px
    }


}

p.text span.unit {
    display        : block;
    color          : #000;
    font-size      : rem-calc(12);
    text-transform : lowercase;
}

p.text .count {
    font-size : 30px;
    color     : #222;
}

div.chart.height-2 {
    min-height : 465px;
}

.sidebar {
    border     : 1px solid #eee;
    min-height : 446px;
}
header {
    .header-background {
        background-color : #333;
        box-shadow       : 0px 1px 4px rgba(0, 0, 0, 0.5);
        height           : 55px;
        position         : fixed;
        z-index          : 10;
        width            : 100%;
        left             : 0;
        top              : 0;
    }
}

.row.row-full {
    max-width : none;
}

section.main {
    margin-top : 10px;
    &.with-bar {
        margin-top : 70px;
    }
}

.padding-none {
    padding : 0px !important;
}

.padding-xs {
    padding : 5px !important;
}

section.main .container-fluid {
    padding-left  : 20px;
    padding-right : 20px;
}

.progress-goal-img {
    text-align : center;
    margin-top : -102px;
    height     : 75px;

    &.no-goal {
        height     : 140px;
        margin-top : 30px;
        color      : rgba(0, 128, 0, .2);

        p {
            font-size   : 20px;
            line-height : 1;
        }

        button {
        }

        i {
            font-size : 90px;
            color     : rgba(0, 128, 0, .2);
        }
    }

    &.no-goal:hover {
        color : rgba(0, 128, 0, .8);

        i {
            color : rgba(0, 128, 0, .8);
        }

    }
}

.no-goal {
    display : table;
    width   : 100%;

    .progress-goal-img {
        height         : 180px;
        margin-top     : 30px;
        color          : rgba(0, 128, 0, .2);
        display        : table-cell;
        vertical-align : middle;
        text-align     : center;

        .btn {
            padding       : 0;
            margin        : 13px 0 0 0;
            border-radius : 50%;
            width         : 130px;
            height        : 130px;
            font-size     : 20px;

            span {
                display    : block;
                margin-top : 15px;
            }

            i {
                margin-top : -5px;
                font-size  : 35px;
                width      : 35px;
                height     : 35px;
                color      : rgba(255, 255, 255, 1);
            }
        }

        p {
            font-size   : 20px;
            line-height : 1;
        }
    }
}


.add-goal {
    padding    : 40px 20px;
    text-align : center;
}

.add-goal button {
    margin-top : 20px;
}

.add-goal .goal-title {
    line-height : 30px;
    font-size   : 30px;
    font-family : 'Lato', sans-serif;
    color       : #555050;
}

.gauge-chart {
    top          : 20px;
    max-height   : 120px !important;
    width        : 130px;
    height       : 120px;
    margin-left  : auto;
    margin-right : auto;
    text-align   : center;
}

.item-header {
    line-height   : 1.3;
    margin-bottom : 5px;
    font-size     : rem-calc(13);
    font-weight   : normal;

    a {
        text-transform : capitalize;
        display        : inline-block;
        font-size      : rem-calc(13);
        margin-right   : 5px;
    }

    strong {
        text-transform: capitalize;
    }

    .date {
        font-weight   : 500;
        margin-bottom : 4px;
    }

}

ul.collection.menu {
    .item-container {
        margin-left : 55px;
        width       : calc(100% - 75px);

        .item-header {
            font-size   : rem-calc(12);
            line-height : 1;
            width       : 100%;
        }

        .item-content {
            font-size   : rem-calc(12);
            line-height : 1;
            width       : 100%;

            p {
                width         : 100%;
                color         : black;
                font-weight   : 500;
                font-size: rem-calc(12);
                overflow      : hidden;
                text-overflow : ellipsis;
                white-space   : nowrap;
                margin-bottom : 0;
            }

            p.wrap {

                text-overflow : clip;
                overflow      : visible;
                white-space   : normal;
            }

            small {
                color : grey;
            }
        }

        .secondary-content {
            position : absolute;
            top      : 5px;
            right    : -24px;

            .material-icons {
                font-size : 15px;
                width     : 15px;
                height    : 15px;
            }
        }

        .item-icons {
            margin-left : -55px;
        }
    }
}

ul.collection.milestones {
    font-size   : rem-calc(12);
    font-family : Verdana, Helvetica;
    color       : #000;
    padding     : 20px;

    li.collection-item {
        padding       : 0;
        margin-bottom : 5px;

        .item-header {
            font-size     : rem-calc(16);
            margin-bottom : 3px;
        }

        .item-content {
            font-size     : rem-calc(10);
            margin-bottom : 5px;
        }

        border-bottom : 1px solid #f4f4f4;
    }

    .progress-line {
        display      : inline-block;
        width        : 80%;
        margin-top   : 0;
        margin-right : 2px;
    }

    .progress-value {
        display    : inline-block;
        text-align : right;
    }
}

.card-milestone,
.card-campaign {
    .progress-label {
    }

    .card-image {
        height : 85%;

    }
    .card-content {
        height : 15%;
    }

    .collapsible-header {
        font-size     : rem-calc(16);
        border-bottom : 1px solid #f4f4f4;
        height        : rem-calc(45);
        line-height   : rem-calc(45);
    }
    .collapsible-body {
        font-size        : rem-calc(12);
        border-bottom    : 1px solid #f4f4f4;
        background-color : rgba(0, 0, 0, .015);
        ul li {
            font-size    : rem-calc(12);
            margin-left  : 0;
            margin-right : 10px;
        }

        .value {
            color       : #000;
            font-weight : normal;
            padding     : 0 5px;
        }
        padding          : 0 20px;
        .info-item {
            border-bottom : 1px solid white;
        }
        .info-item:last-child {
            border-bottom : none;
        }
        .name {
            margin-right : 20px;
        }
        .progress-line {
        }

        .name, .progress-line, .value {
            display : inline-block;
        }
    }
    .status-circle {
        float         : right;
        border-radius : 50%;
        height        : 18px;
        margin-top    : 15px;
        margin-left   : 15px;
        width         : 18px;

        &.green {
            background-color : green;
        }
        &.grey {
            background-color : grey;
        }
        &.orange {
            background-color : orange;
        }
    }


    .inline-list {
        margin: 1px 0;
        > li {
            padding: 0 20px;
        }
    }

    .milestone-status-list {
        font-size   : rem-calc(9);
        font-weight : normal;
        color       : #777;
        margin      : auto;
        width       : 165px;

        li {
            margin-left : 5px;
            padding: 0;
        }

        .status-circle {
            height       : 7px;
            margin-top   : 3px;
            margin-left  : 0;
            width        : 7px;
            margin-right : 3px;
        }
    }
}

.collapsible-flat {
    box-shadow : none;
    border     : none;
}

.progress-main {
    text-align : center;
    height     : 140px;
}

.progress-main h3 {
    margin-top : 0;
}

.percent-center {
    line-height : 60px;
    font-size   : 25px;
    font-weight : 500;
}

.progress-percent {
    margin-top : 2px;
}

ul.progress-list {
    padding-top : 20px;
    list-style  : none;
}

.progress-hover {
    display : none;
}

.card.expand .progress-hover {
    display : block;
}

.card.expand .progress-image,
.card.expand .progress-text {
    display : none;
}

.card.expand .progress-text {

}

.chart ul {
    margin-left : 0;
}

ul.progress-list .number {
    font-size : 30px;
}

.large-graph {
    margin-top : 0px;
    height     : 195px;
}

.progress-line svg {
    border-radius : 15px;
}

.milestone-chart {
    p.text {
        text-align : center;
        margin-top : 7px;
        font-size  : 14px;
    }
    .pricing-table {

        border        : none;
        margin-top    : 10px;
        margin-bottom : 0;

        .price {
            background     : none;
            font-size      : 13px;
            padding-left   : 5px;
            margin-top     : 2px;
            padding-top    : 10px;
            padding-bottom : 10px;
        }

        .price,
        .description {
            text-align : left;
        }

        .description {
            padding       : 0px 108px 8px 0;
            border-bottom : 1px solid #eee;
        }

        .progress-line {
            margin-top : 0;
        }
    }
}

.card-message {
    line-height : 1;
    font-size   : rem-calc(35);
    font-weight : 200;
    text-align  : center;
}

.card.projects,
.card.events {
    .card-message {
        line-height : 100px;
    }
}

.card-milestone {
    .card-message {
        line-height : 250px;
    }
}

@media (max-width : 768px) {

    section.dashboard {
        width : 100%;

        .progress-circle {
            width       : 65px;
            margin-top  : 0px;
            margin-left : 5px;
            float       : left;
            height      : auto;

            &.semi-circle {
                margin-top : 17px;
                height     : 40px;
                width      : 65px;
            }
        }

        .progress-label .text {
            margin-top   : 15px;
            text-align   : left;
            padding-left : 80px;
        }

        .progressbar-text {
            display : none;
        }

        p.text {
            font-size   : 14px;
            line-height : 60px;
            margin-left : 2px;
            margin-top  : 12px;
            text-align  : left;

            .progress-count,
            .progress-percent {
                font-size   : rem-calc(26);
                font-weight : 500;
                line-height : 1;
                color       : #333;
            }
        }

        p.text span.number {
            color     : #000;
            font-size : 17px;
        }

        .mobile-1 .medium-3 {
            width : 50%;
        }

        .card,
        .chart {
            position      : relative;
            padding       : 10px;
            margin-bottom : 0;
            border-bottom : 1px solid #e9e9e9;
            border-radius : 5px;

            .card-settings {
                position   : absolute;
                top        : 33px;
                bottom     : 5px;
                right      : 10px;
                left       : auto;
                visibility : visible;
            }
        }


        .mobile-1 {
            .medium-9 {
                //width : 100%;
            }

            .card.small {
                height : auto;

                .card-image,
                .card-content {
                    display : inline-block;
                    height  : 65px;
                    margin-top: 0;
                }

                .card-image {
                    width : 80px;
                    float : left;
                }

                &.reveal {
                    height : 270px;
                }
            }

            .point-tracker-single {
                &.card.small .card-content {
                    //display:block;

                    p.text {
                        text-align: right;
                    }
                }
            }

        }

        .no-goal {
            .progress-goal-img {
                /* float: left; */
                width  : 63px;
                margin : 0;
                float  : left;
                height : 71px;
                left   : 8px;


                .btn {
                    font-weight: 400;
                    font-size: rem-calc(14);
                    line-height: 1;
                    padding       : 0;
                    margin        : 4px 0 0 0;
                    border-radius : 50%;
                    width         : 60px;
                    height        : 60px;
                    display       : inline-block;
                    box-shadow    : none;
                }
            }
        }

        .progress-goal-img {
            text-align : left;
            margin-top : 0;
            left       : 18px;
            height     : auto;
            position   : absolute;
        }

        .progress-goal-img i {
            font-size   : 40px;
            margin-top  : 3px;
            margin-left : 0px;
        }

        .progress-goal-img img {
            width : 30px;
        }

        p.text span.unit {
            display     : inline;
            margin-left : 5px;
            font-size   : rem-calc(13);
            color       : #555;
        }

        .add-goal {
            padding    : 10px;
            text-align : left;
        }

        .add-goal button {
            font-size   : 20px;
            margin-top  : 0;
            display     : inline;
            margin-left : 10px;
        }

        .add-goal .goal-title {
            font-size : 20px;
            display   : inline;
        }

        .gauge-chart {
            top          : 0;
            height       : 50px;
            width        : 60px;
            margin-left  : inherit;
            margin-right : inherit;
            float        : left;
        }

        .gauge-chart svg text {
            display : none;
        }

        .gauge-chart svg {
            margin-top : 6px;
        }

        .progress-main {
            text-align : left;
            height     : 70px;
            float      : left;
        }

        .progress-main h3 {
            margin-top : 0;
        }

        .percent-center {
            font-weight : 500;
            line-height : 32px;
            font-size   : 13px;
        }

        ul.progress-list {
            padding : 0;
        }

        ul.progress-list .number {
            font-size : 20px;
        }

        .progress-main h3 {
            font-size : 14px;
        }

        .card.expand .progress-chart {
            display : block;
        }

        .progress-goal-img img {
            left : 4px;
            top  : 18px;
        }

        .progress-hover {
            &.progress-percent {
                margin-top : 20px;
                width      : 40px;
                text-align : center;
            }

            //.progress-percent {
            //    width      : 40px;
            //    text-align : center;
            //}
        }

    }
}

@media (max-width : 580px) {
    section.dashboard {
        margin-top : 5px;
        width      : 100%;

        .card, .chart {
            border        : none;
            margin-bottom : 0;
            border-bottom : 1px solid #e9e9e9;
            border-radius : 5px;

            h3 {
                font-family    : Lato, sans-serif;
                margin-bottom  : 10px;
                margin-left    : 3px;
                text-transform : uppercase;
                font-size      : 12px;
                color          : #aaa;
            }

            .card-settings {
                right : 20px;
            }
        }
        .progress-goal-img {
            left : 18px;
        }

        .progress-goal-img img {
        }

        .progress-line {
            padding : 2px 0;
        }

        .padding-xs {
            padding : 0 !important;
        }

        .mobile-1 .medium-3 {
            width : 100%;
        }

        .progress-main {
            height : 70px;
        }

        .progress-main h3 {
            margin-top : 0;
        }

        .percent-center {
            font-weight : 500;
            line-height : 30px;
            font-size   : 15px;
        }

        p.text {
            margin-top : 10px;

            .progress-count,
            .progress-percent {
                display : inline-block;
            }
        }

        .collection .collection-item {
            border-bottom : 1px solid #f4f4f4;

            .item-container {
                margin-left  : 85px;
                margin-right : 10px;
            }

            .item-icons {
                margin-left : -74px;
            }

            &:last-child {
                border : none;
            }
        }
    }
}


