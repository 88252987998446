@charset "UTF-8";

// Mixins
@import "materialize/sass/components/prefixer";
@import "materialize/sass/components/mixins";
@import "materialize/sass/components/color";

//$secondary-color : color("grey", 'lighten-2');
$btn-color : rgba(0,0,0,.8);
$button-color-raised: rgba(0,0,0,.9);

// Variables;
@import "materialize/sass/components/variables";
$roboto-font-path: "../css/fonts/";
$icons-font-path: "../css/fonts/material-design-icons/";

// Reset
@import "materialize/sass/components/normalize";

// components
@import "_materialize_global";
@import "materialize/sass/components/icons-material-design";
//@import "materialize/sass/components/grid";
//@import "materialize/sass/components/navbar";
//@import "materialize/sass/components/roboto";
//@import "materialize/sass/components/typography";
@import "materialize/sass/components/cards";
@import "materialize/sass/components/toast";
//@import "materialize/sass/components/tabs";
//@import "materialize/sass/components/tooltip";
//@import "materialize/sass/components/buttons";
//@import "materialize/sass/components/dropdown";
@import "materialize/sass/components/waves";
@import "materialize/sass/components/modal";
@import "materialize/sass/components/collapsible";
//@import "materialize/sass/components/materialbox";
//@import "materialize/sass/components/form";
//@import "materialize/sass/components/table_of_contents";
//@import "materialize/sass/components/sideNav";
//@import "materialize/sass/components/preloader";
//@import "materialize/sass/components/slider";
//@import "materialize/sass/components/date_picker/default.scss";
//@import "materialize/sass/components/date_picker/default.date.scss";
//@import "materialize/sass/components/date_picker/default.time.scss";

$transparent : (
    "transparent-1": 1,
    "transparent-2": .87,
    "transparent-3": .70,
    "transparent-4": .54,
    "transparent-5": .26,
    "transparent-6": .12
);

@each $color_name, $color in $colors {
    @each $color_type, $color_value in $color {
        @each $opacity_name, $opacity_value in $transparent {
            @if $color_type == "base" {
                .#{$color_name}-#{$opacity_name} {
                    background-color : rgba($color_value, $opacity_value) !important;
                }
            }
        }
    }
}
