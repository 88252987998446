.arrow {
    width  : 0;
    height : 0;
}

.arrow-up {
    border-top    : none !important;
    border-left   : 7px solid transparent;
    border-right  : 7px solid transparent;
    border-bottom : 7px solid white;
}

.arrow-down {
    border-bottom : none !important;
    border-left   : 7px solid transparent;
    border-right  : 7px solid transparent;
    border-top    : 7px solid white;
}

.arrow-right {
    border-right  : none !important;
    border-top    : 7px solid transparent;
    border-bottom : 7px solid transparent;
    border-left   : 7px solid white;
}

.arrow-left {
    border-left   : none !important;
    border-top    : 5px solid transparent;
    border-bottom : 5px solid transparent;
    border-right  : 5px solid white;
}

.dropdown {
    .dropdown-menu {
        color            : $black;
        position         : absolute;
        width            : 400px;
        height           : 350px;
        background-color : white;
        /* right: 0; */
        top              : 62px;
        left             : -340px;
        box-shadow       : 0 2px 10px rgba(0, 0, 0, 0.2);
        @include transform-origin(top right);
        @include transform(scale(0));
        @include transition(all 0ms linear);

        .collection {
            overflow : auto;
            .collection-item.avatar {
                padding-left : 10px;

                a {
                    //display:block;
                }
            }
        }

        .item-container a {
            color : $black;
        }

    }

    a:hover {
        background-color : transparent;
        color            : black;
    }
    .dropdown-menu-content {
        overflow-y : scroll;
        height     : calc(100% - 60px);

        &::-webkit-scrollbar {
            display : none;
        }
    }

    .dropdown-menu-footer a,
    .dropdown-menu-header {
        display     : block;
        text-align  : center;
        border-top  : 1px solid #F0F0F0;
        line-height : 30px;
        height      : 30px;
        font-size   : rem-calc(11);
        color       : #828282;
    }

    .dropdown-menu-footer a:hover {
        color : rgba(0, 0, 0, 1);
    }

    .dropdown-menu-header {
        height        : 30px;
        line-height   : 30px;
        border-bottom : 1px solid #F0F0F0;
        font-size     : rem-calc(13);
        color         : rgba(0, 0, 0, .8);
        font-weight   : bold;
    }

    .item-message {
        color       : $black;
        line-height : 100px;
        font-size   : rem-calc(24);
        font-weight : 200;
        text-align  : center;
    }
}

.dropdown.open {
    .dropdown-menu {
        display : block;
        @include transform-origin(top right);
        @include transform(scale(1));
        @include transition(all 150ms ease);
    }
}