@import "settings";

#manifestTextarea
{
    display : none;
}

#manifestTextarea textarea
{
    height : 600px;
}

#manifestCreateButton,
#manifestEditButton
{
    display : none;
}

#surveyList table td
{
    vertical-align : middle;
}

#surveyList table th.admin
{
    background-color : #aaa;

}

#surveyList table form
{
    margin : 0;

}

.hideCreateManifest
{
    display : none;
}

.edited
{
    background-color : #C5A4E4;
}

.edit
{
    font-size : .9em;
}

.edit.select
{
    margin-left : 10px;
}

span.require
{
    padding : 0 5px;
}

.require.hide
{
    display : none;
}

.editHover
{
    cursor : pointer;
}

div.editOptions
{
    margin : 30px 0 0 0;
}

#fieldEditForm div.row
{
    padding-bottom : 5px;
}

#fieldEditForm label
{
    margin-top : 5px;
}

a.toggleSurvey
{
    font-size  : 20px;
    text-align : center;
    color      : #000000;
}

ul.tools
{
    margin : 0;
}

ul.tools.icons .button.small
{
    padding : 0;
}

ul.tools .button.small
{
    padding : 5px 10px;
}

ul.icons .icon
{
    font-size : 30px;
    height    : 30px;

}

li.markItUpButton a
{

}

li.markItUpButton.scoreButton a
{
    background  : none;
    text-indent : 0;
    height      : 23px;
}

/* PREVIEW VIEW */
div#surveyPreview .survey-page
{
    padding-left  : 20px;
    padding-right : 20px;
    border        : 2px solid #666;
    margin-top    : 20px;
}

.signupContainer {
    .logo {
        height: auto;
    }
}

div.survey-page
{
    padding : 20px 0;

    .question .row,
    p.question {
        margin-top: 40px;

        &.dependency-question {
            margin-top: 0px;
        }
    }

    .question:first-child .row {
        margin-top: 10px;
    }

    .question .row.textgrid {
        margin-top: 0;
    }
}

.survey-title {
    h2 {
        font-size: 27px;
        text-align:center;
    }
}

.survey-intro {
    text-align: center;
}

h3.introText
{
    margin : 20px 0 40px 0;
}

div.survey-question
{
    margin : 25px 0;
}

button.survey-next
{
    margin : 20px 0;
}

.survey-container,
.survey-page
{
    p.question,
    label {
        color: #4d4d4d;
        font-size: 16px;
        line-height: 20px;

        .require-status {
            font-size: 80%;
            color: #999;
        }
    }

    h2 {
        font-size: 30px;
    }

    .surveyHeader
    {
        margin-bottom : 40px;
    }

    div.surveyBody p
    {
        margin-bottom : 5px;
        font-weight   : bold;
    }

    div.surveyBody input
    {
        margin-right : 10px;
        cursor       : pointer;
    }

    div.surveyIntro h3
    {

    }

    div.surveyNav
    {
        margin-top : 100px;
    }

    div.surveyNav a.go-back
    {
    }

    div.surveyRow
    {
        margin : 70px 0 25px 0;
    }
    div.surveyRow small.error
    {
        margin-top : 0;
    }

    div.surveyRow label
    {
        cursor        : default;
        margin-bottom : 5px;
    }

    .button {
        @extend .brand-background;
    }

    .button.success,
    .button.alert {
        color: white;
    }

}

.survey-container.admin {
    h2 {
        font-size: 18px;
    }
}

.survey-container.training-application
{

    .surveyHeader .logo
    {
        width             : 190;
        height            : 190;
        background-repeat : no-repeat;
    }

    .surveyHeader h2
    {
        font-size  : 35px;
        text-align : center;
    }

    .surveyIntro h4
    {
        text-align : center;
    }

    .surveyNav .button
    {
        color       : #000;
        border      : none;
        font-weight : bold;
    }
}

#survey-page-1-1.training-application
{
    .surveyNav
    {
        text-align : center;
    }
}

#consentFormContainer button
{

    background : #000;
    color      : #FFF

}

div.progress
{
    height : 35px;
}

div.progress .meter
{
    text-align   : center;
    font-weight  : bold;
    color        : white;
    font-size    : 17px;
    line-height  : 30px;
    border-right : 1px solid white;
}

div.progress.four_steps .meter
{
    width : 25%;
}

.progress-label
{
    margin-left : 10px;
}

div.progress .meter.end
{
    border : none;
}

div.progress .meter.none
{
    background-color : #bbb;
}

div.minigrant-review {

    ul.pricing-table {

        .price {
            font-weight: bold;
            font-size: 12px;
        }

        .bullet-item {
            font-size: 12px;
        }

        .price,
        .bullet-item {
            padding: 5px;
        }
    }

    ul.table li {
        font-size: 12px;
    }

}


///*************************************
//SURVEY ENGINE - TRAININGS APPLICATIONS
//*************************************/
//body.evolvement .survey-container.training-application {
//
//  .surveyHeader {
//  }
//  .surveyHeader .logo {
//    background-image: url("/images/evolvement/logo.jpg");
//  }
//
//  .surveyNav .button,
//  .surveyNav .button {
//    background-color: #ede808;
//  }
//
//}
//
//body.ystreet .survey-container.training-application {
//  .surveyHeader .logo {
//    background-image: url("/images/ystreet/logo.jpg");
//  }
//
//  .surveyHeader h1 {
//  }
//
//  .surveyNav .button,
//  .surveyNav .button {
//    background-color: #00f0ff;
//  }
//
//}

