/************************************
 * This is to add generic classes which will
 * be applied often.
 ************************************/
body {
    font-family            : $lato-font-family;
    padding-top            : 55px;
    -webkit-font-smoothing : antialiased;
    background-image       : none;
}

code {
    display : block;
}

.inline {
    display : inline !important;
}

.inline-block {
    display : inline-block !important;
}

.fancy-list,
.pricing-table {
    .price {
        font-size        : 1.3rem;
    }
}

.dbg-box {
    border           : 2px solid red;
    background-color : white;
    padding          : 20px;
}

.table-wrap {
    width      : 100%;
    overflow-x : scroll;
}

::-webkit-scrollbar {
    //display : none;
}

a {
    outline : 0;

    &:hover {
        text-decoration : underline;
    }
}

input[type=file] {
    &.mobile-fix {
        width    : 100%;
        position : absolute;
        left     : 0;
        top      : 0;
        opacity  : 0;
        height   : 100%;
    }
}

button {
    font-family : $lato-font-family;
}

.scrollable {
    overflow-y : scroll !important;
}

.no-decoration {
    text-decoration : none ! important;

    &:hover {
        text-decoration : none !important;

    }
}

//::-webkit-scrollbar {
//    -webkit-appearance: none;
//    width: 7px;
//}
//::-webkit-scrollbar-thumb {
//    border-radius: 4px;
//    background-color: rgba(0,0,0,.5);
//    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
//}
//
//table {
//
//tr.shade {
//    th {
//        background-color: #ccc;
//
//    }
//}
//}

.circle {
    border-radius : 50%;
}

.ellipsis {
    text-overflow : ellipsis;
    overflow      : hidden;
    white-space   : nowrap;
}

.vert-align {
    position : relative !important;
    top      : 50% !important;
    @include transform(translateY(-50%));
}

.table {
    display : table;
    width   : 100%;

    .table-center {
        display        : table-cell;
        width          : 100%;
        vertical-align : middle;
        text-align     : center;
    }

    .vert-top {
        vertical-align : top;
    }

}

ul.float-grid {
    @extend .clearfix;
    margin-bottom : 10px;

    li {
        float : left;

        img {
            padding : 2px;
        }
    }
}

.textValue,
.button.textValue {
    float : right;
}

//.avatar{
//  border-radius:50%;
//}
.avatarTd,
.avatarContainer {
    width : 90px;
}

.space-top {
    margin-top : 5px;

    &.more {
        margin-top : 10px;

        &.more {
            margin-top : 15px;
        }
    }

}

table.show-grid {
    th,td {
        border: 1px solid #ddd;
    }
}

.clear {
    clear : both;
}

.required.formElement {
    font-size : 150%;
    color     : $alert-color;
}

.architectLabel {
    margin      : 16px 2px 6px 2px;
    font-weight : bold;
}

.block-center {
    display    : block;
    text-align : center;
}

.box-center {
    margin : auto;
}

ul.crumb-list {
    @extend .inline-list;
    background-color : #eee;
    padding          : 10px;
    border-radius    : 5px;
    margin-top       : 5px;

    > li {
        line-height : 50px;
        margin-left : rem-calc(10);

        &.first-link {

        }

        a, span {
            font-size       : rem-calc(16);
            font-weight     : 400;
            color           : rgba(0, 0, 0, .6);
            text-decoration : none;
        }

        span.active {
            color : #222;
            color : rgba(0, 0, 0, 1);
        }

        a:hover {
            color : rgba(0, 0, 0, 1);
        }

        i {
            font-size : rem-calc(30);
        }
    }
}

.ui-datepicker {
    background-color : $ghost;
    border           : 1px solid $iron;
    padding          : 20px;
    display          : none;
}

div.simple-nav {
    display : inline-block;
    a {
        font-size : 14px;
    }

}

@media (max-width : $small-breakpoint) {
    .mobile-block {
        display : block !important;
    }
}

@media (max-width : 768px) {

    ul.crumb-list {

        > li {

            line-height : 40px;

            a, span {
                font-size : rem-calc(12);
            }

            img {
                width : 40px;
            }

            i {
                font-size : rem-calc(22);
            }
        }

    }

}

@media (max-width : 580px) {
    ul.crumb-list {

        > li {
            line-height : 22px;
            margin-left : 5px;

            img {
                display : none;
            }

        }

    }
}

