@import "mixins";
//@import "settings";
//@import "foundation3";
@import "foundation/custom";

#hlxxLightBox .row {
    min-width : 0;
}

//// Shade classes
//@each $color, $color_value in $shades {
//    .#{$color} {
//        background-color : $color_value !important;
//    }
//    .#{$color}-text {
//        color : $color_value !important;
//    }
//}

//body.freethenightok,
//body.breakdown,
//body.evolvement {
//    .brand-color {
//        color: $evolvementColor !important;
//    }
//
//    .brand-background {
//        background-color: $evolvementColor !important;
//    }
//
//    .brand-border {
//        border-color: $evolvementColor !important;
//    }
//}
//
//body.ystreet {
//    .brand-color {
//        color : $ystreetColor !important;
//    }
//
//    .brand-background {
//        background-color : $ystreetColor !important;
//    }
//
//    .brand-border {
//        border-color : $ystreetColor !important;
//    }
//}

.columns.centered {
    @extend .small-centered;
}

textarea {
    min-height : 0;
}

/* MOVED TO _buttons.scss */
//.button {
//    margin: 0;
//}
//
//.button.hide {
//    display : none;
//}

.label {
    color: $secondary-color;
}

.button {
    outline : none;
}

.button.thin {
    padding-top    : 2px !important;
    padding-bottom : 2px !important;
}

.button-grid {
    @extend .block-grid;
}

.button-grid .button {
    width : 100%;
}

ul.inline-list.button-group {
    &.right > li {
        margin-left: 5px;
        margin-right: 0
    }
    &.left > li {
        margin-right: 5px;
        margin-left: 0
    }
}

.no-float {
    float : none !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family : Lato, Verdana, Arial, "Open Sans", sans-serif
}


.block {
    display : block;
}

.divider-bottom {
    border-bottom : 1px solid #eee;

}

.button.wide,
input.wide {
    width : 100% !important;
}

table .button.wide {
    width       : 90% !important;
    margin-left : 5% !important;
}

input.grid_15 {
    @extend .grid_15;
}

input.grid_33 {
    @extend .grid_33;
}

input.grid_50 {
    @extend .grid_50;
}

input.grid_66 {
    @extend .grid_66;
}

.cursor-pointer {
    cursor : pointer !important;
}

a {
}

a:link,
a:visited {
    //font-weight: bold;
    //color:#666;
}

a:hover,
a:active {
    text-decoration : none;
}

a.button:hover,
a.button:active {
    text-decoration : none;
}

a.front,
div.custom > a {
    text-decoration : none;
    color           : $black;
}

button.icon-post {
    padding : 0;
    margin  : 0;
}

select, option {
    font-size : 11px;
}

option {
    font-weight : normal;
    display     : block;
    white-space : pre;
    min-height  : 1.2em;
}

//.nav-bar a,
//.dropdown a,
//a.selector,
//a.current
//  {
//  font-weight: normal;
//}

.pointer {
    cursor : pointer;
}


ul.disc {
    list-style-type : disc;
}

ul.button-group.menu .simple {
    padding-left  : 2px;
    padding-right : 2px;
}

.ellipsis {
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}

.truncate,
.truncate .textValueWrap {
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
    font-size     : inherit;
    width         : 200px !important; // default to medium
    cursor        : pointer;
}

.truncate.auto {
    width : auto !important;
}

.truncate.tiny,
.truncate.tiny .textValueWrap {
    width : 20px !important;
}

.truncate.small,
.truncate.small .textValueWrap {
    width : 125px !important;
}

.truncate.medium,
.truncate.medium .textValueWrap {
    width : 200px !important;
}

.truncate.large,
.truncate.large .textValueWrap {
    width : 300px !important;
}

.assert-fail {
    color       : red;
    font-weight : bold;
    padding     : 10px;

}

select[multiple] {
    height : 100px;
}

select {
    margin : 0 0 12px 0;
}

select.small {
    width : 125px !important;
}

#pointSignup div.error,
#questionSignup div.error {
    padding : 6px;
}

small.error.hide {
    display : none;
}

div.error {
    font-size        : 80%;
    margin           : 10px 0px;
    border-radius    : 10px;
    text-align       : center;
}


div.border {
    border        : 1px solid #999;
    border-radius : 4px;
}

small.success,
small.error {
    text-align  : left;
    padding     : 4px 6px;
    margin-top  : 0;
    clear       : both;
    line-height : 2;
}

.checkbox {
    small.success,
    small.error {
        margin-top : 0;
    }
}

small.success {
    background-color : $success-color;
}

.warning-text {
    color : red !important;
}

form.custom small.error {

}

.alternating:nth-child(even) {
    background-color : #F1F1F1;
}

.alternating:nth-child(odd) {
    background-color : #FFF;
}

.dark {
    color : #bbb;
}

.dark h1,
.dark h2,
.dark h3 {
    @extend .brand-color;
}

.editable {
    cursor : pointer;
}

.editable.hover {
    background-color : $almostWhite;
}

h1.title,
h2.title,
h3.title,
h4.title,
h5.title
{
    color       : $almostBlack;
    font-weight : bold;
}

h1.title.secondary,
h2.title.secondary,
h3.title.secondary,
h4.title.secondary,
h5.title.secondary {
    color : #555;
}

.title > a:hover,
.title > a:hover,
.title > a:hover {
    text-decoration : none;

}

h1.title div.sub-text {
    color       : #444;
    font-weight : normal;
}

.title .sub-title {
    margin    : 2px 0 0 2px;
    color     : #999;
    font-size : 14px;
}

span.fail,
span.alert {
    color : $alert-color;
}

span.success {
    color : $success-color;
}

dd.active span.alert {

    color : $white;
}

.panel {
    border-radius    : 3px;
    background-color : $panelColor;
    line-height      : 1.3;
}

.transparent {
    background-color : transparent !important;
}

a.underlined {
    border          : none;
    text-decoration : underline;

}

.underlined.secondary {
    border-bottom-color : #eee;
}

/******************
 * HR
 *******************/
hr.alert {
    border : 1px dashed red;
}

hr.success {
    border : 1px dashed green;
}

/******************
 * Buttons
 *******************/
.button.success {
    background-color : $success-color !important;
}

.button.alert {
    background-color : $alert-color !important;
}

.button.secondary.alert {
    color : $white;
}

.button-group.tiny .button {
    @extend .tiny;
}

.button-group.radius .button {
    @extend .radius;
}

.button-group.menu {
    @extend .right;
    margin : 0;
}

.button-group.menu.left {
    @extend .left;
}

h2 .button-group.menu {
    margin-top : 5px;

}

h4 .button-group.menu,
h4 .button-group.menu-join {
    margin-top : 6px;
}

h3 .button-group.menu,
h3 .button-group.menu-join {
    margin-top : 7px;
}

.button-group.menu.wide li,
.button-group.menu.wide .button {
    width      : 100%;
    text-align : center;
}

table .button-group.menu.wide .button {
    width       : 90%;
    margin-left : 5%;
    text-align  : center;
}

.button.standard.menu {
    border : 1px solid #999 !important;
}

/******************
 * Labels
 *******************/
label.success {
    color : green !important;
}

label.alert {
    color : red !important;
}

label.input-align {
    line-height : 32px;
}

form.custom div.custom.dropdown a.current {
    font-size                 : 85%;
    border-radius             : 3px;
    -moz-border-radius-radius : 3px;
    -webkit-border-radius     : 3px;
}

form.custom div.custom.dropdown a.selector {
    border-radius             : 0px 3px 3px 0px;
    -moz-border-radius-radius : 0px 3px 3px 0px;
    -webkit-border-radius     : 0px 3px 3px 0px;
}

form.custom.expanded div.custom.dropdown,
form.custom.expanded div.custom.dropdown ul {
    width : 100% !important;
}

form.custom span.custom.checkbox {
    margin-right : 10px;
}

label {
    margin : 5px 0 5px 0;
}

table td label {

    margin : 0;
}

table td.error {
    color : red;
}

.table-key ul li {
    font-size : 11px;
}

table.align-middle tr td {
    vertical-align : middle;
}

.button.dark,
.label.dark {
    background-color : black;
    font-weight      : bold;
    text-transform   : capitalize;
    box-shadow       : 1px 1px 9px #555;
}

.label.light {
    background-color : #f2f2f2;
    box-shadow       : 1px 1px 1px #444;
    color            : black;
    text-transform   : uppercase;
    border           : 1px solid #ddd;
}

.label.dark {

    background-color : #4D4D4D;
    text-transform   : uppercase;
    box-shadow       : 1px 1px 1px #444;
    color            : $white;
}

.button.warning,
.label.warning {
    background-color : orange !important;
}

.button-group.no-focus .button.standard:focus {
    background-color : #e1e1e1;
}

.button-group.no-focus .button.standard.selected:focus {
    background-color : #888;
}

.button.collapse {
    padding : 3px 2px;
}

table,
ul.table {
    border-radius : 3px;
    border        : 1px solid #DDD;
}

table {
    border-spacing : 0;
}

table.vertDivider th,
table.vertDivider td,
table tr.vertDivider th,
table tr.vertDivider td {
    border-right : 1px solid #DDD;
}

ul.table {
    margin-bottom : 10px;
}

ul.table li {
    background : #FFF;
    padding    : 5px;
}

table tbody tr:nth-child(even),
ul.table li:nth-child(odd)
{
    background-color : #f1f1f1;
}

ul.table li:nth-child(even) {
    text-indent : 10px;
    padding     : 3px 5px;
}

ul.table.sym li {

    padding     : 5px;
    text-indent : 0;
}

table.td-collapse tr td {
    padding : 0;
}

table.goal {
    border-collapse : collapse;
}

table.goal,
table.goal tr th,
table.goal tr td {
    border    : 1px solid #ddd;
    font-size : 11px;
}

table.goal div {
    font-size : 11px;
}

ul.table.collapse li {

}

table tbody tr td.index {
    border-right : 1px solid #ddd;
    text-align   : center;
    color        : #555;
    font-size    : 75%;
}

table thead tr th,
table tbody tr td {
    padding   : 9px 5px;
    font-size : 13px;
}

table tbody tr.header th {
    border-top    : 1px solid #000;
    border-bottom : 1px solid #000;
}

table tbody tr th {
    color       : #484848;
    background  : #f2f2f2;
    font-size   : 13px;
    padding     : 9px 5px;
    line-height : 18px;
}

table thead,
table thead tr th,
table tfoot {
    color      : #484848;
    background : $tableColor;
    font-size  : 13px;
}

table.secondary thead,
table.secondary thead tr th,
table.secondary tfoot {
    color      : #484848;
    background : #e1e1e1;
}

table.secondary tr th.index {
    border-right : 1px solid #FFF;
}

table th {
    text-transform : capitalize;
}

table.middle td {
    vertical-align : middle;
}

table.thin thead tr th,
table.thin tfoot tr th {
    padding : 3px 10px;
}

table.condensed thead th,
table.condensed th,
table.condensed tbody td,
table.condensed thead th div,
table.condensed tbody td div,
table.condensed thead th a,
table.condensed tbody td a,
table.condensed .button {
    font-size   : 12px;
    font-weight : normal;
    text-align  : left;
}

table.condensed.small thead th,
table.condensed.small th,
table.condensed.small tbody td,
table.condensed.small thead th div,
table.condensed.small tbody td div,
table.condensed.small thead th a,
table.condensed.small tbody td a,
table.condensed.small.button {
    font-size : 11px;
}

table.condensed tbody td,
table.condensed tbody th {
    padding : 6px 5px;
}

table.condensed.more tbody td,
table.condensed.more thead th {
    padding : 2px 5px;
}

table.condensed .button {

    font-size : 11px;
    padding   : 2px 5px;
}

table.condensed [type=checkbox] {
    margin: 4px 0 0 5px;
}

table.simple {
    border-collapse : collapse;
    border          : 1px dotted #aaa;
}

table.simple thead,
table.simple thead tr th,
table.simple tfoot,
table.simple tbody {
    background-color : transparent;
}

table.simple thead tr th,
table.simple tbody tr,
table.simple tbody tr td {
    border           : 1px dotted #aaa;
    background-color : transparent;
}

.button.condensed {
    padding-top    : 4px !important;
    padding-bottom : 4px !important;
    margin         : 0;
}

.strong {
    font-weight : bold;
}

li.title {
    font-weight : bold;
    font-size   : 110%;;
}

.profile-list {
    margin-left      : 0;
    margin-bottom    : 20px;
    background-color : transparent;
    border-right     : 1px solid #ececec;
}

.profile-list > li.title {
    background-color : #ececec;
    padding          : 5px 10px;
    text-align       : left;
    font-size        : 12px;
    color            : #333333;
    font-weight      : bold;
}

.profile-list > li {
    background-color : transparent;
    padding          : 10px 15px 10px 15px;
    text-align       : left;
    font-size        : 12px;
    color            : #444;
    font-weight      : normal;
}

.pricing-table.condensed .price {
    font-size : 14px;
}

.pricing-table .cta-button {
    padding: 20px;
}

.pricing-table.collapse li,
.pricing-table.collapse div.row {
    padding-top    : 0;
    padding-bottom : 0;
}

.pricing-table.collapse a {
    padding : 7px 0;
    display : block;
}

.pricing-table.collapse div.row {
    margin-bottom : 0;
}

.pricing-table.selected {
    border : 1px solid $success-color;
}

.pricing-table.highlighted {
    border : 1px solid black;
}

.pricing-table.hover li:hover {
    background-color : #ddd;
}

.pricing-table.highlighted li.price {
    background-color : $almostBlack;
    color            : $white;
    font-weight      : bold;
}

.pricing-table.highlighted li.description {
    color : $almostBlack;
}

.pricing-table.selected li.price {
    background-color : $success-color;
    color            : $white;
}

.pricing-table.condensed .price,
.pricing-table.condensed .description,
.pricing-table.condensed .bullet-item {
    padding : 5px 15px;
}

.pricing-table.condensed .bullet-item,
.pricing-table.condensed .bullet-item a {

    font-size : 12px;
}

.pricing-table.condensed .row {
    margin-bottom : 0;
}

.fancy-list {
    @extend .pricing-table;
    background-color : $white;
    border-radius    : 3px;
}

.fancy-list.no-bullet li,
.fancy-list li.title,
.fancy-list li.price {
    list-style : none;
}

.fancy-list li.price {
    font-size : 16px;;
}

.fancy-list li.bullet-item {
    color     : #555;
    font-size : 13px;
}

.fancy-list.small li,
.fancy-list.small li.price,
.fancy-list.small li.bullet-item {
    font-size : .9em;
}

.fancy-list li,
.fancy-list li.title,
.fancy-list li.price {
    padding : 11px;
}

.fancy-list.condensed {
    li,
    li.description,
    li.description a,
    li.description div {
        font-size : 95%;
    }

}

.fancy-list li {
    list-style : inside;
}

.fancy-list li:nth-child(even) {
    background : #F9F9F9;
}

.simple-list li {
    padding     : 3px 0;
    text-indent : 10px;
}

.simple-list li:first-child {
    padding-top : 0;
}

.simple-list .underlined {
    margin-bottom : 4px;
}

.simple-list {
    margin-bottom : 10px;
}

.simple-list li.header {
    font-size   : 1.3em;
    text-indent : 0;
}

div.row-buttons {
    padding : 20px;
}

div.row-buttons .simple-list a {
    display : block;
    color   : #555;
}

div.row-buttons .simple-list a:hover {
    background-color : #efefef;
}

.label.standard {
    @extend .radius !optional;
    padding : 2px 8px;
}

ul.label-list {
    margin-left : -7px;
}

.collapse {
    padding-top    : 0 !important;
    padding-bottom : 0 !important;
}

.no-padding {
    padding : 0 !important;
}

.collapse-partial {
    padding-top    : 10px !important;
    padding-bottom : 10px !important;
}

.trim {
    margin : 0 !important;
}

.trim-partial-tiny {
    margin : 2px !important;

}

.trim-partial {
    margin : 5px !important;
}

.trim ul,
ul.inline-list.trim,
ul.trim li {
    margin : 0;
}

div.filter-panel ul.inline-list li {
    margin-left  : 0;
    margin-right : 10px;
}

div.filter-panel select {
    margin : 0;
}

div.filter-panel label {
    font-size : 12px;
}

div.filter-panel input {
    font-size : 12px;
    height    : auto;
}

div.member-browse {

    div.filter-panel select {
        min-width : 150px;
    }

}

a.filter-reset,
a.filter-link {
    font-size   : 12px;
    margin-left : 5px;
}

.text-left {
    text-align : left !important;
}

.panel.trim {
    margin-bottom : 5px !important;
}

span.prefix,
.prefix {
    z-index : 1;
}

.block-grid.collapse {
    margin : 0;
}

.block-grid.collapse > li {
    padding : 0;
}

.spaced,
.button.spaced {
    margin : 4px;
}

.big.spaced {
    margin : 8px;
}

.short {
    min-height : 60px;
}

small.error {
    margin-bottom : 20px;
}

.no-pad-right {
    padding-right : 0 !important;
}

.pad-ul {
    margin-left : 22px !important;
}

.pad-top-tiny {
    margin-top : 5px !important;
}

.pad-top-small {
    margin-top : 10px !important;
}

.pad-top {
    margin-top    : 25px !important;
    margin-bottom : 0px;
}

.pad-top-big {
    margin-top : 50px !important;
}

.space-top-big {
    margin-top : 100px !important;
}

.space-bottom {
    margin-bottom : 25px !important;
}

.space-bottom-half {
    margin-bottom : ($marginSpace / 2) !important;
}

.space-bottom {
    margin-bottom : $marginSpace !important;
}

.space-bottom-double {
    margin-bottom : ($marginSpace * 2) !important;
}

.real-pad-top {
    padding-top : 10px;
}

.entypo.pad-left,
.pad-left
{
    margin-left : 5px;
}

.space-left-tiny {
    margin-left : $tinySpace;
}

.space-left-small {
    margin-left : $smallSpace;
}

.space-left {
    margin-left : $space;
}

.space-left-big {
    margin-left : $bigSpace;
}

.short {
    min-height : 60px;
}

.tall {
    min-height : 120px;
}

.taller {
    min-height : 200px;
}

.bright.panel {
    background-color : white;
    padding          : 10px;
    border-radius    : 5px;
    border           : 1px solid #CCC;
}

.highlighted {
    color      : #FFFA7B !important;
    box-shadow : 1px 1px 9px yellow;
}

.dropdown.available-items {
    top              : 31px;
    box-shadow       : 3px 3px 9px #CCC;
    border           : 1px solid #CCC;
    position         : absolute;
    background-color : white;
    padding          : 4px;
    z-index          : 6;
    border-radius    : 0px 0px 5px 5px;
    border-top       : none;
    width            : 100%;
}

ul.tabs-content.hide {
    display : none;
}

dl.tabs.pill.collapse {
    margin : 0;

}

dl.tabs.tiny {
    height : 30px;
}

dl.tabs.pill.tiny dd a {
    line-height : 22px;
    height      : 22px;
    font-size   : .9em;
}

.tabs.secondary dd, .tabs.secondary li {
    border-top   : none;
    margin-top   : -4px;
    margin-right : -1px;
}

.tabs.secondary dd.active a, .tabs.secondary li.active a {
    background-color : #212121;
    color            : $white;
}

.tabs.secondary dd a, .tabs.secondary li a {
    line-height      : 25px;
    height           : 25px;
    background-color : #cccccc;
    color            : $almostBlack;
    font-weight      : bold;
    padding          : 0 20px;
    margin-right     : 1px;
}

.tabs.secondary {
    border-bottom : 4px solid #212121;
    height        : 25px;
}

.tabs.right {
    float : none;
}

.tabs.right dd {
    float : right;
}

.relative {
    position : relative;
}

.weak {
    color     : $weakColor;
    font-size : 90%;
}

.weak.tiny {
    font-size : 70%;
}

h4 .weak {
    font-size  : 60%;
    margin-top : 10px;
}

.panel .weak {
    color : #777;
}

.metric.empty {
    color : #999;
}

h4 .weak.sub-text {
    font-size : 50%;
}

.pad-small {
    padding : 4px;
}

.pad {
    padding : 8px;
}

.pad-big {
    padding : 12px;
}

.pad-bigger {
    padding : 20px;
}

.selected-items .dropdown-item.padded,
.padded {
    margin : 4px;
}

label.required span {
    font-size : .9em
}

fieldset legend {
    background-color : inherit;
}

fieldset {
    padding : 12px;
}


.hide,
.entypo.hide,
div.hide,
div.alert.hide,
div.alert-box.hide,
input.hide,
ul.inline-list > li .hide,
a.hide {
    display : none;

}

.alert-box.alert a {
    color : #fff;
}

.alert-box.alert a.secondary {
    color : #000;
}

div.alert-box.warning {
    background-color : $warning-color;
}


.alert-box {
    &.secondary {
        background-color: $primary-color;
        color: $black;
    }
}

div.simple-alert {
    text-align     : center;
    text-transform : uppercase;
}

div.simple-alert h2 {
    margin-top : 14px;
}

.border-right {
    border-right : 1px solid #bbb;
}

dl.sub-nav.trim {
    margin-bottom : 0;
}

dl.sub-nav.tiny dd a {
    font-size : 12px;
}

a.all-reset,
a.query-reset {
}

a.query-reset {
    @extend .secondary !optional;
    margin-right : 20px;

}

ul.inline-list.small li,
label.small {
    font-size : 12px;
    color     : #666;
}

label.small {
    margin : 0 0 5px 6px;
}

ul.inline-list.small li {
    margin : 0 0 0 28px;
}

ul.inline-list li.right {

    float : right !important;
}

div.alert-box h3 {
    font-size      : 18px;
    padding-top    : 0;
    padding-bottom : 0;
}

div.alert-box.success button,
div.alert-box.success a {
    padding   : 3px 30px;
    font-size : 14px;
    margin    : 0 5px;
}

div.alert-box.success.border {
    margin-top : 5px;
    background : none;
    border     : 1px solid $success-color;
    color      : $success-color;
}

div.alert-box.success.border label {
    color : $success-color;
}

div.alert-box.thin {
    padding-top    : 2px;
    padding-bottom : 2px;
}

div.alert-box.success button:hover {
}

div.alert-box.alert h2
div.alert-box.alert h3,
div.alert-box.alert h4
{
    color : #FFF;
}

div.alert-box.alert p {
    margin-bottom : 0;
}

label.modified {
    color : green;
}

select.error {
    border : 1px solid $alert-color;
}

.reveal-modal-bg {
    //background-color: #D8D8D8;
}

label.modified.text {
    text-align    : right;
    display       : none;
    color         : green;
    font-size     : 12px;
    margin-top    : -8px;
    margin-bottom : 3px;
}

h2 label {
    font-size : 22px;
}

.alert-box ol,
.alert-box ul {
    margin-bottom : 0;
}

.text-value {
    cursor : pointer;
}

.text-value:hover {
    text-decoration : underline;
}

.alert-text {
    color : red !important;
}

.label.list {
    width          : 100%;
    display        : inline-block;
    padding-top    : 4px;
    padding-bottom : 4px;
}

.published-alert {
    font-size : 14px;
    color     : red;
}

.published .published-alert {
    color : green;
}

.campaignName .edit {
    padding-left : 24px;
    font-size    : 12px;
}

.ref-links.weak {
    color : #666;
}

.ref-links.weak a {
    color : blue;

}

.mop-item {
    padding : 13px 0 21px 0;

}

.mop-listing > .mop-item:first-child {
}

.mop-item .title {
    padding-bottom : 5px;
}

.mop-item .description {
    color : #333;
}

.mop-item .value {
    line-height : 1.0em;
}

.mop-listing.edit.inactive .mop-item {
    background-color : #D7D9EB;
}

.mop-listing.edit.inactive .mop-item.hover {
    background-color : #B8BDF0;

}

.button.highlight {
    background  : yellow !important;
    color       : black !important;
    font-weight : bold;
}

a.vr-left.secondary {
    border-left : 2px solid #666;
}

div.comment-thread.row {
    margin-left : 55px;
}

div.comment-thread hr {
    border-color : #eee;
}

div.comment-one {
    line-height : 1.2em
}

div.comment-one div,
div.comment-one a {
    font-size : 11px;
}

div.comment-one .weak {
    font-size : 10px;
}

div.comment-box textarea {
    font-size     : 11px;
    height        : 28px;
    margin-left   : 1%;
    width         : 98%;
    margin-bottom : 0;
}

div.comment-box textarea.expanded {
    height : 100px;
}

div.comment-box textarea.expanded-50 {
    height : 50px;
}

div.comment-one div.image {
    padding : 0 5px 0 7px;
}

div.comment-one div.info {
    margin-left : 48px;
}

div.comment-one div.text {
    padding : 2px 0;
}

div.action-list > div.flag {
    padding-right : 5px;
}

div.comment-one div.action-list > div.weak {
}

a.show-more-button {
    font-size : 11px;
    display   : block;
    padding   : 0 0 9px 9px;
}

div.notification-one {
    line-height : 1.4em;
}

div.notification-one div,
div.notification-one a {
    font-size : 12px;
}

div.notification-one div.image,
div.notification-one div.content {
    float : left;
}

div.notification-one div.image image {
    width : 50px;
}

div.notification-one div.image {
    padding : 0 5px 0 20px;
}

#notifyPreview div.notification-one div.image {
    padding : 0 5px;
}

#notificationsContainer {
    div.notification-one div.image {
        padding : 0 5px;
    }

    div.notification-one div.content {
        width : 80%;
    }

    input[type="checkbox"] {
        margin-top : 15px;
    }
}

/******************
 * Homepage Style
 *******************/

div.quick-post {
    background-color : #ececec;
}

div.quick-post > div.columns {
    padding-top : 12px;
}

div.quick-post div.post-icon {
    margin-top : 5px;
}

div.quick-post div.text {
    float       : left;
    margin-left : 5px;
    margin-top  : 8px;
    font-size   : 12px;
}

div.quick-post textarea {
    font-size : 12px;
}

div.quick-post textarea.expanded {
    height : 100px;
}

div.quick-post .post-submit {
    margin-bottom : 10px;
}

ul.tabs-content {
    div.quick-post {
        padding : 15px 0 0 0;
    }

    div.quick-post div.text {
        margin-top : 6px;
    }

    .front.lowerBarIcon {
        margin-top : 0;
    }
}

/******************
 * Activity/Feed Style
 *******************/

div#feedHeader {
    padding-left  : 20px;
    padding-right : 20px;
    height        : 62px;
    border-bottom : 1px solid #eee;

}

div#feedHeader div.content.row {
    padding-top : 23px;
}

div#feedHeader a.text,
div#feedHeader div.text {
    float       : left;
    margin-top  : 3px;
    margin-left : 2px;
}

div#feedHeader a.text {
    color : #aaa;
}

div#feedHeader a.text.active {
    color : $black;
}

div#feedHeader div.text {
    font-size : 12px;

}

div#feedHeader ul.filters div.feedFilterIcon {
    float : left;

}

div#feedHeader ul.title {
    margin-left : -18px;
}

div#advisor-home {

    div#feedHeader {
        height : 40px;
    }

    div#feedHeader div.content.row {
        padding-top : 0;
    }

    #sectionsTab {
        margin-top : 100px;
    }

}

div#feedContent {
    margin-top : 10px;
}

div.feed-item {
    border-bottom : 1px solid #eee;
    padding       : 15px 0;
}

div.feedFilterIcon {
    margin-right : 10px;
}

div.activity-one {
    line-height : 1.4em;
}

div.activity-one div.image {
    float : left;
}

div.activity-one a {
    font-weight : normal;
    color       : #030364;
}

div.activity-one a:hover {
    text-decoration : underline;

}

div.activity-one a.actor-name {
    margin-right : 10px;
    font-size    : 12px;
    font-weight  : bold;
    color        : black;
}

div.activity-one span.type-name,
div.activity-one span.type-name a {
    font-size : 11px;
    color     : #9e9e9e;
}

div.activity-one span.type-name a {
    font-weight : bold;
}

div.activity-one div.message {
    margin-bottom : 10px;
    font-size     : 13px;
}

div.activity-one div.content-box {
    margin-left   : 57px;
    margin-bottom : 10px;
}

div.activity-one div.row {
    margin-bottom : 5px;
}

div.activity-one div.content div.row:after {
    clear : none;
}

div.activity-one div.action-list > div {
    float : left;
}

div.action-list div.date {
    font-size    : 10px;
    margin-right : 10px;
    margin-top   : 2px;
}

div.action-list .flag {
    float : left;
}

/******************
 * Point Style
 *******************/

.point-one {

}

.point-one .lang {
    width : 250px;
}

div.notification-one a,
div.comment-one a.name {
    font-weight : bold;
    color       : black;
}

div.comment-one a {
    color : #030364;
}

div.comment-one a:hover {
    text-decoration : underline;
}


.notification {
    &.sticky p, &.floated p, &.fixed p, &.ondemand p {
        float       : left;
        padding     : 0px;
        margin      : 0px;
        margin-left : 10px;
        line-height : 25px;
        color       : #fff;
        font-size   : 17px;
        width       : 90%;
        text-align  : center;
    }
}

.sticky a, .floated a, .fixed a, .ondemand a {
    float  : right;
    margin : 3px 15px 0px 0px;
}

.wrapper {
    padding : 20px;
}

.sticky {
    padding: 15px 0;
    position           : fixed;
    bottom             : 0;
    left               : 0;
    z-index            : 2000;
    width              : 100%;
    //border-top         : 3px solid #fff;

    //background         : #91BD09; /* Old browsers */
    //background         : -moz-linear-gradient(top, #91BD09 0%, #91BD09 100%); /* FF3.6+ */
    background         : #0e59ae; /* Old browsers */
    background         : -moz-linear-gradient(top, #0e59ae 0%, #0e59ae 100%); /* FF3.6+ */

    /* FireFox 3.6 */
    /* Safari4+, Chrome */
    -ms-filter         : "progid:DXImageTransform.Microsoft.gradient(startColorstr='#91BD09', endColorstr='#91BD09')";
    //    -pie-background    : linear-gradient(#0e59ae, #0e59ae 100%);
    //    behavior           : url(PIE.htc);
    -moz-box-shadow    : 1px 1px 7px #676767;
    -webkit-box-shadow : 1px 1px 7px #676767;
    box-shadow         : 1px 1px 7px #676767;
//    height             : 45px;
    background-image   : -webkit-gradient(linear, left bottom, left top, color-stop(0, #0e59ae), color-stop(1, #0e59ae)); /* IE6,IE7 */
    opacity            : .95;
    /* IE8 */
    /* Firefox F3.5+ */
    /* Safari3.0+, Chrome */
}

.sticky.top {
    top        : 0;
    border-top : none !important;
}

.floated {
    position           : absolute;
    top                : 0;
    left               : 0;
    z-index            : 1000;
    width              : 100%;
    border-bottom      : 3px solid #fff !important;
    background         : #0e59ae; /* Old browsers */
    background         : -moz-linear-gradient(top, #0e59ae 0%, #0e59ae 100%); /* FF3.6+ */

    -ms-filter         : "progid:DXImageTransform.Microsoft.gradient(startColorstr='#0E59AE', endColorstr='#0E59AE')";

    -moz-box-shadow    : 1px 1px 7px #676767;
    -webkit-box-shadow : 1px 1px 7px #676767;
    box-shadow         : 1px 1px 7px #676767;
    height             : 45px;
    background-image   : -webkit-gradient(linear, left bottom, left top, color-stop(0, #0E59AE), color-stop(1, #0E59AE)); /* IE6,IE7 */
    //    -pie-background    : linear-gradient(#0E59AE, #0E59AE 100%);
    //    behavior           : url(PIE.htc);
}

.fixed {
    position           : absolute;
    bottom             : 0;
    left               : 0;
    width              : 100%;
    border-top         : 3px solid #fff !important;

    background         : #660099; /* Old browsers */
    background         : -moz-linear-gradient(top, #660099 0%, #660099 100%); /* FF3.6+ */

    /* FireFox 3.6 */
    /* Safari4+, Chrome */
    -ms-filter         : "progid:DXImageTransform.Microsoft.gradient(startColorstr='#660099', endColorstr='#660099')";
    //    -pie-background    : linear-gradient(#660099, #660099 100%);
    //    behavior           : url(PIE.htc);
    -moz-box-shadow    : 1px 1px 7px #676767;
    -webkit-box-shadow : 1px 1px 7px #676767;
    box-shadow         : 1px 1px 7px #676767;
    height             : 45px;
    background-image   : -webkit-gradient(linear, left bottom, left top, color-stop(0, #660099), color-stop(1, #660099)); /* IE6,IE7 */
    /* IE8 */
    /* Firefox F3.5+ */
    /* Safari3.0+, Chrome */
}

.ondemand {

    width              : 100%;
    border-bottom      : 3px solid #fff !important;
    position           : absolute;
    top                : 0;
    left               : 0;
    z-index            : 1000;

    background         : #CC0000; /* Old browsers */
    background         : -moz-linear-gradient(top, #CC0000 0%, #CC0000 100%); /* FF3.6+ */

    /* FireFox 3.6 */
    /* Safari4+, Chrome */
    -ms-filter         : "progid:DXImageTransform.Microsoft.gradient(startColorstr='#CC0000', endColorstr='#CC0000')";
    -pie-background    : linear-gradient(#CC0000, #CC0000 100%);
    //    behavior           : url(PIE.htc);
    -moz-box-shadow    : 1px 1px 7px #676767;
    -webkit-box-shadow : 1px 1px 7px #676767;
    box-shadow         : 1px 1px 7px #676767;
    height             : 45px;
    background-image   : -webkit-gradient(linear, left bottom, left top, color-stop(0, #CC0000), color-stop(1, #CC0000)); /* IE6,IE7 */
    /* IE8 */
    /* Firefox F3.5+ */
    /* Safari3.0+, Chrome */
}

.sticky.error {
    background : $alert-color;
}

/******************
 * Edit Profile
 *******************/

div.row.edit-profile-field {
    border-bottom : 1px solid #ddd;
    padding       : 10px 0;
}

div.row.edit-profile-field:last-child {
    border : none;
}

div.row.edit-profile-field.disabled {
    background-color : #f4f4f4;
}

div.row.edit-profile-field div.field,
div.row.edit-profile-field div.field-label {
    padding-top : 8px;
}

div#hlxxModalContent form#locationEdit {
    margin-top : 20px;
}

div#hlxxModalContent form#locationEdit > .row {

    margin-bottom : 10px;
}

div.entypo.valid {
    color : green;
}

div.entypo.missing {
    color : red;
}

form#locationEdit select {
    margin-top : 8px;
}

input[type=submit].button.small {
    margin-top : 1px;
}

.plist td {
    border-right : 1px solid #dddddd;
}

table.reporting a {
    color : #160D7E;
}

div#current-product-tags .button,
div#possible-product-tags .button {
    margin-bottom : 2px;
}

ul.breadcrumbs {
    margin-bottom : 20px;
}

ul.breadcrumbs li a {
    color: $black;
}


ul.breadcrumbs li span.current,
ul.breadcrumbs li.current a {
    font-weight : bold;
    color: $black;
}

img.stdIcon {

    border        : none;
    box-shadow    : 0 0 6px #ccc;
    border-radius : 5px;
}

#sidebar img.stdIcon {
    box-shadow : none;
}

/***********************
 * User Listing
 ***********************/

.user-rank .avatar {

}

.user-rank .info {
    margin-left : 5px;
}

.user-rank .info,
.user-rank .info a {
    font-size : 12px;
}

.user-rank a.name {
    padding : 0 0 5px 0;
}

.user-rank .info a {

    font-weight : bold;
}

.user-rank div.rank {
    font-size  : 22px;
    margin-top : 0;
}

div.progress.five-steps .meter {
    width : 20%;
}

div.progress.branded {
    .meter {
        color: $secondary-color;
    }
}

/**
 * Branded progress meter
 */


//body.evolvement {
//    div.progress.branded .meter {
//        @extend .brand-background;
//        color      : $almostBlack;
//    }
//
//    div.progress.branded .meter.none {
//        background : $iron;
//        color      : $white;
//    }
//}
//
//body.ystreet {
//
//    div.progress.branded .meter {
//        @extend .brand-background;
//        color      : $almostBlack;
//    }
//
//    div.progress.branded .meter.none {
//        background : $iron;
//        color      : $white;
//    }
//
//}

#userNavSearchResults ul.pricing-table {
    margin        : 0;
    margin-bottom : 10px;
}

#userNavSearchResults div.text-container {
    font-size : 13px;
}

#userNavSearchResults div.count {
    float        : right;
    margin-right : 5px;
}

/**
 *  Team Listing
 */

.user-avatar .info {
    font-size : 11px;
    height    : 2rem;
}

.framed {
    padding : 10px;
    border  : 1px solid #ddd;
}

.panel .framed {
    border : 1px solid #ddd;
}

.tb-field-container ul.inline-list {
    margin-bottom : 0;

}

.tb-field-container ul.inline-list li {
    margin-left : 10px;
}

.tb-field-container ul.inline-list li label {
    font-size : 10px;
}

/**
 *  Sort menu
 */
.sort-menu .sort-label,
.sort-menu .sort-options a {
    @extend .weak;
    font-size   : 12px;
    float       : left;
    margin-left : 10px;
}

.sort-menu .sort-options a {
    border-top    : 1px solid #FFF;
    border-bottom : 1px solid #FFF;
}

.sort-menu .sort-options a.sort.asc,
.sort-menu .sort-options a.sort.desc {
    background-image : none;
    color            : #555;
    padding          : 0;
}

.sort-menu .sort-options a.sort.asc {
    border-bottom : 1px solid #555;
}

.sort-menu .sort-options a.sort.desc {
    border-top : 1px solid #555;
}

ul.search-list {
    margin : 0;
}

li.border-block {
    padding          : 5px;
    box-shadow       : 0 0 5px #eee;
    border           : 1px solid #e7e7e7;
    float            : left;
    margin           : 0 5px 5px 0;
    background-color : white;
}

ul.search-list > li {
    width     : 49%;
    min-width : 350px;
}

@media only screen and (max-width : 1441px) {
    ul.search-list > li {
        width : 99%;
    }
}

div.user-block {
    width : 100%;
}

div.user-block.condensed {
    width    : 100%;
    position : relative;

    a {
        height: 50px;
        display:block;

        &:hover {
            background-color: #f9f9f9;
        }
    }
}

div.user-block div.image-content {
    float      : left;
    overflow   : hidden;
    max-width  : 100px;
    background : #f4f4f4;
    height: 100px;
}

div.user-block.condensed div.image-content {
    width  : 50px;
    height : 50px;
}

div.user-block div.text-content {
    display : inline-block;
    width     : 85%;
    padding   : 10px;
    word-wrap : break-word;
}

div.user-block.condensed div.text-content {
    padding : 2px 10px;
}

div.user-block div.text-content div {
    color       : #333;
    font-size   : 12px;
    font-weight : bold;
    word-wrap   : break-word;
    line-height : 1.4em;
}

div.user-block div.text-content div.strong {
    font-size : 14px;
}

div.user-block.condensed div.text-content div {
    font-size : 12px;
}

div.user-block div.text-content div.weak {
    font-size : 10px;
    color     : #777;
}


input.user-search-input {
}

#memberFilters.panel label {
    font-size : 12px;
}

#memberResults .show-more.button {
    margin-top : 10px;
    float      : left;
    clear      : both;
}

small.save-error.error,
small.save-success.success {
    margin    : 7px 0 0 20px;
    font-size : 12px;
}

div.goal-form-container {
    margin-top    : 30px;
    margin-bottom : 30px;
    width         : 94%;
    margin-left   : 3%;
}

div.reveal-modal.full {
    width       : 100%;
    margin-left : 0;
    left        : 0;
}

hr.dashed {
    border-style : dashed;
}

label.disabled {
    color : $weakColor;
}

.button.submit {
    margin-top : 4px;
    @extend .standard;
}

#report-summary select {
    margin : 0;
}

#report-summary .reset-drop {
    font-size  : 11px;
    height     : 20px;
    margin-top : -5px;
}

#report-summary .reset-drop a {
    display   : none;
    font-size : 11px;
    color     : #666;
}

#report-date-modal {
    dl#sectionsTab {
        height : 30px;

    }

    dl#sectionsTab a {
        height      : 30px;
        line-height : 30px;
    }

    #sectionsTabContent .button {
        font-size      : 12px;
        padding-top    : 10px;
        padding-bottom : 10px;
    }

    .button.selected {
        @extend .front;
        border         : 1px solid black !important;
        font-weight    : normal !important;
        text-transform : none !important;
    }
}

#report-summary .block-grid select {
    width : 100% !important;
}

#report-summary select {
    min-width : 150px;
}

ul.inline-list.checklist label {
    font-size : 12px;
}

ul.inline-list.checklist li:first-child {
}

ul.inline-list.checklist li {
    margin-left : 10px;
}

.input-font .button,
.input-font.button {
    font-size : $inputFontSize;
}

label .reset-filter {
    color        : #999;
    font-size    : 80%;
    padding-left : 10px;
}

.team-edit {
    .pricing-table li {
        text-align : left;
    }

    .simple-list li {
        border        : 1px solid #eee;
        padding       : 10px;
        margin-bottom : 10px;
    }
}


//.alert-box.alert {
//    color: #fff !important;
//}



























