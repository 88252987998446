//@each $brand_name, $colors in $brandColors {
//    body.#{$brand_name} {
        @each $color_type, $color_value in $color-shades {
            @if $color_type == "base" {
                .brand-color {
                    color : $color_value !important;
                }

                &.brand-background,
                .brand-background {
                    background-color : $color_value !important;

                    &.button:hover {
                        background-color: scale-color($color_value, $lightness: 20%) !important;
                    }
                }

                .brand-border {
                    border-color : $color_value !important;
                }

            } @else {
                .brand-color.#{$color_type} {
                    color : $color_value !important;
                }

                &.brand-background.#{$color_type},
                .brand-background.#{$color_type} {
                    background-color : $color_value !important;

                    &.button:hover {
                        background-color : scale-color($color_value, $lightness : 20%) !important;
                    }
                }

                .brand-border.#{$color_type} {
                    border-color : $color_value !important;
                }
            }
        }
//    }
//}





