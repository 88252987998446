@import "settings";

@font-face {
    font-family : entypo;
    src         : url('/css/fonts/entypo.ttf?test=1'), url('/css/fonts/entypo.eot?test=1'); /* IE9+ */
}

body {
    background-color : $white;

}

body .nice-font {
    font-family : "Helvetica Neue", Helvetica, Verdana, Arial;
}

body .nice-font {
    h1, h2, h3, h4, h5, h6 {
        font-family : "Helvetica Neue", Helvetica, Verdana, Arial;
    }
}

.minigrant-login {

    label {
        font-size: rem-calc(22);
    }

}

//body.helxxBgDesign
//{
//    background-image : url('/images/sidebarbg.jpeg');
//}
//
//body.helxxBgDesign.evolvement
//{
//    background-image : url('/images/evolvement/bg.jpg');
//}
//
//body.evolvement .highlight.secondary {
//    @extend .brand-background;
//}
//
//body.ystreet .highlight.secondary {
//    background-color : $evolvementColor !important;
//    @extend .brand-background;
//
//}
//
//body.evolvement .highlight
//{
//    background-color : $evolvementColor !important;
//    @extend .brand-background;
//}
//
//body.ystreet .highlight
//{
//    background-color : $ystreetColor !important;
//    @extend .brand-background;
//}
//
//body.evolvement .highlight.selected
//{
//    background-color : $evolvementDarkColor !important;
//    @extend .brand-background;
//}
//
//body.ystreet .highlight.selected
//{
//    background-color : $ystreetDarkColor !important;
//    @extend .brand-background;
//}
//
//body.evolvement .color100
//{
//    background-color : $evolvementColor100 !important;
//    @extend .brand-background;
//}
//
//
//
//body.evolvement .button.highlight:hover
//{
//    background-color : $evolvementDarkColor !important;
//    @extend .brand-background;
//}
//
//body.ystreet .button.highlight:hover
//{
//    background-color : $ystreetDarkColor !important;
//}

//body.breakdown,
//body.ystreet {
//    .color100
//    {
//        background-color : $ystreetColor100 !important;
//    }
//
//    .color200
//    {
//        background-color : $ystreetColor200 !important;
//    }
//
//    .color300
//    {
//        background-color : $ystreetColor300 !important;
//    }
//
//    .color400
//    {
//        background-color : $ystreetColor400 !important;
//    }
//
//    .color500
//    {
//        background-color : $ystreetColor500 !important;
//    }
//
//    .color600
//    {
//        background-color : $ystreetColor600 !important;
//    }
//
//    .color700
//    {
//        background-color : $ystreetColor700 !important;
//    }
//
//    .color800
//    {
//        background-color : $ystreetColor800 !important;
//    }
//
//    .color900
//    {
//        background-color : $ystreetColor900 !important;
//    }
//
//    .accent100 {
//        background-color: $ystreetAccent100;
//
//    }
//    .accent200 {
//        background-color: $ystreetAccent200;
//
//    }
//    .accent400 {
//        background-color: $ystreetAccent400;
//
//    }
//    .accent700 {
//        background-color: $ystreetAccent700;
//    }
//}

//body.freethenight,
//body.evolvement {
//    .color100
//    {
//        background-color : $evolvementColor100 !important;
//    }
//
//    .color200
//    {
//        background-color : $evolvementColor200 !important;
//    }
//
//    .color300
//    {
//        background-color : $evolvementColor300 !important;
//    }
//
//    .color400
//    {
//        background-color : $evolvementColor400 !important;
//    }
//
//    .color500
//    {
//        background-color : $evolvementColor500 !important;
//    }
//
//    .color600
//    {
//        background-color : $evolvementColor600 !important;
//    }
//
//    .color700
//    {
//        background-color : $evolvementColor700 !important;
//    }
//
//    .color800
//    {
//        background-color : $evolvementColor800 !important;
//    }
//
//    .color900
//    {
//        background-color : $evolvementColor900 !important;
//    }
//
//    .accent100 {
//        background-color: $evolvementAccent100;
//    }
//    .accent200 {
//        background-color: $evolvementAccent200;
//    }
//    .accent400 {
//        background-color: $evolvementAccent400;
//    }
//    .accent700 {
//        background-color: $evolvementAccent700;
//    }
//}

.my-page {
    background-color : white;
    box-shadow       : 0px 0px 20px rgba(0, 0, 0, .2);
}

#main {
    padding-left  : 40px;
    padding-right : 40px;
    margin-top    : 25px;
    margin-bottom : 50px;
    min-height    : 900px;
}

.separator {
    background-image  : url('/images/separatorGradient.jpg');
    background-repeat : repeat-x;
}

hr.separator {
    background-image  : url('/images/gradientRow.jpg');
    background-repeat : repeat-x;
    height            : 18px;
    border            : none;
    margin            : 20px 0 0 0;
}

hr.divider {
    border-bottom : 1px solid #ccc;
}

.row form .row.signupButtonContainer,
.row.signupButtonContainer {
    margin-top : 10px;
    padding    : 30px;
    margin     : 0 -55px;
}

.signupExtra label {
    text-align  : right;
    font-weight : bold;
}

.signupExtra.align-left {
    label {
        text-align:left;
    }
}

.signupContainer {
    .login-content {

        margin-top : 40px;
    }
}

.signupContainer .stdForm {
    background-color : white;
    margin-top       : 60px;
    box-shadow       : 0px 0px 22px #5A5858;
    border-radius    : 5px;
}

.signupContainer ul.loginButtons {
    margin-top : 40px;
}

.signupContainer .stdForm .darkBg label {
    color : #fff;
}

.signupContainer .stdForm .darkBg {
    background-color : #444;
    margin           : 0 -55px;
    padding          : 35px 40px 10px 40px;
}

.signupContainer .dropdown#available-school,
.signupContainer .dropdown#available-group
{
    width : 130%;
}

.signupContainer .stdForm .disclaimer p {
    font-size     : 90%;
    margin-bottom : 0px;
}

.signupContainer .stdForm input {
    background-color : #ECECEC;
}

.signupContainer .stdForm .accountSignup input {
    font-size  : 24px;
    padding    : 6px;
    box-shadow : none;
    border     : none;
    height     : 48px;
}

.columns.centered.signupFooter,
.signupFooter {
    font-size  : 85%;
    margin-top : 60px;
}

.logo {
    background-repeat   : no-repeat;
    background-position : center;
    background-size     : contain;
    height              : 180px;
    width               : 350px;
}

//body.ystreet .logo
//{
//    background-image : url('/images/ystreet/logo.jpg');
//}
//
//body.helxxBgDesign.ystreet {
//    background-image : url('/images/ystreet/bg.jpg');
//}
//
//body.freethenightok .logo {
//
//    background-image : url('/images/freethenightok/freethenight-logo.png');
//    width: 350px;
//    background-size: contain;
//    height: 300px;
//}
//
//body.breakdown .logo {
//    background-image : url('/images/breakdown/logo.jpg');
//    width: 350px;
//}

//body.evolvement .logo
//{
//    background-image : url('/images/evolvement/logo.jpg');
//}

body .front.button {
    text-transform : uppercase;
    color          : #000;
    font-weight    : bold;
    border         : none;
}

body.freethenightok,
body.breakdown,
body.evolvement {
    .front.button {
        @extend .brand-background;
    }
}

body.ystreet {
    .front.button {
        @extend .brand-background;
    }
}

.button-group .front.button,
.button-group.menu .front.button {
    font-weight : bold;
}

body .front.button.disable {
    cursor           : default;
    background-color : #DDD;
    color            : #888;
}

.columns.facebookLoginButton {
    width      : 280px;
    height     : 100px;
    margin-top : 20px
}

.columns.facebookLogoutButton {
    width      : 312px;
    height     : 40px;
    margin-top : 20px
}

#admin-sidebar {
    position   : fixed;
    height     : 700px;
    //background-image : url('/images/sidebarbg.jpeg');
    background : #111;
    //max-width: 250px;
    padding    : 0px;
    z-index    : 1001;
}

#main-content {
    margin-left : 16.67%;
    min-height  : 900px;
}

#main-content.no-sidebar {
    margin-left : 0;

}

.alert-count {
    padding     : 2px 4px 1px 4px;
    color       : $black;
    font-size   : 11px;
    font-family : sans-serif;
    float       : left;
    margin      : 30px 0 0 25px;
    line-height : 11px;
}

body.freethenightok .alert-count,
body.breakdown .alert-count,
body.evolvement .alert-count {
    @extend .brand-background;
}

body.ystreet .alert-count {
    @extend .brand-background;
}

#developerStatsIcon {
    display : none;
}

.ghost,
.reveal-modal.ghost {
    -webkit-box-shadow : none;
    -moz-box-shadow    : none;
    box-shadow         : none;
    background         : none;
    border             : none;
}

.reveal-modal h2 {
    margin-top : 0;

}

.reveal-modal p.large {
    font-size : 1.5em;
}

.reveal-modal.vimeo {
    background : black;
    width      : 560px;
}

input[type="text"]:-moz-placeholder {
    color : #999;

}

textarea.textInput {
    height : 30px;
}

.selected-items,
.available-items {
    margin-bottom  : 12px;
    padding-top    : 0;
    padding-bottom : 8px;
}

.selected-items .dropdown-item,
.available-items .dropdown-item {
    height      : 32px;
    line-height : 20px;
    margin      : 0px 0px 2px 0px;
}

.tabs.small {
    height : 25px;
}

tr.disabled td,
tr.disabled {
    color : #999;
}

a.sort.asc {
    background    : url('/images/sortUp.gif') no-repeat right center;
    padding-right : 20px;
}

a.sort.asc span.asc {
    font-family : entypo;
    display     : inline;
}

a.sort.desc {
    background    : url('/images/sortDown.gif') no-repeat right center;
    padding-right : 20px;
}

a.sort.desc span.desc {
    font-family : entypo;
    display     : inline;
}

.headerlogo {
    width      : 100%;
    text-align : center;
}

.loadingImage {
    width   : 60px;
    display : block;
    margin  : auto;
}

.loadingImage.large {
    width : auto;
}

.entypo.aligned {
    margin-left : 0em;
}

.entypo {
    margin-left  : -1em;
    margin-right : .2em;
    font-family  : entypo;
    font-size    : 2em;
    display      : inline-block;
    height       : .7em;
}

.entypo.std {
    margin-left  : 0;
    margin-right : 0;
    font-size    : 2em !important;
}

.icon {
    font-family : entypo;
    display     : inline-block;
    width       : 26px;
    height      : 24px;
    font-size   : 35px;
}

button.icon-post {
    font-family : entypo;
    font-size   : 1.2em;
    margin-top  : -3px;
    color       : #aaa;
    border      : none;
    background  : none;
}

li.active a span.icon {
    color : #1e1e1e;
}

.icon.arrow {
    font-size    : 10px;
    margin-top   : 1px;
    margin-right : 8px;
    float        : right;
    width        : 13px;
    height       : 11px;
    color        : #CBCBCB;

}

.icon.arrow:after {
    content : "\25B2";
}

li.active a span.icon.arrow:after {
    content : "\25BC";

}

.icon.bolt {
    margin-left  : 6px;
    margin-right : -6px;
}

/*
.icon{
    vertical-align: middle;
    margin-right: 6px;
    background-image: url('/images/spritesSmall.gif');
}

li.active a span.arrow{
    background-position: -124px 0px;
}

.bolt{
    background-position: -72px -24px;
}
li.active a .bolt{
    background-position: -72px -52px;
}

.system{
    background-position: -48px -24px;
}
li.active a .system{
    background-position: -48px -52px;
}

.user{
    background-position: -0px -24px;
}
li.active a .user{
    background-position: -0px -52px;
}

.tools{
    background-position: -24px -24px;
}
li.active a .tools{
    background-position: -24px -52px;
}*/

#admin-sidebar ul {
    margin : 0px;
}

.currentUser, .currentUserSmall {
    width            : 100%;
    background-color : $black;
    color            : $almostWhite;
}

.currentUserSmall .name,
.currentUserSmall .links {
    text-align  : center;
    float       : left;
    width       : 50%;
    line-height : 150%;
}

.currentUserSmall .links {
    font-size : 12px;
}

.currentUserSmall {
    padding : 8px;
    height  : 60px;
}

.currentUser {
    padding  : 12px;
    position : absolute;
    bottom   : 0px;
    height   : 90px;
}

.currentUser img {
    margin-right  : 12px;
    border-radius : 35px;
}

.current-user-info {
    color : #aaa;
}

.current-user-info .top,
.current-user-info .lower {
    height : 50px;
}

.current-user-info .lower {
    padding-left : 22px;
    padding-top  : 10px;
}

.current-user-info .name {
    margin      : 0;
    font-weight : bold;
    color       : $white;
}

.inline,
a.inline,
.inline.hide-for-small {
    display : inline;
}

//TODO: Fix this madness
label.inline {
    display : block;
}

label.inline-strict {
    display : inline;
}

label small {
    font-size : 80%;
}

.currentUser a,
.currentUserSmall a {
    color   : $almostWhite;
    display : inline;
}

.currentUser div {
    margin-top : 4px;
}

.currentUserSmall .role,
.currentUser .role {
    color     : #444;
    font-size : 12px;
}

.currentUser .links {
    font-size  : 11px;
    margin-top : 10px;
}

.currentUserSmall .name,
.currentUser .name {
    font-weight : bold;
}

.nav-bar {
    background : none;
}

.nav-bar.vertical > li {
    margin-top : 1px;
}

.nav-bar > li > a:first-child {
    font-size : 14px;
}

.nav-bar li ul li a,
.nav-bar li ul li.active a,
.nav-bar li ul li a:hover,
.nav-bar li ul li a:focus {
    font-size          : 13px;
    margin             : 0px 10px;
    -webkit-box-shadow : none;
    -moz-box-shadow    : none;
    box-shadow         : none;
    background         : #343434;
    color              : #CBCBCB;
    padding            : 6px 10px;
    border             : 0px none;
    border-bottom      : 1px solid #444;
    text-decoration    : none;
    font-weight        : normal;
}

.nav-bar li ul li a:hover {
    color : #FFF;
}

.nav-bar li ul li:first-child a,
.nav-bar li ul li.active:first-child a {
    margin-top : 10px;
}

.nav-bar li ul li:last-child a,
.nav-bar li ul li.active:last-child a {
    padding-bottom : 20px;
    border-bottom  : none;
}

#nav-bar li ul {
    display : none;
}

.nav-bar li.active ul {
    display : block;
}

.nav-bar li ul li,
.nav-bar li ul li.active {
    text-transform : capitalize;
    border-left    : solid 3px #343434;
}

.nav-bar.vertical > li.active {
    border-right : none;
}

.nav-bar > li,
.nav-bar > li:hover,
.nav-bar > li.active,
.nav-bar > li.active:hover {
    background : #343434;
    border     : none;
    @extend .brand-color;
}

.nav-bar > li.active > a,
.nav-bar > li.active > a:hover {
    font-weight : bold;

}

.nav-bar > li > a {
    cursor           : pointer;
    font-size        : 18px;
    background-color : #1e1e1e;
    color            : $white;
    font-weight      : normal;
}

.nav-bar > li.active > a {
    cursor           : pointer;
    background-color : $highlightColor;
    color            : #1e1e1e;
    cursor           : default;
}

.nav-bar span.icon {

}

body.ystreet .nav-bar > li.active > a {
    background-color : #00f0ff;
}

p.persistentMessage {
    text-align : center;
    font-size  : 1.6em;
}

div.custom.dropdown.open {
    z-index : 100;
}

div.custom.dropdown {
    padding : 0;
}

div.button.dropdown {
    top           : 0;
    border-radius : 0;
    padding-top   : 8px;
}

ul.filter-dropdown li {
    margin-right : 20px;
}

ul.filter-dropdown label {
    text-transform : capitalize;
}

.cropDiv {
    position         : absolute;
    width            : 200px;
    height           : 200px;
    top              : 2px;
    left             : 2px;
    border           : 2px solid black;
    opacity          : 0.4;
    background-color : white;
    cursor           : move;
}

.resizeDiv {
    cursor   : se-resize;
    width    : 30px;
    height   : 30px;
    position : absolute;
    bottom   : -10px;
    right    : -10px;
}

.upload-preview {
    min-height       : 200px;
    text-align       : center;
    background-color : #E4E7EE;
}

.upload-preview .loadingImage {
    margin-top : 50px;
}

.upload-preview div.drag-message {
    padding   : 60px 0;
    font-size : 60px;
    color     : #fff;
}

.upload-preview div.drag-message div {
    color     : #818B97;
    font-size : 30px;
    padding   : 20px 0 0 0;
}

#upload-form-box,
div.drag-message h2 {
    font-family : helvetica, sans-serif;
}

div.drag-message h2 {
    margin-top : 20px;
    color      : #777;
}

#upload-preview-box {
    position : relative;
}

#upload-form-box {
    margin-top: 20px;

    .image-preview img {
        max-width : 100%;
    }

    label {
        font-size     : 23px;
        margin-bottom : 25px;
    }

    .image-form {
        margin-top : 30px;
    }
}


.upload-preview.droppable {
    border-color : #c71585;
}

.upload-preview.droppable div.drag-message {
    color : #c71585;
}

div#fileButton {

    /* IMPORTANT STUFF */
    overflow      : hidden;
    position      : relative;
    cursor        : pointer;
    margin-bottom : 20px;

    /* SOME CUSTOM STYLING */
    text-align    : center;
    font-weight   : bold;
}

div#fileButton input {
    height         : 30px;
    cursor         : pointer;
    position       : absolute;
    top            : 0px;
    right          : 0px;
    font-size      : 100px;
    z-index        : 2;

    opacity        : 0.0; /* Standard: FF gt 1.5, Opera, Safari */
    filter         : alpha(opacity=0); /* IE lt 8 */
    -ms-filter     : "alpha(opacity=0)"; /* IE 8 */
    -khtml-opacity : 0.0; /* Safari 1.x */
    -moz-opacity   : 0.0; /* FF lt 1.5, Netscape */
}

.select-input .prefix,
.text-input .prefix,
.text-disabled .prefix {
    text-transform : capitalize;
}

div#manageGroups.button {
    margin : 0 0 12px 0;
    height : 32px;
}

.row.pad.large,
.row form .row.pad.large {
    padding : 60px 0;
}

.row.pad.small,
.row form .row.pad.small {
    padding : 10px 0;
}

tr.messageRow td {
    vertical-align : middle;
}

form.custom span.custom.checkbox:before {
    line-height : 1.0;
}

.facebookButton {
    border-radius : 5px;
    display       : block;
    background    : url(/images/fb-login.jpg) no-repeat;
    height        : 39px;
    width         : 280px;
}

.facebookLogoutButton {
    border-radius : 5px;
    display       : block;
    background    : url(/images/fb-logout.jpg) no-repeat;
    height        : 40px;
    width         : 312px;
    padding       : 0;
}

.button.twitter {
    background-image : url(/images/twitter-login.jpg);
    height           : 39px;
    width            : 280px;
}

/***************************************************************
 *    BEGIN Youth Header Style
 ***************************************************************/
//body.freethenightok
//{
//    background-image : url(/images/freethenightok/bg.jpg);
//}
//
//body.ystreet
//{
//    background-image : url(/images/ystreet/bg.jpg);
//}
//
//body.breakdown,
//body.evolvement
//{
//    background-image : url(/images/evolvement/bg.jpg);
//}

#mainFrontContainer {
    max-width        : 1200px;
    width            : 100%;
    box-shadow       : 0px 0px 20px #464646;
    background-color : white;
}

body.helxxBgDesign #mainFrontContainer {
    background-color : transparent;
}

#mainFrontContainer.sidebar .user-header-navigation {
}

#mainFrontContainer.sidebar {
    max-width : 2500px;
}

#user-header-alerts,
.user-header-navigation {
    height : 50px;
}

#user-header-alerts {
    background-color : #202020;
}

.header-icon-group {
}

.header-icon-group .projCount,
.header-icon-group .messageCount,
.header-icon-group .newsCount {

}

.user-header-navigation {
    background-color : #515151;
}

.front.rightHeader {
    float : right;
}

.front.rightHeader.logout {
    @extend .brand-color;
    font-weight : bold;
    margin      : 22px 16px 0 0;

}

body.freethenightok .front.rightHeader span.help-text,
body.breakdown .front.rightHeader span.help-text,
body.evolvement .front.rightHeader span.help-text {
    @extend .brand-color;
}

body.ystreet .front.rightHeader span.help-text {
    @extend .brand-color;
}

.front.rightHeader.header-links {
    margin : 16px 0 0 16px;

}

.header-links a {
    font-size       : 12px;
    color           : #ddd;
    text-decoration : none;
    font-weight     : bold;
    margin-right    : 5px;
}

.header-links a.help-message-box {
    margin-left : 5px;
}

.header-links a.profile-name {
    max-width  : 170px;
    overflow-x : hidden;
}

.header-links a.secondary {
    color     : #888;
    font-size : 11px;
    pad-top   : 1px;
}

.header-links ul li {
    margin-left : 2px;
}

.header-links ul li:first-child {
    margin-left : 0;
}

.header-links ul li.helper-links {
    margin-left  : 5px;
    margin-right : 5px;
}

.front.header-links {
    color : #fff;
}

.front.header-links ul {
    margin-top : 6px;
}

body.ystreet .front.rightHeader.logout {
    @extend .brand-color;
    font-weight : bold;
}

.sprite {
    background-image : url(/images/front-sprites.png);
}

.sprite.edit-icon {
    background-position : -41px -306px;
}

.button-group .button.entype-container {
    padding : 4px 6px !important;

}

.button-group .button .entypo {
    height    : 0px;
    font-size : 2.5em;
    margin    : -8px 0 0 0;

}

.front.LogoutIcon,
.front.lowerBarIcon,
.frontSprite,
.accordIcon,
.front.feedFilterIcon,
.front.headerIcon {
    display : block;
    @extend .sprite;
}

.front.headerIcon
{
    width  : 50px;
    height : 50px;
    float  : left;
    margin : 0px 1px;
}

.front.feedFilterIcon,
.front.lowerBarIcon {
    float      : left;
    display    : inline-block;
    height     : 25px;
    width      : 30px;
    margin-top : -3px;
}

.front.headerIcon.smallLogo
{
    margin-top          : 0px;
    margin-left         : 0px;
    height              : 50px;
    width               : 100px;
    background-position : -40px -120px;
}

body.freethenightok .front.headerIcon.smallLogo {
    background-position : -248px -267px;;
}

body.breakdown .front.headerIcon.smallLogo {
    background-position : -129px -267px;

}

body.evolvement .front.headerIcon.smallLogo {
    background-position : -40px -200px;
}

.front.header-links {
    color : #fff;
}

.front.header-links ul {
    margin-top    : 6px;
    margin-bottom : 0;
}

body.freethenightok .front.headerIcon:hover,
body.freethenightok .front.headerIcon.selected,
body.breakdown .front.headerIcon:hover,
body.breakdown .front.headerIcon.selected,
body.evolvement .front.headerIcon:hover,
body.evolvement .front.headerIcon.selected {
    @extend .brand-background;
}

body.freethenightok .front.headerIcon.smallLogo:hover,
body.freethenightok .front.headerIcon.smallLogo.selected {
    background-color : transparent;
}

body.ystreet .front.headerIcon:hover,
body.ystreet .front.headerIcon.selected {
    @extend .brand-background;
}

.front.lowerBarLeft {
    background-color : #4D4D4D;
    height           : 50px;
    //    min-width        : 240px;
}

.front.lowerBarLeft ul li {
    float       : left;
    margin-left : 0;
}

.lowerBarButton span.buttonText {
    font-size   : 11px;
    float       : left;
    padding-top : 4px;
}

a.lowerBarButton,
.lowerBarButton {
    margin-top  : 15px;
    float       : left;
    display     : block;
    color       : #fff;
    padding     : 0px 5px 1px 1px;
    margin-left : 5px;
}

.lowerBarRight a.lowerBarButton {
}

.lowerBarRight a.lowerBarButton.vr-left {
    padding-left : 20px;
}

.lowerBarRight ul.inline-list > li {
    margin-left : 5px;
}

.front.LogoutIcon {
    display             : inline-block;
    width               : 20px;
    height              : 20px;
    background-position : 0px -200px;
}

body.ystreet .front.LogoutIcon {
    background-position : 0px -120px;
}

.rightHeader.logout {
    //width : 125px;
}

.rightHeader.logout .front.LogoutIcon,
.rightHeader.logout .logoutText {
    float : right;
}

.rightHeader.logout .logoutText {
    margin-top : 0;
}

.lowerBarButton.group {
    float : right;
}

.front.lowerBarIcon.groups {
    background-position : -201px -6px;
}

.front.lowerBarIcon.campaigns {
    background-position : -352px -8px;
}

.front.lowerBarIcon.members {
    background-position : -236px -6px;
}

.front.lowerBarIcon.profile {
    background-position : -236px -8px;
}

.front.lowerBarIcon.follow {
    background-position : -160px -196px;
}

body.ystreet .front.lowerBarIcon.follow {
    background-position : -160px -116px;
}

.front.lowerBarIcon.message {
    background-position : -200px -193px;
}

.front.lowerBarIcon.post {
    background-position : -77px -301px;
}

.front.lowerBarIcon.status {
    background-position : -40px -301px;
}

body.ystreet .front.lowerBarIcon.message {
    background-position : -200px -116px;

}

.front.headerIcon.store {
    background-image : none;
}

.front.headerIcon.store .icon {
    color       : #DDD;
    margin-top  : -5px;
    margin-left : 11px;
    font-size   : 48px;
    width       : 37px;
}

.front.headerIcon.store:hover .icon {
    color : #000;
}

.front.headerIcon.home {
    background-position : -108px 11px;
}

.front.headerIcon.home:hover {
    background-position : -108px -29px;
}

.front.headerIcon.projects {
    background-position : -67px 11px;
}

.front.headerIcon.projects:hover,
.front.headerIcon.projects.selected {
    background-position : -67px -29px;
}

.front.headerIcon.mail {
    background-position : 12px 11px;
}

.front.headerIcon.mail:hover,
.front.headerIcon.mail.selected {
    background-position : 12px -29px;
}

.front.headerIcon.news {
    background-position : -146px 13px;
}

.front.headerIcon.news:hover,
.front.headerIcon.news.selected {
    background-position : -146px -27px;
}

.front.headerIcon.settings {
    background-position : -29px 13px;
}

.front.headerIcon.settings:hover {
    background-position : -29px -27px;
}

/**************************************************************
 * START Youth Sidebar start
 **************************************************************/
.frontSprite {
    display : inline-block;
    width   : 16px;
    height  : 18px;
}

.frontSprite.circle {
    background-position : -321px -16px;
}

.breakdown .frontSprite.coloredCircle,
.evolvement .frontSprite.coloredCircle {
    background-position : -198px -238px;
}

.ystreet .frontSprite.coloredCircle {
    background-position : -198px -158px;
}

.frontSprite.road {
    background-position : -284px -16px;
}

#sidebar {
    background-image    : url(/images/sidebar-gradient.jpg);
    background-color    : white;
    background-position : right;
    background-repeat   : repeat-y;
    padding-top         : 13px;
    padding-bottom      : 50px;
}

#sidebar.no-header {
}

#sidebar-top-section {
    padding-bottom : 10px;
}

.youth .avatarContainer {
    text-align : right;
}

.youth .avatarContainer.centered {
    text-align : center;
}

ul.userInfoList {
    padding-right : 10px;
}

ul.userInfoList li h5 {
    font-size : 15px;
    color     : #040404;
}

ul.userInfoList li {
    line-height : 15px;
    font-size   : 11px;
}

.sectionRow-head .columns {
    background-color : #EBEBEB;
    height           : 35px;
    line-height      : 35px;
    font-size        : 15px;
    font-weight      : bold;
    margin-bottom    : 10px;
}

.sectionRow-head .button.tiny {
    padding-top    : 4px;
    padding-bottom : 3px;
}

.sectionRow-head .help {
    font-size    : 11px;
    line-height  : 1.0em;
    font-weight  : normal;
    padding      : 5px 10px;
    margin-right : -15px;
}

.sectionRow-body {
    padding-bottom : 0px;
}

.accordion.open {
    background-repeat   : no-repeat;
    padding-bottom      : 9px;
    background-image    : url(/images/front-sprites.png);
    background-position : -215px -14px;
}

.accordion .accordionRow {
    margin-bottom    : 3px;
    font-weight      : bold;
    font-size        : 12px;
    line-height      : 32px;
    height           : 35px;
    background-color : #EBEBEB;
}

.accordion .accordionRow .name {
    font-weight : bold;
    font-size   : 12px;
    margin-top  : 3px;
    float       : left;
}

.accordion.no-image {
    margin-top : 110px;
}

.no-image-spacer {
    height : 110px;
}

.sidebar-top-spacer {
    height : 25px;
}

.accordion.secondary .accordionRow {
    background-color : #525252;
    color            : $white;
}

.accordionRow .accordText {
    float      : left;
    margin-top : 4px;
    font-size  : 12px;
}

.accordion .accordionRow a {
}

.accordion .accordionRow a.projects,
.accordion .accordionRow a.store {
}

.accordion .accordionRow a {

}

.accordion .accordionRow a.browse {
    color      : #555;
    font-size  : 80%;
    margin-top : 2px;
    float      : right;

}

.accordion-children {
    padding-bottom : 10px;
    padding-top    : 5px;
}

.accordion-children .accordion-child {
    border-bottom : 1px solid #eee;
}

.accordion-children .accordion-child:last-child {
    border-bottom : none;
}

.accordion-child.secondary {
    background-color : #525252;
}

.accordion-child .oppRow {
    height      : 50px;
    padding-top : 4px;
}

.accordion-child .oppRow:last-child {
}

.accordion-child .oppRow img {
    float  : left;
    height : 40px;
}

.accordion-child .oppRow div.title {
    //    float        : left;

    padding-left : 60px;
    font-weight  : bold;
    font-size    : 12px;
    height       : 25px;
    margin-top   : 5px;
}

.accordion-child .oppRow span.sub-title {
    margin : 0;
}

.accordion-child .oppRow div.sub-title {
    //    float        : left;

    padding-top  : 0px;
    padding-left : 60px;
    font-weight  : bold;
    font-size    : 10px;
    color        : #999;
}

.accordion-no-image {
    .accordion-child .oppRow div.title {
        padding-top : 0;
    }
}

.accordion-child .oppRow.full {
    padding-top   : 0;
    height        : 40px;
    border-bottom : 2px solid #eee;
}

.accordion-child .oppRow.full div.title {
    text-align   : left;
    padding-left : 10px;
}

.accordion-child .oppRow div.title a {
    font-size      : 11px;
    color          : $black;
    line-height    : 12px;
    display        : inline-block;
    vertical-align : middle;
}

.accordion-child .oppRow div.title a span.sub-title {
    font-size : 11px;
}

.accordion-child ul.pricing-table {
    padding : 0 10px;
    border  : none;
}

.accordion-child ul.pricing-table li.price {
    background-color : transparent;
    padding          : 12px 0 3px 0;
    text-align       : left;
    border           : none;
}

.accordion-child ul.pricing-table li.price,
.accordion-child ul.pricing-table li.price div {
    font-size : 12px;
    color     : #555;
}

.accordion-child ul.pricing-table li.description,
.accordion-child ul.pricing-table li.description div {
    font-size : 11px;
    color     : #222;
}

.accordion-child ul.pricing-table li.description.container {
    border-top : 1px solid #eee;
    padding    : 6px 0px 6px 5px;

}

.accordion-child ul.pricing-table li.description.container:last-child {
    border-bottom : 1px solid #eee;
}

.accordion-child ul.pricing-table li.description a,
.accordion-child ul.pricing-table li.price a {
    color : #222;
}

#milestone-panel {
    margin-bottom : 20px;
}

#milestone-panel {
    div.accordion {
        margin-bottom : 10px;
    }
    div.alert-title {
        font-weight : bold;
        color       : #555;
        font-size   : 11px;
        padding     : 0 0 5px 0;
        text-align  : left;
    }

    .pricing-table {
        margin-bottom : 0px;
    }

    ul.pricing-table li.price {
        padding-top : 5px;
    }

    ul.pricing-table li.price div {
        font-size   : 11px;
        color       : #333;
        font-weight : bold;
    }

    .accordion-child ul.pricing-table {
        padding-left : 0;
        border       : none;
    }

    .pricing-table li.description {
        background-color : transparent;
    }

    .panel {
        padding-left  : 10px;
        padding-right : 10px;
    }

    .accordion-child .icon {
        width      : 26px;
        height     : 18px;
        font-size  : 25px;
        margin-top : -8px;
    }

    .icon.approved {
        color : $success-color;
    }

    .icon.remaining {
        color : $secondaryColorSelected;
    }

    .icon.pending {
        color : orange;
    }

    .status-flag {
        margin-right : 5px;
    }

    .status-flag .icon {
        font-size  : 22px;
        margin-top : -10px;
        width      : 13px;
    }

    .status-flag div {
        font-size : 9px;
        color     : #777;
    }

    .accordion-child ul.pricing-table li.description div.value {
        font-size      : 10px;
        color          : #222;
        word-wrap      : break-word;
        line-height    : 1.5;
        letter-spacing : -.5px;
    }

    .title {
        font-weight    : bold;
        font-size      : 11px;
        padding-bottom : 10px;
        display        : block;
    }
}

.accordion-child ul.pricing-table li.description {
    padding    : 0;
    text-align : left;
    border     : none;
}

.accord-count {
    font-size      : 10px;
    padding-top    : 10px;
    padding-bottom : 10px;
}

.accordIcon {
    float  : left;
    width  : 40px;
    height : 40px;
}

.accordIcon.icon {

    margin : 4px 0 0 10px;
    width  : 27px;
}

.accordIcon.projects {
    background-position : -75px -75px;
}

.accordIcon.events {
    background-position : -115px -75px;
}

.accordIcon.media {
    background-position : -155px -75px;
}

.accordIcon.calendar {
    background-position : -195px -75px;
}

.accordIcon.config {
    background-position : 0 -302px;
}

.accordIcon.teams {
    background-position : -242px -196px;
}

.accordIcon.org {
    background-position : -243px -194px;
}

.accordIcon.groups {
    background-position : -196px -3px;
}

.accordion .icon.store {
    color       : #222;
    font-size   : 48px;
    margin-top  : 2px;
    margin-left : 4px;
    width       : 37px;
}

.accordion .accordionRow .name {
    margin-left : 5px;
    margin-top  : 3px;
    font-size   : 12px;
    float       : left;
}

/**************************************************************
 * END Youth Sidebar
 **************************************************************/

/***************************************************************
 *    END Youth Header Style
 ***************************************************************/

/***************************************************************
 *    START Home Style
 ***************************************************************/

.front.feedFilterIcon.news {
    background-position : -158px -45px;
}

/***************************************************************
 *    START Profile Style
 ***************************************************************/

#sidebar-top-section.profile {
    //    height : 215px;
}

.avatar.thick {
    border : 4px solid black;
}

.profile-header {
    font-family : Verdana, Arial;
}

.profile-header h2 {
    font-size   : 26px;
    font-weight : normal;
    font-family : Verdana, Arial;
    color       : $black;
}

.profile-header.no-image {
    height : 140px;
}

.profile-header.no-image h2 {
    font-size : 23px;
}

.profile-header ul.detail-list {
    padding : 0;
    float   : left;
}

.profile-header ul.detail-list li {
    font-size : 11px;
}

.profile-header div.sub-title {
    font-size    : 12px;
    padding-left : 3px;
}

.profile-header div.sub-title.large {
    font-size    : 16px;
    padding-left : 0;
}

ul.stat-list {
    @extend .inline-list !optional;
    float       : left;
    clear       : left;
    margin-left : 0;
    padding     : 0;
}

ul.stat-list strong {
    display : inline;
}

ul.stat-list li {
    margin-left: 0;
    margin-right : 8px;
    font-size   : 12px;
}


div#notifyPreview {
    left               : 100px;
    top                : 50px;
    position           : absolute;
    z-index            : 1;
    width              : 430px;
    padding            : 0;
    height             : 350px;
    background         : $white;
    box-shadow         : 0 1px 12px #555;
    -moz-box-shadow    : 0 1px 12px #555;
    -webkit-box-shadow : 0 1px 12px #555;
    border             : 1px solid #888;
}

body.evolvement div#notifyPreview {
    //border : 6px solid $evolvementColor;
    //    box-shadow : 2px 3px 16px #555;
    border : 1px solid #888;
}

body.ystreet div#notifyPreview {
    //    border : 1px solid $ystreetColor;
}

#snoop,
#unsnoop {
    z-index       : 1002;
    border-radius : 5px;
    margin        : 2px;
    padding       : 9px;
    position      : fixed;
    bottom        : 2px;
    left          : 2px;
}

#unsnoop a {

}

div.notify-scroll {
    overflow-y : scroll;

    overflow-x : hidden;
    height     : 300px;

}

div#notifyPreview div.notification-one {
    font-size     : .9em;
    line-height   : 1.3em;
    border-bottom : 1px solid #eee;
}

div#notifyPreview div.notification-one img {
    padding : 0 5px;
    width   : 45px;
    border  : none;
}

div.my-notifications div.notification-one img {
    width  : 45px !important;
    border : none;
}

div#notifyPreview div.notification-one div.content {
    width : 350px;
}

div#notifyPreview .message-container,
div#notifyPreview a.message-container {
    padding         : 5px 0;
    text-decoration : none;
    font-weight     : normal;
    float           : left;
    font-size       : 11px;

}

div#notifyPreview div.notify-scroll.default div.notification-one {
    padding : 5px 0;
}

div#notifyPreview a.message-container:hover {
    background-color : #f2f2f2;
}

div#notifyPreview div.notification-one a.message-container div.content {
    padding : 0 4px;
}

div#notifyPreview div.message {
    width : 330px;
}

div#notifyPreview div.weak {
    font-size : 90%;
}

div#notifyPreview div.no-alert,
div#notifyPreview div.loading-alert {
    padding     : 10px 0;
    text-align  : center;
    font-weight : bold;
    color       : #777;
}

div#notifyPreview div.no-alert {
    margin-top : 20px;
}

div#notifyPreview div.notify-view-all {
    padding    : 7px 0;
    text-align : center;
    border-top : 1px solid #ccc;
    font-size  : .82em;
}

div#notifyPreview div.notify-view-all a {
    color           : #777;
    font-size       : .82em;
    font-weight     : bold;
    text-decoration : none;
}

/***************************************************************
 *    END Profile Style
 ***************************************************************/

#simpleLoadingDialog {
    position         : absolute;
    width            : 200px;
    height           : 40px;
    border           : 3px solid #666;
    background-color : white;
    text-align       : center;
    vertical-align   : middle;
    padding-top      : 11px;
    color            : #666;
    font-weight      : bold;
}

#menuBarWrap {
    position   : absolute;
    top        : 49px;
    z-index    : 1001;
    left       : 0;
    background : black;
    width      : 25%;
}

#admin-sidebar #searchEverything {
    display : inline-block;
}

@media only screen and (max-width : 1441px) {
    #main-content {
        //width: 77.77778%;
        //width : 80%;
    }
}

@media only screen and (max-width : 1279px) and (min-width : 768px) {
    #admin-sidebar span.icon {
        display : none;
    }

}

@media only screen and (max-width : 767px) {

    #main-content {
        margin-left : 0px;
    }

    #main {
        padding-left  : 15px;
        padding-right : 15px;
    }

    #admin-sidebar {
        position  : static;
        height    : auto;
        max-width : none;

    }

    .stat-list li {
        font-size : 11px;
    }

    .profile-header h2 {
        font-size     : 21px;
        margin-top    : 9px;
        margin-bottom : 0;
    }

    .profile-header h2 .sub-title {
        padding-left : 0;
    }

    .profile-header ul.detail-list {
        clear   : both;
        padding : 0;
    }

    .signupContainer {
        min-width : 0;
    }

    #menuBarWrap {
        width : 100%;
    }

    .front.lowerBarLeft ul li {
        float : left;
    }

    .front.lowerBarIcon.message {
        display : inline-block;
    }

    #sidebar, #main {
        height : auto !important;
    }

    ul.userInfoList li h5 {
        font-size : 20px;
        color     : #040404;
    }

    .signupExtra label {
        text-align : left;
    }

    .grid_50.mobile-one.logoutContainer .hideSmall {
        display : none !important;
    }

    .header-icon-group {
        min-width : 0;
    }

    .front.rightHeader.logout {
        margin       : 19px 0 0 0;
        padding-left : 20px;
    }

    .lowerBarButton span.buttonText {
        margin-left : 0;
        font-size   : 10px;
    }

    div#notifyPreview div.notification-one div.content {
        width : 270px;
    }

    div#notifyPreview {
        left       : 10px;
        top        : 50px;
        position   : absolute;
        z-index    : 1;
        width      : 330px;
        padding    : 0;
        height     : 350px;
        background : white;
    }

    div#notifyPreview div.message {
        width : 100%;
    }

}

.minigrant-application {
    .progress-label {
        text-align : left

    }
}

#advisor-sidebar {
    .pricing-table {
        padding : 0;
    }

    .group-module .pricing-table {
        padding : 0 15px 0 5px;
    }

    .price {
        font-size     : 11px;
        border-bottom : 1px solid #ddd;
    }

    .description {
        padding : 2px 0;
    }

}

div.admin-lead,
div.admin-lead a,
div.admin-lead button {
    font-size : 12px;
}

#alertContent {
    .alert-item {
        border         : 2px dotted #ccc;
        font-size      : 12px;
        padding-top    : 5px;
        padding-bottom : 5px;
        margin-top     : 10px;
    }

    .alert-item div {
        font-size : 12px;
    }

    .alert-item .text {
        padding : 4px 0;
    }

    .alert-item div.stats {
        font-size : 10px;
        color     : $weakColor;
    }
}

div.user-box ul li {
    margin-bottom : 10px;

}

div.progress {
    background-color : transparent;
    padding          : 0;

    .meter {
        &.front {
            color : $secondary-color;
        }
    }
}

.secondaryColor {
    &:link,
    &:visited {
        background-color : $secondaryColor;
        color            : $black;

    }
    &.hover,
    &.active {
        background-color : darken($secondaryColor, 10%);
        color            : $black;
    }
}

.sub-nav dd.active a, .sub-nav dt.active a, .sub-nav li.active a {
    @extend .secondaryColor;
    span {
        color : $black;
    }
}

.requirement-check {
    i.done,
    i.not-done {
        display:none;
        margin-left: 5px;
    }

    &.not-done {
        i.not-done {
            display : inline;
        }
        color: red;
    }

    &.done {
        i.done {
            display : inline;
        }
    }
}

