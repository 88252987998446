
.header-inner {
    list-style : none;
    padding    : 0;
    @extend .brand-color, .accent-2;

    > li {
        display        : inline-block;
        vertical-align : top;
    }

    > li.menu-icon {
        /*margin-right: 20px;*/
    }

    .top-menu a,
    .menu-icon a {
        color: $white;
    }

    .menu-icon a {
        font-size       : 12px;
        padding         : 0 10px;
        text-transform  : capitalize;
        height          : 55px;
        display         : block;
        font-weight     : 300;
        text-decoration : none;
    }

    .menu-icon span:hover {
    }


    .menu-icon a.active,
    .menu-icon a:hover {
        border-bottom    : 3px solid $primary-color;
        transition       : all 200ms ease-in-out;
        background-color : rgba(255, 255, 255, 0.1)
    }

    span.menu-icon,
    span.menu-name {
        line-height : 55px;
    }

    a.icon-wrap {
        height  : 55px;
        padding : 0 15px;

        &:hover {
            background-color : rgba(255, 255, 255, 0.1);
            border-radius    : 2px;
            transition       : background-color .5s ease;
        }
    }

    i {
        margin : 0;
    }

    .nav-icon {
        line-height : 55px;
    }

    .menu-icon .menu-icon {
        font-size : 16px;
        float     : left;
    }

    .menu-name {
        margin-left : 5px;
        float       : left;
        font-family : verdana, arial, sans-serif;
    }

    .menu-icon .md {
        margin-right : 2px;
    }

    .dropdown-menu a {
        color : #000;
    }
}

.menu-trigger .line-wrap .line {
    background-color : #FFF;
}

.top-menu {

    > .dropdown {
        position     : relative;
        margin-right : 0;

        .arrow-up {
            position : relative;
            top      : 56px;
            left     : 13px;
            z-index  : 10;
            opacity  : 0;
        }
    }
}

header {
    .icon-bar .item {
        padding     : 0;
        line-height : 55px;

        a {
            float : left;
            width : 100%;

            &:hover {
                background-color : rgba(255, 255, 255, .1);
            }
        }

    }

}

.top-menu {
    list-style : none;
    padding    : 0;

    > .dropdown.open {
        .arrow-up {
            opacity : 1;
            @include transition(all 250ms linear);
        }
    }

    > li > a > i {
        font-size : 19px;
    }

    > li {
        display : inline-block;
    }

    > li > a {
        height              : 35px;
        min-width           : 50px;
        display             : inline-block;
        background-position : center;
        background-repeat   : no-repeat;
        position            : relative;
        z-index             : 1;

        &:hover {
        }
    }
}

.menu-trigger {

    height : 40px;
    cursor : pointer;
    margin : 0;
    width  : 70px;

    .line-wrap {
        @include transition(all 300ms linear);
        width    : 20px;
        position : relative;
        top      : 27px;
        margin   : auto;

        .line {
            width  : 100%;
            height : 2px;
            @include transition(all .1s linear);
        }

        .line:before {
            top        : -5px;
            content    : '';
            display    : block;
            width      : 20px;
            height     : 2px;
            background : #fff;
            position   : absolute;
            z-index    : 1;
            @include transition(all .3s linear);
        }

        .line:after {
            top        : 5px;
            content    : '';
            display    : block;
            width      : 20px;
            height     : 2px;
            background : #fff;
            position   : absolute;
            z-index    : 1;
            @include transition(all .3s linear);
        }
    }

    &.toggled {
        .line-wrap {
            .line {
                background-color : transparent !important;
            }

            .line:before {
                @include transform(rotate(45deg));
                @include transition(all .2s linear);
                top : 0;
            }

            .line:after {
                @include transform(rotate(-45deg));
                @include transition(all .2s linear);
                top : 0;
            }
        }
    }
}

.progress-goal-img i {
    font-size  : 60px;
    margin-top : -16px;
    color      : #ddd;
}

.top-menu {

    > li > a {
        height          : 40px;
        background-size : 62%;
        min-width       : 40px;

        &.tm-task {
            background-size : 75%;
        }
    }

    > li.dropdown-text {
        top : -10px;
    }

    > li > a.tm-text {
        color       : white;
        font-size   : 20px;
        font-weight : 700;
        text-align  : center;
    }

    > li > a {
        float      : left;
        text-align : center;
        @include transition(all 250ms ease);
    }

    > li > a .md {
        font-size  : 18px;
        margin-top : 7px;
        color      : white;
    }

    > li.open > a {
        background-color : rgba(255, 255, 255, 0.1);
        @include transition(all 450ms ease);
    }

    a.tm-profile {
        text-align: center;
        padding: 0 5px;
        img {
            height        : 25px;
            border-radius : 50%;
        }
    }
}

#nav-sidebar {
    top                 : 55px;
    position            : fixed;
    box-shadow          : 0 0 10px rgba(51, 51, 51, 0.38);
    z-index             : 5;
    width               : 250px;
    overflow            : hidden;
    background-image    : none;
    bottom              : 0;
    background-color    : white;
    -webkit-transform   : translate3d(-250px, 0, 0);
    transform           : translate3d(-250px, 0, 0);
    transition-duration : 300ms;

    &.toggled {
        -webkit-transform : translate3d(0, 0, 0);
        transform         : translate3d(0, 0, 0);
    }

    &.admin-sidebar {
        padding-top : 0;
    }

    .menu-icon {
        font-size : 15px;
        display   : block;
        padding   : 0;
        position  : relative;

        h3 {
            padding       : 10px 20px 10px 20px;
            border-bottom : 1px solid #eee;
            font-size: rem-calc(16);
        }

        .main-menu > li > a {
            padding     : 5px 30px 5px 30px;
            display     : block;
            color       : #4C4C4C;
            font-weight : 500;
            position    : relative;
            font-size   : rem-calc(13);
        }
    }

    .collection {
        border : none;
    }

    .collection .collection-item {
        border-bottom   : none;
        padding         : 10px 20px;
        color           : #555;
        text-decoration : none;
    }

    a {
        color: #555;
    }
    a:hover {
        text-decoration: none;
    }


    .admin-menu {
        .collection {
            margin: 0;
        }
        .collection .collection-item {
            padding         : 5px 20px;
            font-size: 13px;
        }
    }

    i {
        float        : left;
        margin-top   : 1px;
        margin-right : 10px;
    }

    .logo-small {
        border-top    : 1px solid #e0e0e0;
        border-bottom : none;

        .main-menu > li > a {
            padding : 10px 20px 10px 20px;

        }
    }

    .extra {
        padding : 12px 0;
    }

    .sidebar-inner {
        height     : 100%;
        overflow-y : auto;
        width      : 100%;
    }

    .main-menu {
        margin-bottom : 10px;
    }

    .main-menu > li.active > a {
        color            : #262626;
        background-color : #F4F4F4;
    }

    .main-menu > li > a > i {
        font-size : 15px;
        padding   : 0 5px;
    }

    .main-menu > li > a {
        padding     : 10px 20px 10px 20px;
        display     : block;
        color       : #4C4C4C;
        font-weight : 500;
        position    : relative;
        font-size   : 13px;
    }

    .main-menu > li > a:hover {
        background-color : rgba(255, 255, 255, 0.1);
    }

    .main-menu > li.sub-menu > ul {
        list-style  : none;
        background  : #f7f7f7;
        display     : none;
        padding     : 0;
        margin-left : 0;
    }

    .main-menu > li.sub-menu.toggled > ul {
        display : block;
    }

    .main-menu > li.sub-menu .toggle-icon {
        float       : right;
        padding-top : 2px;
    }
    .main-menu > li.sub-menu .toggle-icon:after {
        content : '\002B';
    }

    .main-menu > li.sub-menu.toggled .toggle-icon:after {
        content : '\2212';
    }

    .main-menu > li.sub-menu > ul > li:first-child > a {
        padding-top : 14px;
    }

    .main-menu > li.sub-menu > ul > li > a {
        color       : #7f7f7f;
        padding     : 8px 20px 8px 50px;
        font-weight : 500;
        display     : block;
        font-size   : 13px;
    }

    .main-menu > li.sub-menu > ul > li > a:hover {

        color : #000;
    }

    .collapsible {
        box-shadow : none;
        border-top : none;


        .collapsible-header {
            height      : rem-calc(32px);
            line-height : rem-calc(32px);
            font-size: rem-calc(14);

            .icon {
                &.system {
                    font-size: 30px;
                }
                &.arrow {
                    font-size: 14px;
                }
            }
        }

        .collapsible-body {
            padding-top    : 5px;
            padding-bottom : 10px;
            a {
                text-transform     : capitalize;
                font-size          : 13px;
                margin             : 0px 10px;
                -webkit-box-shadow : none;
                -moz-box-shadow    : none;
                box-shadow         : none;
                padding            : 4px 10px;
                text-decoration    : none;
                font-weight        : normal;
                display            : block;
            }

            > ul > li:hover {
                background-color : #eee;
            }
        }
    }
}

.top-menu > .dropdown > a > .tmn-counts {
    position                    : absolute;
    color                       : #FFF;
    font-style                  : normal;
    background                  : rgba(244, 67, 54, .8);
    font-weight                 : 700;
    width                       : 100%;
    line-height                 : 1.2;
    padding                     : 0px 3px;
    //border-radius               : 2px;
    right                       : 0px;
    bottom                      : 0;
    font-size                   : 10px;
    -webkit-animation-name      : wobble;
    animation-name              : wobble;
    -webkit-animation-duration  : 500ms;
    animation-duration          : 500ms;
    -webkit-animation-fill-mode : both;
    animation-fill-mode         : both;
}

@media (max-width : 768px) {

    .menu-trigger {
        display : block;
        margin  : 0;
    }

    .menu-trigger .line-wrap {
        top    : 10px;
        margin : 18px auto 0 auto;
    }

    .header-inner li > a, .top-menu > li > a {
        width           : 30px;
        max-width       : 30px;
        background-size : 50%;
        min-width       : 0;
    }

    .header-inner.nav-list {
        //display : none;
    }

    .header-inner .menu-icon {
        display : none;
    }

    .header-inner:nth-child(2) {
        margin-right : -5px;
    }

    .top-menu {
        .item {
            border-left : 1px solid rgba(255, 255, 255, .1);
            &:first-child {
                border : none;
                height : 55px;
            }
            i {
                font-size : rem-calc(22);
            }

            a.menu-trigger {
                height : 55px;
            }
        }

        > li > a .md {
            font-size : 15px;
        }

        > .dropdown {
            margin-right : 0;
        }

        > .dropdown > a > .tmn-counts {
            height      : auto;
            padding     : 2px 4px;
            line-height : 1;
            z-index     : 5;

        }

        > li > a > .tmn-counts {
            padding : 1px 3px;
            right   : 0;
        }

        .dropdown {
            position : initial;
            .dropdown-menu {
                top        : 61px;
                left       : 10%;
                width      : 80%;
                text-align : left;

            }
            .arrow-up {
                top  : 55px;
                left : 35%;
            }

            img {
                height : auto;
            }
        }
    }

    .padding-xs {
        padding : 0 2px !important;
    }
}

