#saveStatus {
    font-size  : 12px;
    margin-top : 24px;
}

#filterProjects {
    margin-top : 20px;
}

#projectFilter .grid_33 select {
    width   : 100%;
    padding : 4px;
}

.project-description a.attachment {
    color : blue;
}

.mopReportedInput,
.mopGoalInput,
.tinyInput,
input[type="text"].tinyInput,
input[type="text"].mopReportedInput,
input[type="text"].mopGoalInput {
    width   : 60px;
    padding : 2px 6px;
    height  : 28px;
}

.clearing-container a:link,
.clearing-container a:visited {
    color : #ccc;
}

#createProjectForm .button,
#createProjectForm a.button {
    border : 1px solid #999;
}

div.projectPagingContainer {
    height : 26px;
}

div.project-signup table thead th {
    background : #f9f9f9;
    color      : black;
}

div.project-signup table td {
    color          : black;
    background     : none;
    vertical-align : middle;
}

div.project-signup table td:last-child {
    font-weight : bold;
}

div.project-signup table input {
    margin : 0;
    width  : 55px;
}

div.mopElement h5 {
    font-size : 80%;
    color     : #CCC;
    margin    : 4px;
}

div.mopElement span {
    font-size : 65%;
    margin    : 4px;
}

div.mopElement.selected h5 {

    color : #444;
}

div.border div.row.alternating {
    margin         : 1px 1px;
    padding-bottom : 6px;
}

div.border.projectEfforts {
    background-color : #fff;
}

div.effortDetailsColumn {
    padding-top : 8px;
    line-height : 130%;

    img {
        max-width : 50px;
    }
}

div.mopElement.selected {
    background-color : #fff;
    border-color     : #444;
    color            : #444;
}

div.mopElement {
    cursor           : pointer;
    color            : #CCC;
    padding          : 6px;
    margin           : 1%;
    margin-top       : 0px;
    margin-bottom    : 4px;
    background-color : #F8F8F8;
    float            : left;
    width            : 30%;
    text-align       : center;
    border-radius    : 2px;
    border           : 1px solid #C9C9C9;
}

div.project-signup table tr.total td {
    padding-top    : 15px;
    padding-bottom : 15px;
    font-weight    : bold;
    text-align     : left;
    color          : green;
    font-size      : 16px;
}

div.project-signup table tr.total td.name {
    text-align : right;
    color      : black;
}

div.project-signup div.total {
    margin-right : 25px;
    font-size    : 19px;
    font-weight  : bold;
}

div.project-signup div.total .points {
    color : green;
}

div.project-signup div.total .points.invalid {
    color : red;
}

div.row.browseProjectRow,
div.browseProjectRow {
    border-radius : 3px;
    padding       : 10px 0px;
    border        : 1px solid #DFDFDF;
    margin        : 5px;
}

div.browseProjectRow a {
    color : inherit;

}

// Quiz Css

.questionContainer {
    padding       : 10px 0px;
    border-bottom : 1px solid #DDD;
}

#videoPreview {
    border        : 1px solid #CCC;
    padding       : 7px;
    border-radius : 5px;
    width         : 517px;
}

.newAnswer {
    margin-left : 210px
}

.deleteQuestion.entypo {
    height      : 15px;
    margin      : 0px;
    line-height : 18px;
    font-size   : 40px;
}

.answerContainer {
    clear   : both;
    padding : 8px;
}

#ui-datepicker-div,
#ui-datepicker-div .ui-datepicker-header {
    z-index : 2001 !important;
}

.row form .row.closeEventRow,
.row.closeEventRow,
.closeEventRow {
    margin      : 0px -18px;
    padding-top : 12px;
}

.project-create-proposal {

    .multiselect-attendance {
        position:relative;

    }

    #available-youthSelect {
        top: 65px;

    }

    .timepicker {
        width: 110px;
    }
}


