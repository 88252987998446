@import "settings";

#user-application-tbl table th {
    text-transform : capitalize;
}

#user-application-tbl div.label {
    display    : block;
    margin-top : 2px;
}

ul.block-grid input {
    width : 100%;
}

ul.block-grid a.button.expand {
    font-weight : normal;
}

ul.inline-list.collapse {
    margin : 0;
}

div#filter-options div.searchFilter {
}

input.collapse {

    margin : 0;

}

form.collapse {

    margin : 0;
}

#batch-actions.panel,
.filter-panel {
    @extend .panel;
    margin-bottom : 15px;
    padding       : 20px;
}

#batch-actions ul.inline-list {
    margin : 0;
}

#batch-actions ul.inline-list li {
}

.row.pad,
.row form .row.pad {
    padding : 30px 0;
}

div#consentFormContainer {
    width         : 1000px;
    height        : 1295px;
    margin        : 0 auto;
    margin-bottom : 100px;
}

div#consentFormContainer.ystreet div.form-container {

    padding-top  : 143px;
    padding-left : 128px;
    width        : 900px;
}

div#consentFormContainer button {
    clear       : both;
    float       : left;
    margin-top  : 132px;
    margin-left : 250px;
    background  : black;
}

div#consentFormContainer.ystreet button {
    margin-top : 130px;
}

div#consentFormContainer.nevada,
div#consentFormContainer.breakdown {

    div.form-container {
        padding-top : 147px;
    }

    button {
        margin-left : 425px;
        margin-top  : 150px;
    }

    ul.second-section {
        top  : 679px;
        left : -166px;
    }

    ul.second-section div.field.item-ten,
    ul.second-section div.field.item-twelve {
        margin-left : 60px;
    }
}


div#consentFormContainer.counterbalance {

    div.form-container {
        padding-top: 173px;
        padding-left : 105px;

        input {
            margin-bottom: 4px;

            &[name="parent_email"] {
                margin-left: 30px;
            }
        }

        .second-section {
            top: 705px;
        }
    }

}

div#consentFormContainer div.form-container {
    padding-top  : 197px;
    padding-left : 180px;
    width        : 900px;
}

div#consentFormContainer div.form-container input {
    margin-bottom : 6px;
    height        : 32px;
}

ul.first-section div.field,
ul.second-section div.field {
    float : left;
}

ul.first-section div.field.item-one,
ul.first-section div.field.item-two {
    width       : 500px;
    margin-left : 200px;
}

ul.first-section div.field.item-three {
    width       : 668px;
    margin-left : 32px;
}

ul.first-section div.field.item-four {
    width       : 400px;
    margin-left : 0;
}

ul.first-section div.field.item-five {
    margin-left : 140px;
    width       : 160px;
}

ul.first-section div.field.item-six {
    width       : 200px;
    margin-left : 100px;
}

ul.first-section div.field.item-seven {
    width       : 200px;
    margin-left : 200px;
}

ul.first-section div.field.item-eight {
    width       : 430px;
    margin-left : 170px;
}

div#consentFormContainer.ystreet {
    ul.first-section {
        div.field.item-one {
            margin-left : 106px;
        }
        div.field.item-two {
            margin-left : 150px;
        }
        div.field.item-eight {
            margin-left : 210px
        }
    }
}

ul.second-section {
    position : relative;
    top      : 667px;
}

div#consentFormContainer.ystreet ul.second-section {
    top  : 696px;
    left : 50px;
}

ul.second-section li {

}

ul.second-section div.field.item-nine {
    width       : 400px;
    margin-left : 80px;
}

ul.second-section div.field.item-ten {

    width       : 140px;
    margin-left : 100px;
}

ul.second-section div.field.item-eleven {
    margin-top  : 37px;
    width       : 400px;
    margin-left : 80px;
}

ul.second-section div.field.item-twelve {
    margin-top  : 37px;
    width       : 140px;
    margin-left : 100px;
}

.panel {

    &.apply-buttons {
        background-color : transparent;
        margin-top       : 50px;

        .btn {
            margin-top : 10px;
        }

        .title {
            font-size : rem-calc(16);
            color     : #333;
        }
    }
}

.user-application-form {
    .card {
        border        : 1px solid #eee;
        margin-bottom : 20px;
    }
    .input-value-row {
        margin-bottom : 10px;

        .text-input {
            padding : 5px 10px;
            border  : 1px solid #aaa;
            min-height: 36px;

            &.disabled {
                background-color : #eee;
            }
        }

        a.textValue {
            border-radius : 0;
            padding       : 2px 6px;
            margin-top    : 0;
        }

    }
}

.edit-user-app {

    #historyFormDiv {
        select {
            margin-bottom : 20px;
        }
    }

    #mopContainer {
        margin-bottom : 20px;
    }

    .pricing-table {
        .price {
            font-size  : 1.0rem;
            padding    : 10px;
            text-align : left;

        }

        &.error {
            border : 1px solid red;
        }
    }

    .profile-picture-card {
        img {
            max-height : 200px;
        }
        button {
            margin-top : 10px;
            display    : block;

        }
    }

    .card {
        .button.tiny {
            padding : 5px;
        }

        box-shadow    : none;
        margin-bottom : 5px;
        .card-section,
        .button-group.menu {
            margin-top    : 10px;
            margin-bottom : 0;
            float         : none !important;
        }
    }

}




