#possibleGroups .button.wide,
#currentGroups .button.wide {
  margin-top: 2px;
}

#saveGroups, #cancelGroups {
  float: right;
}

#cancelGroups {
  margin-right: 10px;
}