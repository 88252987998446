@import "settings";

table#campaignList td
{
    vertical-align : middle;

}

.campaignImage img
{
    margin-top : 18px;
    display    : inline-block;
}

.mop-thermometer {
    @extend .brand-background;
  border-radius: 13px; /* (height of inner div) / 2 + padding */
  padding: 3px;
}

.campaign-thermometer {
    @extend .brand-background;
  border-radius: 13px; /* (height of inner div) / 2 + padding */
  padding: 3px;
}

.progress-label {
    font-weight: bold;
    text-align: center;
}

.mop-thermometer .progress-bar {
    height        : 20px;
    border-radius : 10px;
}

.campaign-thermometer .progress-bar {
    height: 20px;
    border-radius    : 10px;
}

body.evolvement,
body.breakdown
{
    .mop-thermometer .progress-bar,
    .campaign-thermometer .progress-bar {
        @extend .brand-background;
    }
}


body.ystreet {
    .mop-thermometer .progress-bar,
    .campaign-thermometer .progress-bar {
        @extend .brand-background;
    }
}


