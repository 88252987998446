
body.brand-bg-design {
    background-color : $black !important;
}

section.splash {
    color          : $white;
    font-weight    : 300;
    padding-bottom : 100px;

    h1 {
        font-size : rem-calc(60);
    }

    h1, h2, h3, h4, h5, h6 {
        text-align : center;
        @extend .brand-color;
    }

    .logo {
        margin : 100px auto 0 auto;
    }

    .logo.small {
        margin-top : 50px;
        height: 150px;
    }

    .content {
        margin-top : 30px;
    }

    .footer {
        margin-top : 100px;
    }

    .forgot-login {
        margin-left : 20px;
    }

    a {
        color : $white;
    }

    a.button {
        color : $black;

        &.visited,
        &:hover,
        &:active {
            color : $black;
        }
        &.hover {
            color : lighten($black, 10%);
        }
    }

    h1, h2, h3, h4, h5, label, input {
        font-weight : 300;
    }

    h2 {
        text-transform : uppercase;
        font-size      : rem-calc(24);

    }

    form {

        label,
        input,
        textarea,
        select {
            font-weight : 300;
        }

        &.large {
            label,
            input,
            textarea,
            select {
                font-size : rem-calc(27);

                p {
                    font-size : rem-calc(27);
                }
            }

            .surveyRow {
                input[type=checkbox],
                input[type=radio] {
                    margin-bottom : 0;
                }

                label {
                    margin-bottom : 20px;

                    span {
                        line-height : 1.4;
                    }
                }

            }
        }

        &.dark {
            label {
                color : $iron;

            }
        }

        //&.dark {
        //    color : $iron;
        //    label,
        //    input,
        //    textarea {
        //        color            : $iron;
        //        border-color     : $iron;
        //        background-color : transparent;
        //
        //        @include placeholder {
        //            color: rgba(248, 241, 67, .4);
        //        }
        //    }
        //}

        input,
        textarea,
        select {
            height        : auto;
            border-radius : 0;
        }
    }

    .login-buttons {
        margin-top : 10px;

        &.facebook {
            margin-top : 50px;
        }
    }

    .card {
        margin-top : 30px;

        .card-content {
            .card-title {
                text-transform : none;
                line-height    : 1.6;
            }
        }

        .card-action {
            a {
                margin : 0;
            }
        }
    }

    .actions {
        margin-top : 150px;

        .button {
            margin-top : 20px;
        }
    }

    &.apply {
        h1 {
            font-size : rem-calc(30);
        }
        h2 {
            font-size : rem-calc(20);
        }
    }

    &.account {
        form {
            label {
                font-size: rem-calc(16);
                text-transform: uppercase;
            }
        }
    }
}
@media (max-width : $small-breakpoint) {
    section.splash {
        &.account {
            form {
                max-width : 500px;
                margin    : auto;
                padding   : 0 40px;

                label {
                    font-size      : rem-calc(16);
                    text-transform : uppercase;
                }

                input {
                    margin-bottom : 0;
                }
            }
        }
    }
}

@media (max-width : $xsmall-breakpoint) {
    section.splash {

        .logo {
            margin-top : 0px !important;
            height: 150px;
            width: auto;
        }

        .button.xlarge {
            font-size : rem-calc(22);
        }

        .login-buttons.facebook {
            margin-top: 20px;

            a {
                width: 100%;
            }

        }

        h1 {
            font-size : rem-calc(30);
        }

        .content {
            margin-top: 10px;
        }

        form {

            label {
                font-size: rem-calc(20);
            }

            label,
            input,
            textarea,
            select {
                font-weight : 300;
            }

            &.large {
                label,
                input,
                textarea,
                select {
                    font-size : rem-calc(20);

                    p {
                        font-size : rem-calc(20);
                    }
                }
            }
        }


        &.account {
            form {
                label {
                    font-size      : rem-calc(14);
                    text-transform : uppercase;
                }

                input {
                    margin-bottom: 0;
                }
            }
        }
    }
}

