.facebookLnk {
  height: 36px;
  width: 20%;
  position: absolute;
  top: 170px;
  left: 26%;
  display: block;
}

.twitterLnk {
  height: 36px;
  width: 17%;
  position: absolute;
  top: 171px;
  left: 52%;
  display: block;
}

.ystreet.facebookLnk {
  height: 28px;
  width: 20%;
  position: absolute;
  top: 115px;
  left: 27%;
  display: block;
}
.ystreet.twitterLnk {
  height: 28px;
  width: 17%;
  position: absolute;
  top: 115px;
  left: 52%;
  display: block;
}

.signupThanks {
  width: 550px;
  height: 203px;
}
