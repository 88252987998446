.collection {
    margin        : 0.5rem 0 1rem 0;
    border        : none;
    border-radius : 2px;
    overflow      : hidden;
    position      : relative;

    hr {
        margin : 8px 0;
        color  : #eee;
    }

    .collection-item {
        background-color : #fff;
        line-height      : 1.5rem;
        padding          : 10px;
        margin           : 0;
        border-bottom    : 1px solid #e9e9e9;

        .item-label,
        .item-content {
            display: inline-block;
        }

        .item-header {
            margin-bottom : 5px;
        }

        &:only-child {
            //border-top : 1px solid #e9e9e9;
        }

        &:last-child {
            border-bottom : none;
        }

        .notification-item {
            padding-left : 65px;
            padding-top  : 7px;
            position     : relative;

            img {
                position       : absolute;
                width          : 42px;
                height         : 42px;
                overflow       : hidden;
                left           : 10px;
                display        : inline-block;
                vertical-align : middle;
                top            : 7px;
            }

            .title {
                font-size   : rem-calc(13);
                font-weight : bold;
                line-height : 1;
            }

            .footer {
                line-height : 1;
                font-size   : rem-calc(10);
                margin-top  : 4px;
                color       : rgba(0, 0, 0, .5);

            }

            .secondary-content {
                position  : absolute;
                top       : 7px;
                right     : 0;
                width     : 25px;
                min-width : 25px;

                i {
                    font-size : 15px;
                    width     : 15px;
                    color     : #ddd;
                }

                i:hover {
                    color : #333;
                }
            }

            .content {
                text-overflow : ellipsis;
                overflow      : hidden;
                margin        : 4px 0 0 0;
                padding       : 0;
                line-height   : 1;
                color         : rgba(0, 0, 0, 0.70);
                white-space   : nowrap;
                font-size     : rem-calc(13);
            }
        }
    }

    a.collection-item {
        display : block;
        color   : #26a69a;

        &:not(.active):hover {
            background-color : #ddd;
        }
    }

    .collection-item.avatar {
        min-height: 0;

        .item-container {
            margin-left : 70px;
            //padding     : 8px 0;
            position    : relative;

            .item-icons {
                border-radius : 5px;
                float         : left;
                margin-left   : -70px;

                img {
                    max-width: 50px;
                }
            }

            .item-icons i {
                color       : #068031;
                font-size   : 50px;
                margin-top  : 0px;
                margin-left : 3px;
            }

            .item-content {
                color       : black;
                font-weight : 500;
            }

            .progress-line {
                margin-top : 10px;
            }

            p {
                line-height   : 1.4;
                margin-bottom : 10px;
            }

            .item-fotos {
                display    : block;
                overflow   : hidden;
                margin-top : 10px;

                a {
                    display : block;
                    float   : left;
                }

                p {
                    margin-left   : 165px;
                    margin-bottom : 0;
                }

                ul.action-list {

                    margin-top  : 0px;
                    margin-left : 165px;
                }

                img {
                    width : 150px;
                    float : left;
                }
            }
        }
    }
}

.dropdown-menu {
    .collection {
        margin : 0;
        border : none;
    }

}
