

.panel {
    &.dark {
        background-color: rgba(72,72,72, .4);
        color: $white;
        border-color: #333;

        label {
            color: #fff;
        }
    }
}