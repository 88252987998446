@import "foundation/scss/foundation";
.one {
    @extend .medium-1;
}

.two {
    @extend .medium-2;
}

.three {
    @extend .medium-3;
}

.four {
    @extend .medium-4;
}

.five {
    @extend .medium-5;
}

.six {
    @extend .medium-6;
}

.seven {
    @extend .medium-7;
}

.eight {
    @extend .medium-8;
}

.nine {
    @extend .medium-9;
}

.ten {
    @extend .medium-10;
}

.eleven {
    @extend .medium-11;
}

.twelve {
    @extend .medium-12;
}

.thirteen {
    @extend .medium-13;
}

.fourteen {
    @extend .medium-14;
}

.fifteen {
    @extend .medium-15;
}

.sixteen {
    @extend .medium-16;
}

.seventeen {
    @extend .medium-17;
}

.eighteen {
    @extend .medium-18;
}

.grid_100 {
    @extend .medium-18;
}

.grid_95 {
    @extend .medium-17;
}

.grid_90 {
    @extend .medium-16;
}

.grid_85 {
    @extend .medium-15;
}

.grid_75 {
    @extend .medium-14;
}

.grid_70 {
    @extend .medium-13;
}

.grid_66 {
    @extend .medium-12;
}

.grid_60 {
    @extend .medium-11;
}

.grid_50 {
    @extend .medium-9;
}

.grid_40 {
    @extend .medium-7;
}

.grid_33 {
    @extend .medium-6;
}

.grid_30 {
    @extend .medium-5;
}

.grid_25 {
    @extend .medium-4;
}

.grid_15 {
    @extend .medium-3;
}

.grid_10 {
    @extend .medium-2;
}

.grid_5 {
    @extend .medium-1;
}

.row.full-width {
    max-width : none;
}


// Block grid xsmall
@if $include-html-block-grid-classes {
    @if $block-grid-media-queries {
        @if $include-xs-html-block-grid-classes {
            @media #{$xsmall-only} {
                @include block-grid-html-classes($size : xsmall, $include-spacing : false);
            }
        }
    }
}
