a,
button,
span,
div {

    outline:none;

    &.btn,
    &.button,
    &.btn-flat,
    &.btn-small,
    &.btn-large {

        margin : 0;
        border: none;

        &:hover {
            text-decoration: none;
        }

        &.wide {
            width: 100%;
        }
    }
}


.file-field .btn, .file-field .btn-large {
    height: auto;
    line-height: 1;
    padding: rem-calc(10) rem-calc(20);
}


.button.button-flat {
    background-color: transparent !important;
}

.button-group {
    &.menu,
    &.menu-join {
        .button {
            background-color : $secondaryColor;
            color : $black;
            @extend .tiny;
            padding: 5px;

            &.link,
            &.visited {
                background-color : $secondaryColor;
                color            : $black;
            }

            &:hover,
            &:active {
                background-color : darken($secondaryColor, 10%);
                color            : $black;
            }

            &.selected {
                background-color : $secondaryColorSelected;
                color            : $black;

                &:link,
                &:visited {
                    background-color : $secondaryColorSelected;
                    color            : $black;
                }

                &:hover,
                &:active {
                    background-color : darken($secondaryColorSelected, 10%);
                    color            : $black;
                }
            }
        }

        margin-bottom: 5px;
    }
}


.button {
    text-transform: $button-text-transform;
    .spaced {
        margin : 4px;
    }

    &.xlarge {
        font-size: $button-font-xlrg;
    }

    &.tiny {
        font-size : $tinyBtnFontSize;
    }

    &.secondary {
        color: $primary-color;
        background-color : $secondary-color;

        &:focus,
        &:link,
        &:visited {
            background-color : $secondary-color;
            color: $primary-color;
        }

        &:hover,
        &:active {
            background-color : $secondary-color;
            color: $primary-color;
        }
    }


    &.secondary.selected {
        background-color : $primary-color;
        color: $secondary-color;

        &:link,
        &:visited {
            background-color : $primary-color;

            color: $secondary-color;
        }

        &:hover,
        &:active {
            background-color: $primary-color;
            color: $secondary-color;
        }
    }

    a.button.secondary,
    button.button.secondary {
        border : none;
    }

    .expand {
        width      : 100%;
        text-align : center;
    }

    .full-width {
        width         : 100%;
        text-align    : center;
        padding-left  : 0px !important;
        padding-right : 0px !important;
    }

    .simple,
    .simple:link,
    .simple:visited {
        border          : none !important;
        background      : transparent !important;
        cursor          : pointer;
        color           : $highlightColor;
        outline         : none;
        text-decoration : none;
    }

    .simple:hover,
    .simple:active {
        text-decoration : underline;
    }

    .raised {

        box-shadow : 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }


    &.standard {
        background-color : $button-bg-color;

        &:link,
        &:visited
        {
            background-color : $button-bg-color;
        }

        &:hover,
        &:active {
            background-color : $button-bg-hover;
        }
    }
}

.button,
.btn,
.btn-small,
.btn-large
{
    box-shadow : none;
    text-transform: uppercase;
    height: auto;
    margin: 0;
    color: $secondary-color;
    background-color : $primary-color;

    &:link,
    &:visited {
        background-color : $primary-color;
        box-shadow : none;
    }

    &:active,
    &:hover {
        text-decoration  : none;
        background-color : darken($primary-color, 2%);
        box-shadow : none;
    }

    i.left {
        margin-right: 5px;
    }

    i.right {
        margin-left: 5px;
    }
}

.btn {
    line-height: 40px;
    font-size: rem-calc(14);
}

.btn-small {
    line-height: 30px;
    font-size: rem-calc(12);

    i {
        font-size: rem-calc(18);
    }
}

.btn-flat,
.btn-flat:link,
.btn-flat:visited,
.btn-flat:hover,
.btn-flat:active,
.btn-flat:focus {
    background-color: transparent;
    color: $jet;
    box-shadow: none;
}

.btn-flat.condensed {
    padding: .5rem 0;
}

a.facebook-button {
    background-color: #3B5998 !important;
    font-size: 14px;
    font-weight: bold;
    color: $white !important;
}
