
.brand-bg-design {
    background-color: $secondary-color;

    .bg-text {
        color: $white;

        a {
            color: $primary-color;
        }
    }
}

section.splash {
    .logo {
        background-image: url('/images/ystreet/logo.png');
        height: 250px;
        margin-top: 50px !important;
    }
}


@media (max-width : 580px) {
    section.splash {
        .logo {
        }
    }
}

