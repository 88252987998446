.projectFeed.info,
.orderFeed.info
{
    background-color : #222;
    background       : -webkit-linear-gradient(left, #191514, #444); /* For Safari */
    background       : -o-linear-gradient(right, #191514, #444); /* For Opera 11.1 to 12.0 */
    background       : -moz-linear-gradient(right, #191514, #444); /* For Firefox 3.6 to 15 */
    background       : linear-gradient(to right, #191514, #444); /* Standard syntax */
}

.projectFeed,
.orderFeed
{
    padding : 6px 20px 9px 20px;
}

.orderFeed.meta
{
    padding : 13px 0 0 15px;
}

.projectFeed.info div,
.orderFeed.info div,
.projectFeed.info span,
.orderFeed.info span
{
    font-size: .95em;
}

.projectFeed.info span.weak,
.orderFeed.info span.weak
{
    font-size: .80em;

}

.orderFeedContainer img.avatar,
.projectFeedContainer img
{

    width        : auto !important;
    max-height   : 90px;
    border        : none;
    border-radius : 0;
    box-shadow    : none;
}

.projectFeedContainer a,
.orderFeedContainer a
{
    color       : #FFF !important;
    font-weight : bold !important;

}

.projectFeedContainer div.info h4,
.projectFeedContainer div.info h5,
.projectFeedContainer div.meta h5,
.orderFeedContainer div.info h4,
.orderFeedContainer div.info h5,
.orderFeedContainer div.meta h5
{

    font-size   : .85em;
    color       : white;
    font-weight : bold;
    margin      : 6px 0px;
}

.projectFeedContainer div.info h4,
.orderFeedContainer div.info h4
{
    font-size : 1.1em;
}

.projectFeedContainer div.float,
.orderFeedContainer div.float
{
    color  : #fff;
    height : 90px;
}

.orderFeedContainer div.float
{
}

.projectFeedContainer .grid_10,
.orderFeedContainer .grid_10
{
}

.projectFeedContainer,
.orderFeedContainer
{
    padding          : 0px;
    background-color : #525252;
}

.projectFeedContainer div.meta div.folder-icon,
.orderFeedContainer div.meta div.folder-icon
{
    @extend .sprite;
    position            : absolute;
    display             : block;
    right               : 0px;
    top                 : 0px;
    width               : 30px;
    height              : 30px;
    background-position : -233px -72px;
}

.ystreet .projectFeedContainer div.meta div.folder-icon,
.ystreet .orderFeedContainer div.meta div.folder-icon
{
    @extend .brand-background;
}

.evolvement .projectFeedContainer div.meta div.folder-icon,
.evolvement .orderFeedContainer div.meta div.folder-icon
{
    @extend .brand-background;
}

.row.profile-body .project_instance_started .pad-small.row,
.row.profile-body .orderFeedContainer .pad-small.row
{
    //  margin: 0px -40px 5px -40px;
}

