@import "settings";

.profile-section {
    .card {
        margin-bottom : rem-calc(10);

        .card-content {

            padding : 10px 20px;

            .card-title {
                text-transform : uppercase;
                font-size      : rem-calc(12);
                line-height    : 1;
                display        : inline-block;
                margin-bottom  : 10px;

            }

            .card-action {
                padding    : 5px 0 0 0;
                text-align : right;

                a {
                    color          : #555;
                    text-transform : capitalize;
                    font-size      : rem-calc(13);
                }
            }
        }
    }

    .gallery-teaser {
        a {
            display : block;
        }

        img {
            max-height : 50px;
        }
    }

    .gallery-full {
        a {
            display : block;
        }
        img {
            max-height : 150px;
        }
    }

}

.achievement-item {
    line-height : 1;
    text-align  : center;
    .headline {
        font-size   : 37px;
        line-height : 1.3;
        color       : $black;
    }
    .small {

    }

}

.card.achievements {
    .card-title {
        width : 100%;
        .secondary {
            font-size : rem-calc(12);
            float     : right;
        }
    }

    .card-content {
        padding-bottom : 0;
    }

    .card-action {
        padding : 5px 20px 10px 20px;
        ul {
            margin-bottom : 0;
            margin-left   : 20px;
        }

        li {
            padding-bottom : 0;
            margin-left    : 0;
            margin-right   : 30px;
        }

        a {
            margin-right : 5px;
            font-size    : rem-calc(18);
        }

        span {
            font-size      : rem-calc(14);
            text-transform : lowercase;
        }

        a,
        span {
            display : inline-block;
        }
    }
}

.card {
    &.profile-info {
        min-height : 340px;
    }
}

.profile-info,
.user-avatar {
    height : auto;

    .table {
        height : 320px;
    }

    a {
        text-decoration : none;
    }

    h4 {
        font-size   : rem-calc(40);
        color       : #777;
        font-weight : 300;
    }

    img {
        max-height : 310px;
    }
}

.profile-section.non-youth,
.profile-section.admin {
    .profile-info,
    .user-avatar {
        height  : auto;
        padding : 10px 20px;
    }

    .user-avatar {
        padding-top : 20px;
    }

    .card-footer {
        padding : 10px 20px;
    }
}

h1 {
    font-size   : rem-calc(27);
    font-weight : 300;

    small {
        line-height    : 44px;
        font-size      : rem-calc(11);
        text-transform : uppercase;
    }

    .btn {
        padding     : 2px 8px;
        margin-left : 5px;
    }
}

.profile-info {
    .collection {
        margin-top : 0px;
        font-size  : rem-calc(14);
        .item-label {
            width : 70px;
            color : rgba(0, 0, 0, .6);
        }

        .item-content {
            color : rgba(0, 0, 0, .8);
        }
    }

    .card-footer {
        font-size      : rem-calc(14);
        color          : #555;
        padding-bottom : 20px;
    }
}

.gallery-header {
    h1 {
        font-size   : 21px;
        line-height : 50px;
    }
}

#edit-profile-body {
    padding-top : 25px;

}

.activity-panel {
    @extend .panel !optional;
    padding          : 5px 0;
    margin           : 0 0 10px 0;
    background-color : $white;
    border-radius    : 0;
    border-left      : none;
    border-right     : none;
}

.activity-panel img {
    border        : none;
    border-radius : 0;
}

#memberResults.profile-body {
    .user-block {
        .text-content {
            padding-top : 0;
        }

        .name {
            font-size : rem-calc(16);
        }

        .group {
            font-size : rem-calc(13);
        }
    }

    .result-count {
        padding : 0 10px;
    }
}

.team-profile {
    .profile-header {
        img {
            max-height : 150px;
        }
    }

    .title-wrapper {
        height : 150px;
    }

    #currentMembers {
        a:hover {
            text-decoration : none;
        }

        a .card {
            &:hover {
                background-color : lighten($secondaryColor, 5%);
            }
        }

        &.large {
            p {
                font-size : rem-calc(20);
            }
        }
    }

    h1 {
        @extend .vert-align;
        font-size   : rem-calc(30);
        font-weight : 300;
    }
}

@media (max-width : $small-breakpoint) {
    .team-profile {
        .profile-header {
            img {
                display:none;
            }
        }

        .title {
            text-align:center;
        }
    }
}


.team-edit {
    ul#currentMembers {
        li {
            padding : 20px 0;
            border  : 1px solid #eee;
            .user-avatar {
                .info {
                    padding   : 5px 0;
                    font-size : 14px;
                }

                .avatar {
                    max-height : 100px;
                    overflow   : hidden;
                }
            }
        }
    }
}

.team-profile {
    ul#currentMembers {
        li {
            .card-image {
                max-height : 100px;
                min-height : 100px;
                overflow   : hidden;
            }
        }
    }
}

#currentMembers {
    .card {
        box-shadow : none;
        border     : 1px solid rgba(0, 0, 0, .05);
        padding    : 10px;
        .card-content {
            padding-left  : 0;
            padding-right : 0;
        }
        p {
            font-size : rem-calc(12);
            color     : $charcoal;
        }
    }
}

@media (max-width : 768px) {
    .card.achievements {
        .card-title {
            .secondary {
                font-size  : 90%;
                margin-top : 10px;
                float      : none;
            }
        }
    }

    .card.profile-info,
    .card.user-avatar {
        height : auto;

        .table {
            height : auto;

        }

        img {
            max-height : 200px;
        }

        .collection {
            margin-top : 0;
            font-size  : rem-calc(12);

            .collection-item {
                padding : 4px;
            }
        }

    }

    h1 {
        margin-top    : 12px;
        font-size     : rem-calc(18);
        font-weight   : 400;
        margin-bottom : 3px;

        small {
            line-height    : rem-calc(27);
            font-size      : 55%;
            text-transform : uppercase;
        }

        .btn {
            i {
                font-size   : 15px;
                line-height : 18px;
            }
        }
    }

    .achievement-item {
        margin-top : 0;
        .headline {
            font-size : rem-calc(27);
        }
    }

    .card-achievements {
        ul > li {
            padding-bottom : 5px
        }
    }

    .profile-section {
        .card {
            .card-content {
                .card-title {
                    margin-bottom : 5px;
                    font-size     : rem-calc(11);
                }
            }
        }

    }
}

@media (max-width : 580px) {
    h1 {
        font-size : rem-calc(20);

        .btn {
            padding : 2px 10px;

            i {
                font-size   : rem-calc(13);
                line-height : rem-calc(18);
            }
        }

    }
}


