/*** Colors ***/
$primary-color: color("materialize-red", "lighten-2") !default;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;

$secondary-color: color("teal", "lighten-1") !default;
$success-color: color("green", "base") !default;
$error-color: color("red", "base") !default;
$link-color: color("light-blue", "darken-1") !default;

/*** Badges ***/
$badge-bg-color: $secondary-color !default;

/*** Buttons ***/
$button-bg-color-disabled: #DFDFDF !default;
$button-color: $secondary-color !default;
$button-color-disabled: #9F9F9F !default;
$button-color-flat: #343434 !default;
$button-color-raised: #fff !default;
$button-floating-size: 37px !default;
$button-height: 36px !default;
$button-font-size-shared: 1.3rem !default;
$button-large-icon-font-size: 1.6rem !default;
$button-line-height: 36px !default;

/*** Cards ***/
$card-padding: 20px !default;
$card-bg-color: #fff !default;
$card-link-color: color("orange", "accent-2") !default;
$card-link-color-light: lighten($card-link-color, 20%) !default;

/*** Collapsible ***/
$collapsible-height: 3rem !default;
$collapsible-header-color: #fff !default;
$collapsible-border-color: #ddd !default;

/*** Dropdown ***/
$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: $secondary-color !default;

/*** Fonts ***/
$roboto-font-path: "../font/roboto/" !default;
$icons-font-path: "../font/material-design-icons/" !default;

/*** Forms ***/
// Text Inputs + Textarea
$input-border-color: color("grey", "base") !default;
$input-bg-color: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $success-color !default;
$input-focus-color: $secondary-color !default;
$label-font-size: .8rem !default;
$input-disabled-color: rgba(0,0,0, .26) !default;
$input-disabled-solid-color: #BDBDBD !default;

// Radio Buttons
$radio-fill-color: $secondary-color !default;
$radio-empty-color: #5a5a5a !default;

// Switches
$switch-bg-color: $secondary-color !default;
$switch-checked-lever-bg: desaturate(lighten($secondary-color, 25%), 25%) !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: #818181 !default;

// Date Picker
$datepicker-weekday-bg: darken($secondary_color, 7%) !default;
$datepicker-date-bg: $secondary_color !default;
$datepicker-year: rgba(255, 255, 255, .4) !default;
$datepicker-focus: rgba(0,0,0, .05) !default;
$datepicker-selected: $secondary-color !default;
$datepicker-selected-outfocus: desaturate(lighten($secondary-color, 35%), 15%) !default;


/*** Global ***/
// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// Grid Variables
$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;

/*** Navbar ***/
$navbar-height: 64px !default;
$navbar-height-mobile: 56px !default;
$navbar-font-color: #fff !default;
$navbar-brand-font-size: 2.1rem !default;

/*** SideNav ***/
$sidenav-bg-color: #fff !default;
$sidenav-padding-right: 15px !default;

/*** Photo Slider ***/
$slider-bg-color: color('grey', 'base') !default;
$slider-bg-color-light: color('grey', 'lighten-2') !default;
$slider-indicator-color: color('green', 'base') !default;

/*** Tabs ***/
$tabs-underline-color: $primary-color-light !default;
$tabs-text-color: $primary-color !default;
$tabs-bg-color: #fff !default;

/*** Tables ***/
$table-border-color: #d0d0d0 !default;
$table-striped-color: #f2f2f2 !default;

/*** Toasts ***/
$toast-height: 48px !default;
$toast-color: #323232 !default;
$toast-text-color: #fff !default;

/*** Typography ***/
$off-black: rgba(0, 0, 0, 0.87) !default;
// Header Styles
$h1-fontsize: 4.2rem !default;
$h2-fontsize: 3.56rem !default;
$h3-fontsize: 2.92rem !default;
$h4-fontsize: 2.28rem !default;
$h5-fontsize: 1.64rem !default;
$h6-fontsize: 1rem !default;

// Footer
$footer-bg-color: $primary-color !default;

// Flowtext
$range : $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;

/*** Collections ***/
$collection-border-color: #e0e0e0 !default;
$collection-bg-color: #fff !default;
$collection-active-bg-color: $secondary-color !default;
$collection-active-color: lighten($secondary-color, 55%) !default;
$collection-hover-bg-color: #ddd !default;

/* Progress Bar */
$progress-bar-color: $secondary-color !default;
