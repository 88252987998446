
.nav-bar {
    height      : 40px;
    background  : #4d4d4d;
    margin-left : 0;
    margin-top  : 20px;
    padding     : 0;
}

.nav-bar > li {
    float              : left;
    display            : block;
    position           : relative;
    padding            : 0;
    margin             : 0;
    border             : 1px solid #333333;
    border-right       : none;
    line-height        : 38px;
    -webkit-box-shadow : 1px 0 0 rgba(255, 255, 255, 0.2) inset;
    -moz-box-shadow    : 1px 0 0 rgba(255, 255, 255, 0.2) inset;
    box-shadow         : 1px 0 0 rgba(255, 255, 255, 0.2) inset;
}

.nav-bar > li:first-child {
    -webkit-box-shadow : 0 0 0;
    -moz-box-shadow    : 0 0 0;
    box-shadow         : 0 0 0;
}

.nav-bar > li:last-child {
    border-right       : solid 1px #333333;
    -webkit-box-shadow : 1px 0 0 rgba(255, 255, 255, 0.2) inset, 1px 0 0 rgba(255, 255, 255, 0.2);
    -moz-box-shadow    : 1px 0 0 rgba(255, 255, 255, 0.2) inset, 1px 0 0 rgba(255, 255, 255, 0.2);
    box-shadow         : 1px 0 0 rgba(255, 255, 255, 0.2) inset, 1px 0 0 rgba(255, 255, 255, 0.2);
}

.nav-bar > li.active {
    background   : #2ba6cb;
    border-color : #2284a1;
}

.nav-bar > li.active > a {
    color  : white;
    cursor : default;
}

.nav-bar > li.active:hover {
    background : #2ba6cb;
    cursor     : default;
}

.nav-bar > li:hover {
    background : #333333;
}

.nav-bar > li > a {
    color : #e6e6e6;
}

.nav-bar > li ul {
    margin-bottom : 0;
}

.nav-bar > li .flyout {
    display : none;
}

.nav-bar > li.has-flyout > a:first-child {
    padding-right : 40px;
    position      : relative;
}

.nav-bar > li.has-flyout > a:first-child:after {
    content      : "";
    display      : block;
    width        : 0;
    height       : 0;
    border       : solid 4px;
    border-color : #e6e6e6 transparent transparent transparent;
    position     : absolute;
    right        : 20px;
    top          : 17px;
}

.nav-bar > li.has-flyout > a.flyout-toggle {
    border-left : 0 !important;
    position    : absolute;
    right       : 0;
    top         : 0;
    padding     : 20px;
    z-index     : 2;
    display     : block;
}

.nav-bar > li.has-flyout.is-touch > a:first-child {
    padding-right : 55px;
}

.nav-bar > li.has-flyout.is-touch > a.flyout-toggle {
    border-left : 1px dashed #666;
}

.nav-bar > li > a:first-child {

    position        : relative;
    padding         : 0 20px;
    display         : block;
    text-decoration : none;
    font-size       : 14px;
}

.nav-bar > li > input {
    margin : 0 10px;
}

.nav-bar.vertical {
    height     : auto;
    margin-top : 0;
}

.nav-bar.vertical > li {
    float              : none;
    border-bottom      : none;
    border-right       : solid 1px #333333;
    -webkit-box-shadow : none;
    -moz-box-shadow    : none;
    box-shadow         : none;
}

.nav-bar.vertical > li.has-flyout > a:first-child:after {
    content      : "";
    display      : block;
    width        : 0;
    height       : 0;
    border       : solid 4px;
    border-color : transparent transparent transparent #e6e6e6;
}

.nav-bar.vertical > li .flyout {
    left : 100%;
    top  : -1px;
}

.nav-bar.vertical > li .flyout.right {
    left  : auto;
    right : 100%;
}

.nav-bar.vertical > li.active {
    border-right : solid 1px #2284a1;
}

.nav-bar.vertical > li:last-child {
    border-bottom : solid 1px #333333;
}

#socialPostBox {
    .card {
        border     : none;
        box-shadow : none;
    }

    .card-action .btn {
        color : black;
    }
}

#myDropZone {
    height        : auto;
    border        : 1px solid #aaa;
    background    : rgba(0, 0, 0, .1);
    min-height    : 0;
    margin-bottom : 0;

    &.one-image {
        text-align:center;
    }

    .dz-message {
        color : #aaa;
    }
}

.bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    background-color : rgba(0, 0, 0, .9);
    padding          : 10px;
    color            : white;
    z-index: 100;

    p {
        font-size     : 11px;
        margin-bottom : 0;
    }

    .filter {
        .filter-label,
        .filter-button {
            margin-right : 20px;
            line-height: 20px;
            vertical-align: middle;
            text-transform: capitalize;
            font-weight: bold;
        }

        .filter-label {
            font-size: rem-calc(13);
            background-color: transparent;
        }

        .filter-button {
            border-radius: 0;
            font-size: rem-calc(15);
            border-bottom: 2px solid transparent;
            padding: 2px 0;

            &:hover,
            &.active {
                border-bottom: 2px solid;
            }
        }
    }
}

.bar.bottom {
    top: auto;
    bottom: 0;
    left  : 0;
    right : 0;
}

@media (max-width : 992px) {

        .header-inner .menu-name {
            display : none;
        }

        .header-inner li.menu-icon {
            text-align : center;
        }

        .menu-trigger .line-wrap {
        }

}

@media (max-width : 768px) {

    header {
        height : auto;
    }

    .header-inner {
        padding : 10px 0 0 0;
    }

    .menu-trigger {
        display : block;
        width   : auto;
    }

    .menu-trigger .line-wrap {
        margin-left  : 0;
        margin-right : 0;
    }

    .header-inner li > a, .top-menu > li > a {
        width           : 30px;
        max-width       : 30px;
        background-size : 50%;
        min-width       : 0;
    }

    .top-menu > li > a .md {
        font-size : 15px;
    }

    .header-inner .menu-icon {
        display : none;
    }

    ul.header-inner:nth-child(2) {
        margin-right : -5px;
    }

    .top-menu > li > a > .tmn-counts {
        padding : 1px 3px;
        right   : 0;
    }

    .dropdown {
        .dropdown-menu {
            right : 14px;
            width : calc(100% - 28px);

        }
    }
    .dropdown.open {
        .dropdown-menu {
        }
    }
}





