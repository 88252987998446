.pattern {

  .card {
    .card-title {
      color: #000;
    }
  }
  h4 {
    margin-top: 50px;
    margin-bottom: 20px;
    color: green;
    font-weight: bold;
  }

  h6.subheader {
    margin-top: 0px;
  }

  a.ref,
  a.ref:link,
  a.ref:visited
  a.ref:hover,
  a.ref:active
  {
    color: red !important;
  }

  code {
    width: 100%;
    overflow-x: scroll;
  }

  .to-top {
    font-size: 11px;
    font-weight: 100;
    margin-left: 20px;

    .icon.arrow {
      float: none;
      color: #000;
    }
  } 

  .primary-color {
    padding: 0 10px;
    li.color {
      padding: 5px;
      .yellow {
        height: 50px;
        background-color: #fcff00 !important;
        width: 90%;
      }
      .grey {
        height: 50px;
        background-color: #f1f1f1 !important;
        width: 90%;
      }
      .orange {
        height: 50px;
        background-color: #f57f17 !important;
        width: 90%;
      }
      .red {
        height: 50px;
        background-color: #c60f13 !important;
        width: 90%;
      }
      .alert-red {
        height: 50px;
        background-color: #f04124 !important;
        width: 90%;
      }
    }
  }
}
