
.store-home {
    background-color : white;
}

.store-menu,
.store-menu .menu-wrapper {
    height : 180px;
}


.store-content {
    margin-top : 50px;
}

.store-menu {
    //box-shadow: 0px 0px 15px black;
    //background-color: rgba(31, 30, 30, 0.9);
    margin   : 0;
    position : relative;
    top      : 0;
    left     : 0;
    width    : 100%;
    z-index  : 3;

    .menu-wrapper {
        position : relative;
        padding  : 45px 20px;
    }

    .store-logo {
        color            : white;
        font-size        : rem-calc(60);
        font-weight      : 300;
        width            : 460px;
        left             : 50%;
        position         : absolute;
        text-align       : center;
        margin-left      : -230px;
        top              : 5px;
        text-transform   : uppercase;
        background-color : rgba(0, 0, 0, .9);

        .logo-inner {
            width  : 200px;
            margin : auto;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        text-transform : uppercase;
    }

    .menu-left {
        float : left;
    }

    .menu-right {
        float : right;

        ul > li {
            padding-left : 0;

            &.list-separator {
                margin-left : 5px;
            }
        }
    }

    .account {
        position    : relative;
        top         : 153px;
        width       : 460px;
        right       : 0;
        left        : 50%;
        margin-left : -230px;

        ul.inline-list li {
            padding-left : 5px;
            margin-left  : 0;
        }

        &.scroll {
            background-color : white;
        }

        ul {
            float : right;
        }

        a {
            text-decoration : none;

            &:hover {
                text-decoration : none;
            }
        }

        .balance {
            clear      : both;
            text-align : right;
            color      : #000;
            padding    : 0;

            font-size  : rem-calc(12);

            span.value {
                font-size   : 120%;
                font-weight : bold;
            }
        }
    }

    ul {
        margin : 0;

        li {
            margin-bottom : 0;
        }
    }

    .menu-bottom {
        position : absolute;
        bottom   : 0;
    }
}

.store-navigation {
    div.name {
        float     : left;
        font-size : 12px;
    }

    .icon {
        margin-top  : 0;
        margin-left : 4px;
        font-size   : 20px;
        line-height : 21px;
        height      : 21px;
        width       : 20px;
        padding     : 0;
        float       : left;
        color       : black;
    }
    a,
    span {
        text-transform : capitalize;
        font-size      : 12px;
    }

    &.categories {
        left        : 50%;
        width       : 434px;
        margin-left : -217px;
        margin-top  : 0;
        padding-top : 5px;
        @extend .vert-align;

        a {
            font-size      : 13px;
            color          : rgba(255, 255, 255, .4);
            text-transform : uppercase;
            font-weight    : bold;
            padding        : 0 4px;
            border         : 1px solid transparent;

            &.selected,
            &:hover {
                border          : 1px solid white;
                text-decoration : none;
                color           : rgba(255, 255, 255, 1);
            }
        }

        ul.inline-list > li {
            padding-left : 2px;
        }
    }
}

.store-admin-menu {
    width            : 100%;
    background-color : #f8f8f8;

    padding          : 10px 20px;
    margin-bottom    : 20px;

    ul {
        margin : 0;
    }
    a {
    }
}

.store-admin-menu {
    margin-bottom : 20px;
}

.store-menu a,
.store-admin-menu a {
    padding : 0;
    margin  : 0;
}

.store-admin-menu a,
.store-admin-menu span {
    padding : 0 10px;
    margin  : 0;
}

.store-menu a.selected {
    background-color : transparent;
    color            : #000;
}

.store-admin-menu a.selected {
    background-color : #555;
    border-radius    : 3px;
}

.store-admin-menu .store-navigation {
    margin-top : 6px;
}

.store-admin-menu .store-navigation ul.primary {
    margin-top : 2px;
}

.store-admin-menu .store-navigation a {
    font-size : 11px;
}

.store-admin-menu .store-navigation span.weak {
    font-size : 11px;

}

.store-admin-menu .store-navigation ul.inline-list li {
    margin-left : 5px;
}

.store-navigation ul.inline-list li.list-separator {
    border-left : 1px solid #aaa;
}

.product-header {
    text-transform : capitalize;
}

.product-info {
    padding : 0 20px;
}

.product-images {
    margin-top : 15px;

    img {
        width : 500px;
    }
}

.product-info {
    h5, h6, .title, .content {
        text-align : center;
    }

    h5, .title {
        text-transform : uppercase;
        font-weight    : bold;
    }

    h5 {
        font-size : 22px;
    }

    h5 .weak {
        font-size : 70%;
    }

    h6 {
        font-size : 90%;
    }

    .content {
        color     : #666;
        font-size : 90%;
    }

    .decoration {
        float      : right;
        width      : 30px;
        border-top : 2px solid #000;
        height     : 1px;
    }

    .detail-list > li {
        margin-bottom : 20px;
    }

    hr {
        margin : 6px 0;
    }
}

ul.product-image-list li {
    margin-bottom : 30px;
}

ul.product-image-list div.container {
    border : 1px solid #ddd;
}

ul.product-image-list h6 {
}

.product-images .image-container {
    text-align : center;
}

span.icon {
    margin-top : -4px;
}

.product-image {
    margin-top : 0;
    width      : 300px;
}

.product-text {
    padding    : 10px 20px;
    text-align : center;
}

.product-options {
    margin-top : 30px;
}

.product-grid > li {
    margin-top : 5px;
}

.product-grid {
    position    : relative;
    top         : 0px;
    font-family : "Helvetica Neue";
    .title {
        text-align     : center;
        text-transform : capitalize;
        margin-bottom  : 10px;
        &.truncate {
            height : 24px;
        }

        a {
            color          : #333;
            font-size      : rem-calc(25);
            line-height    : 1.2;
            text-transform : uppercase;
            font-weight    : 300;

        }

    }

    .description {
        color     : #444;
        font-size : 12px;
        &.truncate {
            height : 20px;
        }
    }

    .weak {
        font-size : 80%;
    }

    .cost {
        margin-bottom : 5px;
        font-size     : 17px;
        color         : #222;
        font-family   : $lato-font-family;
        font-weight   : 300;
    }

    a.detail:after {
        content : "\25B8";

    }

    .product-container {
        margin-bottom : 100px;
        padding       : 5px;
        text-align    : center;
        //box-shadow : 0 0 5px #eee;
        //border     : 1px solid #e7e7e7;

        a {
            display : block;
        }

        a.image-wrap {
            border        : 5px solid transparent;
            transition    : .250s linear all;
            border-radius : 10px;
            padding       : 10px;

            &:hover {
                border     : 5px solid;
                transition : .250s linear all;
                @extend .brand-border;

            }
        }
    }
}

#storeListModal {
    .product-grid .product-container {
        background-color : rgba(255, 255, 255, .8);
    }
    .product-grid .product-container:hover {
        box-shadow : 0 0 5px 4px #ebee1a;
    }
}

body.ystreet .product-grid .product-container.hover {
    //    box-shadow: 0 0 5px $ystreetColor;
    box-shadow : 0 0 5px #ccc;
}

body.evolvement .product-grid .product-container.hover {
    //    box-shadow: 0 0 5px $evolvementColor;
    box-shadow : 0 0 5px #ccc;
}

.button.view-cart {
    background-color : rgba(0, 0, 0, 1);

    &:hover {
        background-color : rgba(0, 0, 0, .8);
    }
}

.cart-icon {
    height           : 45px;
    background-color : transparent;
    font-weight      : bold;
    padding-top      : 10px;
    padding-bottom   : 10px;
}

.cart-icon,
.cart-icon a {
    color : #FFF;

}

.cart-icon .count-display {
    font-size : 25px;
}

.cart-icon .checkout-display {
    font-size : 16px;
}

.cart-icon .checkout-display {
    margin-top : 5px;
}

.cart-icon .cost {
    font-size : 80%;
}

.store-menu .cart-icon {
    height         : 25px;
    padding-top    : 0;
    padding-bottom : 0;
}

.store-menu .cart-icon .count-display {
    font-size : 14px;
}

.store-menu .cart-icon .checkout-display {
    font-size : 12px;
}

.store-menu .cart-icon .icon {
    font-size   : 25px;
    margin-top  : -3px;
    margin-left : -6px;
}

.cart-icon.hover {
    //background-color : green;
}

.cart-icon.hover .count-display {
    display : none;
}

.cart-icon.hover .checkout-display {
    display : block;
}

.product-in-cart,
.remove-from-cart {
}

.remove-from-container.hover .product-in-cart {
    display : none;
}

.remove-from-container.hover .remove-from-cart {
    display : block;
}

#cart-summary .title,
#cart-summary .value {
    background-color : transparent;
    padding          : 0;
    color            : #000;
    line-height      : 2;
}

#cart-summary .value {
    text-align : center;
}

#cart-summary .error {
    background-color : red !important;
    padding-top      : 10px;
    padding-bottom   : 10px;
}

#cart-summary .pricing-table > li {
    border-bottom : 1px solid #ddd;
}

#cart-summary .pricing-table li div {
    font-size : 16px;
}

#cart-summary .pricing-table li a.weak {
    font-size   : 12px;
    margin-left : 10px;
}

#cart-summary .pricing-table li.secondary {
    background-color : #ddd;
}

#cart-summary .pricing-table > li:last-child {
    border-bottom : none;
}

.my-cart {
    background-color : white;
}

#my-cart .title {
    text-transform : uppercase;
}

.shipping-container ul.pricing-table li label {
    text-transform : capitalize;
}

.shipping-container ul.pricing-table li.description {
    text-align : left;
    height     : 70px;
}

.panel.ship-status ul li {
    font-size : 11px;
}

ul.log-list li,
ul.log-list div.alert-box {
    font-size : 11px;
}

table.order-invoice-table tbody tr {
    background-color : transparent;
}

.order-invoice-table .tracking {
    word-wrap : break-word;
}

.order-invoice-table .tracking a {
    text-decoration : underline;
}

.check-enable {
    margin-top  : 7px;
    margin-left : 20px;
}

.check-enable label {
    font-size : 12px;
}

@media (max-width : $small-breakpoint) {
    .store-content {
        margin-top : 40px;
    }

    .store-menu {
        height : 72px;
        top    : 0;
        border-top: 1px solid $primary-color;

        .store-logo {
            width: 100%;
            height           : 70px;
            font-size        : 30px;
            top              : 0;
            background-color : #000;
            left: 0;
            margin: 0;

            .logo-inner {
                background-color : #000;
                margin-top: 9px;
            }
        }
    }

    .product-grid {
        top : 0px;
    }

    .store-navigation {

        &.categories {
            left        : 50%;
            width       : 320px;
            margin-left : -160px;
            position    : absolute !important;
            bottom      : 0;
            top         : 57px !important;
            padding: 0;

            a {
                font-size      : 9px;
                //color          : rgba(0, 0, 0, .6);
                text-transform : uppercase;

                &:hover,
                &.selected {
                    //color : rgba(0, 0, 0, 1);
                }
            }
        }

        &.account {
            padding-left  : 10px;
            padding-right : 10px;
            width: 100%;
            top: 75px;
            left: 0;
            margin: 0;

            .balance {
                .value {
                    font-size : 12px !important;
                }
            }

            font-size     : 9px;
            a {
            }
        }

        .icon {
            margin : 0;
        }

        ul {
            margin-bottom : 0;
            li {
                margin : 0;

                &.list-separator {
                    padding-left : 5px;
                }
            }
        }
    }

}

.store-checkout {
    input[type=checkbox] {
        margin-right : 5px;
    }
    button[type=submit] {
        margin-left : 10px;

    }
}
