@import url(http://fonts.googleapis.com/css?family=Lato:100,300,400,700|Roboto+Slab:400,700|Roboto:300,400,700);

// Font Variables
$lato-font-family : 'Lato', 'Roboto', sans-serif;
$roboto-slab-font-family : 'Roboto Slab', serif;
$roboto-family : 'Roboto', sans-serif;

// Bourbon mixin library
@import "bourbon";
@import "materialize";

// Your custom settings file to override Foundation defaults
//@import "settings";

// Comment out this import if you are customizing you imports below
//@import "foundation";
//@import "_foundation3";
@import "color";
@import "custom";
//@import "materialize/sass/components/materialbox";
//@import "material-icons/custom";

//helixx components
@import "buttons";
@import "collections";
@import "navbar";
@import "modal";
@import "dropdown";
@import "card";
@import "panel";

//helixx imports
@import "main";
@import "generic";
@import "message";
@import "project";
@import "campaign";
@import "region";
@import "feed";
@import "store";
@import "splash";

@import "head";
@import "profile";
@import "survey";
@import "form";
@import "magpop";

@import "topmenu";
@import "applymanage";
@import "userActions";
@import "dashboard";
@import "socialgrid";
@import "registration";

@import "pattern-library";

// ----------------------------------------
// Import specific parts of Foundation by commenting the import "foundation"
// and uncommenting what you want below. You must uncomment the following if customizing

// @import "compass/css3";
// @import "foundation/settings";
// @import "foundation/functions/all";

// Control which mixins you have access too

// @import "foundation/mixins/clearfix";
// @import "foundation/mixins/css-triangle";
// @import "foundation/mixins/font-size";

// Must include next two for semantic grid to work

// @import "foundation/mixins/respond-to";
// @import "foundation/mixins/semantic-grid";

// @import "modular-scale";
// @import "foundation/common/globals";

// Must include the grid for any responsiveness

// @import "foundation/components/grid";

// Control which common styles get compiled

// @import "foundation/common/typography";
// @import "foundation/common/forms";

// Control which components you get if customizing

// @import "foundation/components/modules/buttons";
// @import "foundation/components/modules/tabs";
// @import "foundation/components/modules/ui";
// @import "foundation/components/modules/topbar";
// @import "foundation/components/modules/navbar";
// @import "foundation/components/modules/orbit";
// @import "foundation/components/modules/reveal";
// @import "foundation/components/modules/offcanvas";

// Media Queries Overrides
// @import "foundation/components/modules/mqueries";
