#newMessageContainer fieldset.short
{
    padding-bottom : 15px;
}

#messageInboxTable tr td
{
    border-bottom : 1px solid #ddd;
}

#messageInboxTable tr.unread
{
    background-color : #EDEFFF;
}

#messageInboxTable tr.read
{
    background-color : #FFF;
}

#newMessageContainer
{
    position : relative;
}

#selectFromContainer
{
    top : 63px;
}

div.messageDate
{
    padding-bottom : 10px;
    text-align     : right;
}

#selectFromContainer button,
#selectFromContainer div.button,
#sendToContainer button,
#sendToContainer div.button,
{
    margin : 4px;
}

#viewMessageHeader
{
    margin-top: 40px;
    margin-bottom: 10px;
}

#viewMessageContainer a
{
    font-weight : bold;
}

.messageBody
{
    padding       : 3px;
    padding-right : 30px;
}

.optionsDiv .button.wide
{
    text-align   : left;
    padding-left : 60px;
}

.optionsDiv .button.wide span.entypo
{
    padding-right : 10px;
}

.fullLink
{
    width   : 100%;
    height  : 100%;
    display : block;
    cursor  : pointer;
}

.messageDetails
{
    min-height : 80px;
}

.messageDetails a.avatarContainer image
{
}

.messageDetails,
.messageDetails a
{
    font-size: 12px;
}

.row.singleMessageContainer,
.singleMessageContainer
{
    margin        : 15px;
    border-bottom : 1px solid #f3f3f3;
}

.singleMessageContainer:last-child
{
    border-bottom : none;
}

#pagingContainer
{
    width        : 240px;
    margin-top   : 20px;
    margin-right : 140px;
}

.my-inbox #pagingContainer
{
    margin-top: 15px;
    margin-left: 10px;
    width: 240px;
    margin-right: 75px;
}

input[type="text"].page-change-input,
.page-change-input
{
    text-align : center;
    display    : inline;
    height     : 22px;
    width      : 28px;
    padding    : 3px;
    margin     : 0
}

a.page-link.page-previous,
a.page-link.page-next
{
    font-size : 28px;
}

a.page-link
{
    color       : #000;
    font-size   : 24px;
    font-weight : bold;
    margin      : 0px 4px;
}

a.page-link.entypo {
    margin-top: -10px
}

a.page-link.disabled
{
    cursor : text;
    color  : #999;
}

.send-message-button {

    i {
        float: left;
    }

    color: rgba(0,0,0,.7);
    line-height    : 24px;
    font-size      : rem-calc(11);
    text-transform : uppercase;
}
