@import "settings";


.panel {
    input {
        color     : rgba(0, 0, 0, .9);
        font-size : 20px;
    }
}

.signupBox {
    margin : 10px 0;
}

select {
    padding-right : rem-calc(20);
}

.nice-large-form {
    label {
        font-size: 20px;
    }
}

.signupBox ul li {
    margin-bottom : 20px;

}

input.date-format {
    width: 160px;
}

.signupBox ul li.buttons {
    padding-top : 20px;
}

.signupBox select {
    text-transform : capitalize;
    margin-bottom  : 12px;
    font-size      : 1.2em;
}

.login-logo {
    text-align : center;
}

div.stdForm {
    padding : 20px 40px;
}

div.stdForm.dark {
    color : #bbb;
}

div.stdForm.dark a {
    color       : yellow;
    font-weight : bold;
}

div.stdForm.dark h1,
div.stdForm.dark h2,
div.stdForm.dark h3,
div.stdForm.dark h4 {
    color : yellow;
}

div.stdForm.dark label {
    font-size   : 19px;
    font-weight : bold;
    color       : #bbb;
}

div.stdForm.dark input {

    border : none;
}

div.stdForm.dark button.button,
div.stdForm.dark a.button {
    color            : #000;
    background-color : yellow;
}

select {
    width : 100%;
}

label {
    span {
        margin-right : 5px;
    }
    small {
        text-transform: none;
    }
}
select[name=gender] {
    width : 130px;
}

input[name=score] {
    width : 50px;
}

input[name=city] {
    width : 200px;
}

input[name=zip] {
    width : 100px;
}

input[name=birthday] {
    width : auto;
}

input[name=state_name] {
    width : 200px;
}

select[name=group] {
    width: 100%;
}

select[name=is_adult] {
    width: 100px;
}

select[name=shirt_size] {
    width: 90px;
}

input[type=checkbox] {
    margin-bottom : 5px;
}

#createUserForm label {
    margin-bottom : 0;
}

.fancy-inputs {

    input,
    input[disabled] {
        border           : none;
        box-shadow       : none;
        width            : 60%;
        display          : inline-block;
        margin           : 0;
        background-color : white;
    }

    .unit {
        width      : 35%;
        display    : inline-block;
        text-align : right;
    }

    .input {
        border : 3px solid #ddd;
    }

}

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
    width     : 45%;
    min-width : 64px;
}
